import {
  loginUser,
  logoutUser,
  authVerifyToken,
} from "../../services/api/mbtingService";

const mbtingStore = {
  namespaced: true,
  state: {
    user: null,
    mbtitype: null,
    name: null,
    isLoggedIn: false,
  },
  getters: {
    isLogin(state) {
      return state.isLoggedIn;
    },
    getUserInfo(state) {
      return state.user;
    },
  },
  /* 동기 */
  mutations: {
    loginSuccess(state, userId, name, mbtitype) {
      state.user = userId;
      state.name = name;
      state.mbtitype = mbtitype;
      state.isLoggedIn = true;
    },
    logout(state) {
      state.user = null;
      state.name = null;
      state.mbtitype = null;
      state.isLoggedIn = false;
    },
  },
  /* 비동기 */
  actions: {
    login({ commit }, { userid, password }) {
      // alert("userid: " + userid + ", passwd: " + password)
      return loginUser(userid, password)
        .then((response) => {
          // console.log(response);
          var name = response.data.items[0].fields.name;
          var mbtitype = response.data.items[0].fields.mbtitype;
          commit({ type: "loginSuccess", userid, name, mbtitype });
          return Promise.resolve();
        })
        .catch((error) => {
          commit({ type: "logout" });
          return Promise.reject(error);
        });
    },
    logout({ commit }) {
      logoutUser();
      commit("logout");
    },
    verifyToken({ commit }) {
      return authVerifyToken()
        .then((response) => {
          var userid = response.data.items[0].fields.userid;
          var name = response.data.items[0].fields.name;
          var mbtitype = response.data.items[0].fields.mbtitype;
          commit({ type: "loginSuccess", userid, name, mbtitype });
          return Promise.resolve();
        })
        .catch((error) => {
          alert("로그인 세션이 만료 되었습니다.");
          logoutUser();
          commit("logout");
          return Promise.reject(error);
        });
    },
  },
};

export default mbtingStore;
