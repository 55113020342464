import Vue from "vue";
import VueRouter from "vue-router";
import store from "../store";
// import HelloWorld from '@/components/HelloWorld.vue';
import MbtiFeature from "../views/MbtiFeature.vue";
import MbtiMatch from "../views/MbtiMatch.vue";
import MbtiBoard from "../views/MbtiBoard.vue";
import MbtiBoardDetail from "../views/MbtiBoardDetail.vue";
import MbtiGuessme from "../views/MbtiGuessme.vue";
import MbtiGuessmeAnswer from "../views/MbtiGuessmeAnswer.vue";
import { ACCESS_TOKEN, REFRESH_TOKEN } from "../services/api/mbtingService";
// import { store } from "@/store/store";
// import VueCookies from 'vue-cookies';

Vue.use(VueRouter);

const routes = [
  {
    path: "/", // 경로
    name: "MbtiFeature", // 해당 경로의 이름
    component: MbtiFeature, // 이동할 컴포넌트
  },
  {
    path: "/match",
    name: "MbtiMatch",
    component: MbtiMatch,
    props: true,
    children: [
      {
        path: ":my/:other",
        name: "MbtiMatchShare",
        component: MbtiMatch,
      },
    ],
  },
  {
    path: "/guessme",
    name: "MbtiGuessme",
    component: MbtiGuessme,
  },
  {
    path: "/guessme/answer",
    name: "MbtiGuessmeAnswer",
    component: MbtiGuessmeAnswer,
    props: true,
    children: [
      {
        path: ":userid",
        name: "MbtiGuessmeAnswerId",
        component: MbtiGuessmeAnswer,
      },
    ],
  },
  {
    path: "/board",
    name: "MbtiBoard",
    component: MbtiBoard,
    props: true,
  },
  {
    // path: "/board/:id",
    path: ":id",
    name: "MbtiBoardDetail",
    component: MbtiBoardDetail,
    props: true,
  },
];

const router = new VueRouter({
  mode: "history", // history 모드는 자연스러운 url 가능, 지정하지 않으면 해시(#)기호로 url 사용
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  if (!window.location.host.indexOf("mbting")) {
    if (ACCESS_TOKEN in window.localStorage) {
      // need to verify
      store.dispatch("mbtingStore/verifyToken");
      next();
    } else if (
      !(ACCESS_TOKEN in window.localStorage) &&
      !(REFRESH_TOKEN in window.localStorage)
    ) {
      // need to login
      next();
    }
  } 
});

export default router;
