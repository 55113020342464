<template>
  <v-container fluid>
    <!-- <Adsense
      data-ad-client="ca-pub-5746542177729335"
      data-ad-slot="3800733658"
      data-ad-format="auto"
      data-full-width-responsive="true"
    >
    </Adsense> -->
    <v-row>
      <v-col cols="12" sm="12" md="6" offset-md="3">
        <v-card class="mt-2">
          <v-row justify="center">
            <v-btn
              v-scroll="onScroll"
              v-show="fab"
              fab
              dark
              fixed
              bottom
              right
              color="primary"
              @click="toTop"
            >
              <!-- <v-icon>keyboard_arrow_up</v-icon> -->
              위로
            </v-btn>
          </v-row>
        </v-card>

        <v-card class="mx-auto" max-width="600" outlined>
          <v-list-item two-line dense>
            <v-list-item-content>
              <v-list-item-title>
                <v-row class="text-left">
                  <v-col cols="6">
                    <v-card-text class="font-weight-bold"
                      >사용자
                      <v-list-item-subtitle class="mt-1">{{
                        this.boardList.username
                      }}</v-list-item-subtitle>
                    </v-card-text>
                  </v-col>
                  <v-col cols="6">
                    <v-card-text class="font-weight-bold"
                      >날짜
                      <v-list-item-subtitle class="mt-1">{{
                        this.boardList.date
                      }}</v-list-item-subtitle>
                    </v-card-text>
                  </v-col>
                </v-row>
              </v-list-item-title>
              <v-divider></v-divider>
              <v-list-item-title>
                <v-row class="text-left">
                  <v-col cols="6">
                    <v-card-text class="font-weight-bold"
                      >MBTI 타입
                      <v-list-item-subtitle class="mt-1">{{
                        this.boardList.mbtitype
                      }}</v-list-item-subtitle>
                    </v-card-text>
                  </v-col>
                  <v-col cols="6">
                    <v-card-text class="font-weight-bold"
                      >조회수
                      <v-list-item-subtitle class="mt-1">{{
                        this.boardList.hitcount
                      }}</v-list-item-subtitle>
                    </v-card-text>
                  </v-col>
                </v-row>
              </v-list-item-title>
              <v-divider></v-divider>
              <v-list-item-title>
                <v-row class="text-left">
                  <v-col cols="12">
                    <v-card-text class="font-weight-bold"
                      >제목
                      <v-list-item-subtitle class="mt-1">{{
                        this.boardList.subject
                      }}</v-list-item-subtitle>
                    </v-card-text>
                  </v-col>
                </v-row>
              </v-list-item-title>
              <v-list-item-title>
                <v-row class="text-left">
                  <v-col cols="12">
                    <v-card-text class="font-weight-bold"
                      >내용
                      <v-list-item-subtitle
                        class="mt-1"
                        style="white-space: normal"
                        >{{ this.boardList.content }}</v-list-item-subtitle
                      >
                    </v-card-text>
                  </v-col>
                </v-row>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-card-actions>
            <v-container class="text-left">
              <v-btn
                outlined
                rounded
                text
                @click="gotoBoard"
                class="amber lighten-1"
              >
                <!-- <v-icon>mdi-format-list-bulleted-square</v-icon> -->
                목록
              </v-btn>
              <v-btn
                v-if="isLoggedIn"
                outlined
                rounded
                text
                class="green lighten-1 ml-1"
                @click="openUpdateForm()"
              >
                수정
              </v-btn>
              <v-dialog
                v-if="updateDialog"
                v-model="updateDialog"
                max-width="500px"
              >
                <v-card>
                  <v-card-title>
                    <span class="text-h6">MBTI 게시판 글 수정하기</span>
                  </v-card-title>
                  <v-card-text>
                    <v-container>
                      <v-row>
                        <v-col cols="5" sm="5">
                          <v-autocomplete
                            label="MBTI 타입"
                            :items="items"
                            :rules="selectrules"
                            :search-input.sync="updateData.mbtitype"
                            v-model="updateData.mbtitype"
                            outlined
                            required
                          >
                          </v-autocomplete>
                        </v-col>
                        <!-- <v-col cols="5" sm="5" offset-md="2">
                          <v-text-field
                            label="사용자"
                            :counter="10"
                            :rules="namerules"
                            v-model="getUserInfo.userid"
                            required
                          ></v-text-field>
                        </v-col> -->
                      </v-row>
                      <v-row>
                        <v-col cols="12">
                          <v-text-field
                            label="제목"
                            :counter="20"
                            :rules="subjectrules"
                            v-model="updateData.subject"
                            outlined
                            required
                          ></v-text-field>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="12">
                          <v-textarea
                            label="내용"
                            :counter="200"
                            :rules="contentrules"
                            v-model="updateData.content"
                            outlined
                            required
                          ></v-textarea>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                      class="green lighten-1 ml-1"
                      outlined
                      rounded
                      text
                      @click="watchUpdateDialog = true"
                      :disabled="watchUpdateDialog"
                      :loading="watchUpdateDialog"
                      >수정
                    </v-btn>

                    <v-btn
                      class="deep-purple lighten-1 ml-1"
                      outlined
                      rounded
                      text
                      @click="closeDialog"
                    >
                      닫기
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
              <v-btn
                v-if="isLoggedIn"
                outlined
                rounded
                text
                class="deep-purple lighten-1 ml-1"
                @click="deleteForm()"
              >
                <!-- <v-icon>mdi-trash-can</v-icon> -->
                삭제
              </v-btn>
            </v-container>
          </v-card-actions>
        </v-card>
        <v-card class="mx-auto" max-width="600px" outlined>
          <v-form>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    v-if="!writeReplyDialog"
                    class="mt-2"
                    outlined
                    placeholder="댓글을 남겨보세요!"
                    @click="openWriteReplyForm()"
                  ></v-text-field>
                  <v-container v-else>
                    <v-row>
                      <v-col cols="12">
                        <v-textarea
                          autofocus
                          v-model="writeReplyData.message"
                          :counter="40"
                          :rules="replyrules"
                          outlined
                          clearable
                          filled
                          required
                          single-line
                          placeholder="댓글을 남겨주세요."
                          @click:clear="replyFormClear()"
                        ></v-textarea>
                      </v-col>
                    </v-row>
                    <v-row align="center" justify="end">
                      <v-col cols="auto" class="text-right">
                        <v-btn
                          :disabled="!writeReplyData.message"
                          class="green lighten-1"
                          @click="watchWriteReplyDialog = true"
                          rounded
                          >쓰기</v-btn
                        >
                        <v-btn
                          class="deep-purple lighten-1 ml-1"
                          @click="closeDialog"
                          rounded
                          >닫기</v-btn
                        >
                      </v-col>
                    </v-row>
                  </v-container>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card>
        <v-card class="mx-auto" max-width="600px" outlined>
          <v-row class="text-left">
            <v-col>
              <v-card-text class="font-weight-bold"
                >댓글 [ {{ getCountReply() }} ]</v-card-text
              >
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-data-table
                :headers="headers"
                :items="boardReplyList"
                :page.sync="page"
                :items-per-page="itemsPerPage"
                :sort-by="['id', 'date']"
                :sort-desc="[true, true]"
                item-key="id"
                class="elevation-0"
                @page-count="pageCount = $event"
                dense
                hide-default-header
                hide-default-footer
                :expanded.sync="expanded"
                :single-expand="singleExpand"
              >
                <template v-slot:item="{ item, expand, isExpanded }">
                  <table
                    style="
                      width: 100%;
                      border-collapse: separate;
                      border-spacing: 0 5px;
                    "
                  >
                    <td style="width: 6%">
                      <tr>
                        <td>
                          <v-icon class="ml-2">mdi-face-man-outline</v-icon>
                        </td>
                      </tr>
                    </td>
                    <td>
                      <tr>
                        <td style="font-size: 16px">
                          <!-- <v-card-actions> -->
                          <v-menu bottom offset-y>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                class="ml-10 ma-2 background-color: transparent"
                                v-bind="attrs"
                                v-on="on"
                                v-ripple="false"
                                icon
                              >
                                {{ item.username }}
                              </v-btn>
                            </template>
                            <v-list v-if="isLoggedIn">
                              <v-list-item
                                v-for="(menuitem, i) in replyMenuItems"
                                :key="i"
                                @click="
                                  replyNameClick(item.id, menuitem.action)
                                "
                              >
                                <v-list-item-title>{{
                                  menuitem.title
                                }}</v-list-item-title>
                              </v-list-item>
                            </v-list>
                          </v-menu>
                          <!-- </v-card-actions> -->
                        </td>
                      </tr>
                      <tr>
                        <td style="font-size: 13px; padding-left: 2px">
                          {{ item.message }}
                        </td>
                      </tr>
                      <tr>
                        <td style="font-size: 11px; padding-left: 2px">
                          {{ item.date }}
                        </td>
                        <td style="font-size: 11px; padding-left: 6px">
                          <v-btn
                            v-if="isLoggedIn"
                            class="ma-2 background-color: transparent"
                            style="font-size: 12px"
                            v-ripple="false"
                            icon
                            @click="
                              expand(!isExpanded),
                                (writeRereplyData.id = item.id)
                            "
                            >댓글 쓰기</v-btn
                          >
                        </td>
                      </tr>
                    </td>
                  </table>
                  <v-card
                    class="ml-6"
                    max-width="560px"
                    outlined
                    color="transparent"
                  >
                    <!-- <v-container> -->
                    <v-data-table
                      :headers="headers"
                      :items="getRereplyList(item.id)"
                      item-key="id"
                      class="elevation-0"
                      @page-count="pageCount = $event"
                      dense
                      hide-default-header
                      hide-default-footer
                    >
                      <template v-slot:item="{ item }">
                        <table
                          v-show="item"
                          style="
                            width: 100%;
                            border-collapse: separate;
                            border-spacing: 0 5px;
                          "
                        >
                          <td style="width: 6%">
                            <tr>
                              <td>
                                <v-icon class="ml-2"
                                  >mdi-subdirectory-arrow-right</v-icon
                                >
                              </td>
                              <td>
                                <v-icon class="ml-2"
                                  >mdi-face-man-outline</v-icon
                                >
                              </td>
                            </tr>
                          </td>
                          <td>
                            <tr>
                              <td style="font-size: 16px">
                                <v-menu bottom offset-y>
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                      class="ml-10 ma-2 background-color: transparent"
                                      v-bind="attrs"
                                      v-on="on"
                                      v-ripple="false"
                                      icon
                                    >
                                      {{ item.username }}
                                    </v-btn>
                                  </template>
                                  <v-list v-if="isLoggedIn">
                                    <v-list-item
                                      v-for="(menuitem, i) in replyMenuItems"
                                      :key="i"
                                      @click="
                                        rereplyNameClick(
                                          item.id,
                                          menuitem.action
                                        )
                                      "
                                    >
                                      <v-list-item-title>{{
                                        menuitem.title
                                      }}</v-list-item-title>
                                    </v-list-item>
                                  </v-list>
                                </v-menu>
                              </td>
                            </tr>
                            <tr>
                              <td style="font-size: 13px; padding-left: 2px">
                                {{ item.message }}
                              </td>
                            </tr>
                            <tr>
                              <td style="font-size: 11px; padding-left: 2px">
                                {{ item.date }}
                              </td>
                            </tr>
                          </td>
                        </table>
                      </template>
                      <template slot="no-data">
                        <tr style="visibility: hidden"></tr>
                      </template>
                    </v-data-table>
                  </v-card>
                  <!-- <v-divider></v-divider> -->
                </template>
                <template slot="no-data">
                  <div>등록된 댓글이 없습니다.</div>
                </template>
                <template v-slot:expanded-item>
                  <tr>
                    <td>
                      <v-container>
                        <v-row>
                          <v-col cols="12">
                            <v-textarea
                              autofocus
                              v-model="writeRereplyData.message"
                              :counter="40"
                              :rules="replyrules"
                              outlined
                              clearable
                              filled
                              required
                              single-line
                              placeholder="댓글을 남겨주세요."
                            ></v-textarea>
                          </v-col>
                        </v-row>
                        <v-row align="center" justify="end">
                          <v-col cols="auto" class="text-right">
                            <v-btn
                              class="green lighten-1"
                              rounded
                              @click="watchWriteRereplyDialog = true"
                              >쓰기</v-btn
                            >
                            <v-btn
                              class="deep-purple lighten-1 ml-1"
                              rounded
                              @click="expanded = []"
                              >닫기</v-btn
                            >
                          </v-col>
                        </v-row>
                      </v-container>
                    </td>
                  </tr>
                </template>
              </v-data-table>
              <v-dialog v-model="updateReplyDialog" max-width="500px" absolute>
                <v-card>
                  <v-card-title>
                    <span class="text-h6">댓글 수정하기</span>
                  </v-card-title>
                  <v-card-text>
                    <v-row justify="center">
                      <v-col cols="12">
                        <v-text-field
                          v-model="updateReplyData.message"
                          outlined
                          clearable
                          type="text"
                          filled
                          :counter="40"
                          :rules="replyrules"
                          required
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row justify="center">
                      <v-col cols="12">
                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn
                            outlined
                            rounded
                            text
                            class="green lighten-1 ml-1"
                            @click="watchUpdateReplyDialog = true"
                            :disabled="watchUpdateReplyDialog"
                            :loading="watchUpdateReplyDialog"
                          >
                            수정
                          </v-btn>
                          <v-btn
                            class="deep-purple lighten-1 ml-1"
                            outlined
                            rounded
                            text
                            @click="closeDialog"
                            >닫기
                          </v-btn>
                        </v-card-actions>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-dialog>
              <v-dialog
                v-model="updateRereplyDialog"
                max-width="500px"
                absolute
              >
                <v-card>
                  <v-card-title>
                    <span class="text-h6">댓글 수정하기</span>
                  </v-card-title>
                  <v-card-text>
                    <v-row justify="center">
                      <v-col cols="12">
                        <v-text-field
                          v-model="updateRereplyData.message"
                          outlined
                          clearable
                          type="text"
                          filled
                          :counter="40"
                          :rules="replyrules"
                          required
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row justify="center">
                      <v-col cols="12">
                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn
                            outlined
                            rounded
                            text
                            class="green lighten-1 ml-1"
                            @click="watchUpdateRereplyDialog = true"
                            :disabled="watchUpdateRereplyDialog"
                            :loading="watchUpdateRereplyDialog"
                          >
                            수정
                          </v-btn>
                          <v-btn
                            class="deep-purple lighten-1 ml-1"
                            outlined
                            rounded
                            text
                            @click="closeDialog"
                            >닫기
                          </v-btn>
                        </v-card-actions>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-dialog>
              <!-- 대댓글 수정&삭제 끝 -->
              <!-- 로딩 바 -->
              <v-dialog
                v-model="watchUpdateDialog"
                hide-overlay
                persistent
                width="300"
              >
                <v-card color="green" dark>
                  <v-card-text>
                    Updating...
                    <v-progress-linear
                      indeterminate
                      color="white"
                      class="mb-0"
                    ></v-progress-linear>
                  </v-card-text>
                </v-card>
              </v-dialog>
              <v-dialog
                v-model="watchWriteReplyDialog"
                hide-overlay
                persistent
                width="300"
              >
                <v-card color="green" dark>
                  <v-card-text>
                    Writting...
                    <v-progress-linear
                      indeterminate
                      color="white"
                      class="mb-0"
                    ></v-progress-linear>
                  </v-card-text>
                </v-card>
              </v-dialog>
              <v-dialog
                v-model="watchWriteRereplyDialog"
                hide-overlay
                persistent
                width="300"
              >
                <v-card color="green" dark>
                  <v-card-text>
                    Writting...
                    <v-progress-linear
                      indeterminate
                      color="white"
                      class="mb-0"
                    ></v-progress-linear>
                  </v-card-text>
                </v-card>
              </v-dialog>
              <v-dialog
                v-model="watchUpdateRereplyDialog"
                hide-overlay
                persistent
                width="300"
              >
                <v-card color="green" dark>
                  <v-card-text>
                    Updating...
                    <v-progress-linear
                      indeterminate
                      color="white"
                      class="mb-0"
                    ></v-progress-linear>
                  </v-card-text>
                </v-card>
              </v-dialog>
              <v-pagination v-model="page" :length="pageCount"></v-pagination>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<!-- <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-5746542177729335"
     crossorigin="anonymous"></script> -->
<script>
import axios from "axios";
import { mapGetters, mapState } from "vuex";
let boardurl = "api/board/";
let boardreplyurl = "api/boardreply/";
let boardrereplyurl = "api/boardrereply/";

export default {
  name: "MbtiBoardDetail",
  data: () => ({
    id: 0,
    page: 1,
    pageCount: 0,
    itemsPerPage: 20,
    interval: {},
    value: 0,
    fab: false,
    expanded: [],
    singleExpand: true,
    boardnum: 0,
    boardreplynum: 0,
    boardrereplynum: 0,
    headers: [
      // {
      //     text: 'N',
      //     align: 'start',
      //     sortable: false,
      // },
      {
        text: "내용",
        align: "start",
        sortable: false,
        value: "message",
      },
      { text: "사용자", value: "username", sortable: false },
      { text: "날짜", value: "date", sortable: false },
    ],
    replyMenuItems: [
      {
        title: "수정하기",
        action: "update",
      },
      {
        title: "삭제하기",
        action: "delete",
      },
    ],
    items: [
      "INFP",
      "ENFP",
      "INFJ",
      "ENFJ",
      "INTJ",
      "ENTJ",
      "INTP",
      "ENTP",
      "ISFP",
      "ESFP",
      "ISTP",
      "ESTP",
      "ISFJ",
      "ESFJ",
      "ISTJ",
      "ESTJ",
    ],
    boardOwn: false,
    boardList: [],
    boardReplyList: [],
    boardRereplyList: [],
    updateData: {
      mbtitype: "",
      subject: "",
      content: "",
      username: "",
    },
    writeReplyData: {
      message: "",
      date: "",
    },
    updateReplyData: {
      id: 0,
      message: "",
    },
    deleteReplyData: {
      id: 0,
    },
    writeRereplyData: {
      uid: "",
      username: "",
      message: "",
      date: "",
    },
    updateRereplyData: {
      id: 0,
      message: "",
    },
    deleteRereplyData: {
      id: 0,
    },
    updateDialog: false,
    writeReplyDialog: false,
    updateReplyDialog: false,
    updateRereplyDialog: false,
    // loading bar 용 dialog
    watchUpdateDialog: false,
    watchWriteReplyDialog: false,
    watchUpdateReplyDialog: false,
    watchWriteRereplyDialog: false,
    watchUpdateRereplyDialog: false,
    namerules: [
      (v) => !!v || "사용자 이름은 필수 입력사항 입니다.",
      (v) =>
        !(v && v.length >= 10) || "사용자 이름은 10자 이상 입력할 수 없습니다.",
      (v) =>
        !/[~!@#$%^&*()_+|<>?:{}]/.test(v) ||
        "사용자 이름에는 특수문자를 사용할 수 없습니다.",
    ],
    subjectrules: [
      (v) => !!v || "제목은 필수 입력사항 입니다.",
      (v) => !(v && v.length >= 20) || "제목은 20자 이상 입력할 수 없습니다.",
      // v => !/[~!@#$%^&*()_+|<>?:{}]/.test(v) || '제목에는 특수문자를 사용할 수 없습니다.'
    ],
    contentrules: [
      (v) => !!v || "내용은 필수 입력사항 입니다.",
      (v) => !(v && v.length >= 200) || "내용은 200자 이상 입력할 수 없습니다.",
      // v => !/[~!@#$%^&*()_+|<>?:{}]/.test(v) || '제목에는 특수문자를 사용할 수 없습니다.'
    ],
    selectrules: [(v) => !!v || "MBTI 타입은 필수 입력사항 입니다."],
    passwordrules: [
      (v) => !!v || "패스워드는 필수 입력사항 입니다.",
      (v) =>
        !(v && v.length >= 10) || "패스워드는 10자 이상 입력할 수 없습니다.",
    ],
    replyrules: [
      (v) => !(v && v.length >= 40) || "댓글은 40자 이상 입력할 수 없습니다.",
    ],
  }),
  computed: {
    ...mapState('mbtingStore', ["isLoggedIn", "user"]),
    ...mapGetters('mbtingStore', ["isLogin", "getUserInfo"]),
  },
  async created() {
    this.boardnum = this.$route.params.id;
    await this.getBoard(this.boardnum);
    await this.getBoardReply(this.boardnum);
  },
  watch: {
    watchUpdateDialog(val) {
      if (!val) return;

      setTimeout(
        () => (
          this.updateForm(),
          ((this.updateDialog = false), (this.watchUpdateDialog = false))
        ),
        1000
      );
    },
    watchWriteReplyDialog(val) {
      if (!val) return;

      setTimeout(
        () => (this.writeReplyForm(), (this.watchWriteReplyDialog = false)),
        1000
      );
    },
    watchUpdateReplyDialog(val) {
      if (!val) return;

      setTimeout(
        () => (this.updateReplyForm(), (this.watchUpdateReplyDialog = false)),
        1000
      );
    },
    watchWriteRereplyDialog(val) {
      if (!val) return;

      setTimeout(
        () => (this.writeRereplyForm(), (this.watchWriteRereplyDialog = false)),
        1000
      );
    },
    watchUpdateRereplyDialog(val) {
      if (!val) return;

      setTimeout(
        () => (
          this.updateRereplyForm(), (this.watchUpdateRereplyDialog = false)
        ),
        1000
      );
    },
  },
  mounted() {},
  methods: {
    async checkAuth() {
      return await this.isLoggedIn;
    },
    // 글 수정
    async getBoardRefresh(boardnum) {
      await this.getBoard(boardnum);
      await this.getBoardReply(boardnum);
    },
    openUpdateForm() {
      this.checkUpdateForm(this.boardnum)
        .then((ret) => {
          if (!ret) {
            alert("글쓴이 본인만 수정 가능 합니다.");
            return;
          } else {
            this.updateDialog = true;
          }
        })
        .catch((err) => console.log(err));
    },
    checkUpdateForm(id) {
      return axios.get(boardurl + id + "/", {}).then((response) => {
        if (response.data.username == this.getUserInfo.userid) {
          this.updateData.mbtitype = response.data.mbtitype;
          this.updateData.subject = response.data.subject;
          this.updateData.content = response.data.content;
          this.updateData.username = response.data.username;
          return true;
        }
        return false;
      });
    },
    updateForm: function () {
      if (
        this.updateData.mbtitype == null ||
        this.updateData.subject == "" ||
        this.updateData.content == "" ||
        this.getUserInfo.userid == ""
      ) {
        alert("데이터가 유효하지 않습니다.");
        return;
      }

      axios({
        method: "PATCH",
        url: boardurl + this.boardnum + "/",
        data: this.updateData,
      })
        .then(() => {
          this.getBoard(this.boardnum);
          this.updateData.mbtitype = "";
          this.updateData.subject = "";
          this.updateData.content = "";
          this.updateData.username = "";
          this.updateDialog = false;
          this.getBoardRefresh(this.boardnum);
        })
        .catch((error) => {
          console.log("axios patch error: ", error.reponse);
        });
    },

    // 글 삭제
    deleteForm: function () {
      this.checkDeleteForm(this.boardnum)
        .then((ret) => {
          if (!ret) {
            alert("글쓴이 본인만 삭제 가능 합니다.");
            return;
          } else {
            if (confirm("정말로 삭제 하시겠습니까?")) {
              axios({
                method: "DELETE",
                url: boardurl + this.boardnum + "/",
              })
                .then(() => {
                  this.$router.push({ name: "MbtiBoard" });
                  this.$emit("deleted");
                })
                .catch((error) => {
                  console.log("axios board del error: ", error.reponse);
                });
            }
          }
        })
        .catch((err) => console.log(err));
    },

    checkDeleteForm(id) {
      return axios.get(boardurl + id + "/", {}).then((response) => {
        if (response.data.username == this.getUserInfo.userid) {
          return true;
        }
        return false;
      });
    },
    // 댓글 쓰기
    openWriteReplyForm: function () {
      this.checkAuth()
        .then((ret) => {
          if (!ret) {
            this.writeReplyDialog = false;
            alert("로그인 후 댓글 쓰기가 가능합니다.");
            return;
          } else {
            this.writeReplyDialog = true;
          }
        })
        .catch((err) => console.log(err));
    },
    
    writeReplyForm: function () {
      if (this.writeReplyData.message == "") {
        alert("데이터가 유효하지 않습니다.");
        return;
      }

      this.writeReplyData.board_id = this.boardnum;
      this.writeReplyData.username = this.getUserInfo.userid;
      var date = new Date();
      var year = date.getFullYear();
      var month = ("0" + (date.getMonth() + 1)).slice(-2);
      var day = ("0" + date.getDate()).slice(-2);
      var hour = date.getHours();
      var min = date.getMinutes();
      this.writeReplyData.date =
        year + "-" + month + "-" + day + " " + hour + ":" + min;

      axios({
        method: "POST",
        url: boardreplyurl,
        data: this.writeReplyData,
      })
        .then(() => {
          /* 초기화 */
          this.writeReplyData.board_id = "";
          this.writeReplyData.message = "";
          this.writeReplyData.date = "";
          this.writeReplyData.username = "";
          this.writeReplyDialog = false;
          this.getBoardRefresh(this.boardnum);
        })
        .catch((error) => {
          this.writeReplyDialog = false;
          console.log("axios board reply post error: ", error.reponse);
        });
    },
    // 댓글 수정&삭제하기
    replyNameClick(id, action) {
      if (action == "update") {
        this.checkUpdateReplyForm(id)
          .then((ret) => {
            if (!ret) {
              alert("글쓴이 본인만 수정 가능 합니다.");
              return;
            } else {
              this.updateReplyData.id = id;
              this.updateReplyDialog = true;
            }
          })
          .catch((err) => console.log(err));
      } else if (action == "delete") {
        this.checkDeleteReplyForm(id)
          .then((ret) => {
            if (!ret) {
              alert("글쓴이 본인만 삭제 가능 합니다.");
              return;
            } else {
              this.deleteReplyData.id = id;
              if (confirm("정말로 삭제 하시겠습니까?")) {
                axios({
                  method: "DELETE",
                  url: boardreplyurl + this.deleteReplyData.id + "/",
                })
                  .then(() => {
                    this.deleteReplyData.id = 0;
                    this.getBoardRefresh(this.boardnum);
                  })
                  .catch((error) => {
                    console.log("axios board del error: ", error.reponse);
                  });
              }
            }
          })
          .catch((err) => console.log(err));
      }
    },
    checkUpdateReplyForm(id) {
      return axios.get(boardreplyurl + id + "/", {}).then((response) => {
        if (response.data.username == this.getUserInfo.userid) {
          this.updateReplyData.message = response.data.message;
          return true;
        }
        return false;
      });
    },
    checkDeleteReplyForm(id) {
      return axios.get(boardreplyurl + id + "/", {}).then((response) => {
        if (response.data.username == this.getUserInfo.userid) {
          return true;
        }
        return false;
      });
    },
    updateReplyForm: function () {
      if (this.updateReplyData.message == "") {
        alert("데이터가 유효하지 않습니다.");
        return;
      }

      axios({
        method: "PATCH",
        url: boardreplyurl + this.updateReplyData.id + "/",
        data: this.updateReplyData,
      })
        .then(() => {
          this.updateReplyData.message = "";
          this.updateReplyData.id = 0;
          this.updateReplyDialog = false;
          this.getBoardRefresh(this.boardnum);
        })
        .catch((error) => {
          console.log("Axios patch error: ", error.reponse);
        });
    },
    // 대댓글 쓰기
    writeRereplyForm: function () {
      if (this.writeRereplyData.message == "") {
        alert("데이터가 유효하지 않습니다.");
        return;
      }

      this.writeRereplyData.reply_id = this.writeRereplyData.id;
      this.writeRereplyData.username = this.getUserInfo.userid;
      var date = new Date();
      var year = date.getFullYear();
      var month = ("0" + (date.getMonth() + 1)).slice(-2);
      var day = ("0" + date.getDate()).slice(-2);
      var hour = date.getHours();
      var min = date.getMinutes();
      this.writeRereplyData.date =
        year + "-" + month + "-" + day + " " + hour + ":" + min;

      axios({
        method: "POST",
        url: boardrereplyurl,
        data: this.writeRereplyData,
      })
        .then(() => {
          // this.getList(this.boardnum);
          // this.$emit('repsaved');
          /* 초기화 */
          this.writeRereplyData.reply_id = "";
          this.writeRereplyData.message = "";
          this.writeRereplyData.date = "";
          this.writeRereplyData.username = "";
          this.getBoardRefresh(this.boardnum);

          this.expanded = [];
        })
        .catch((error) => {
          console.log("axios board rereply post error: ", error.reponse);
        });
    },

    // 대댓글 수정&삭제 하기
    rereplyNameClick(id, action) {
      if (action == "update") {
        this.checkUpdateRereplyForm(id)
          .then((ret) => {
            if (!ret) {
              alert("글쓴이 본인만 수정 가능 합니다.");
              return;
            } else {
              this.updateRereplyData.id = id;
              this.updateRereplyDialog = true;
            }
          })
          .catch((err) => console.log(err));
      } else if (action == "delete") {
        this.checkDeleteRereplyForm(id)
          .then((ret) => {
            if (!ret) {
              alert("글쓴이 본인만 삭제 가능 합니다.");
              return;
            } else {
              this.deleteRereplyData.id = id;
              if (confirm("정말로 삭제 하시겠습니까?")) {
                axios({
                  method: "DELETE",
                  url: boardrereplyurl + this.deleteRereplyData.id + "/",
                })
                  .then(() => {
                    this.deleteRereplyData.id = id;
                    this.getBoardRefresh(this.boardnum);
                  })
                  .catch((error) => {
                    console.log(
                      "axios board rereply del error: ",
                      error.reponse
                    );
                  });
              }
            }
          })
          .catch((err) => console.log(err));
      }
    },

    checkUpdateRereplyForm(id) {
      return axios.get(boardrereplyurl + id + "/", {}).then((response) => {
        if (response.data.username == this.getUserInfo.userid) {
          this.updateRereplyData.message = response.data.message;
          return true;
        }
        return false;
      });
    },
    updateRereplyForm: function () {
      if (this.updateRereplyData.message == "") {
        alert("데이터가 유효하지 않습니다.");
        return;
      }

      axios({
        method: "PATCH",
        url: boardrereplyurl + this.updateRereplyData.id + "/",
        data: this.updateRereplyData,
      })
        .then(() => {
          this.updateRereplyData.message = "";
          this.updateRereplyData.id = 0;
          this.updateRereplyDialog = false;
          this.getBoardRefresh(this.boardnum);
        })
        .catch((error) => {
          console.log("axios board rereply patch error: ", error.reponse);
        });
    },

    checkDeleteRereplyForm(id) {
      return axios.get(boardrereplyurl + id + "/", {}).then((response) => {
        if (response.data.username == this.getUserInfo.userid) {
          return true;
        }
        return false;
      });
    },
    deleteRereplyForm: function () {
      axios({
        method: "DELETE",
        url: boardrereplyurl + this.deleteRereplyData.id + "/",
      })
        .then(() => {
          this.deleteRereplyData.id = 0;
        })
        .catch((error) => {
          console.log("Axios del error: ", error.reponse);
        });
    },
    async getBoard(id) {
      await axios({
        method: "GET",
        url: boardurl + id + "/",
      })
        .then((response) => {
          console.log(response)
          if (this.boardList.length > 0) {
            this.boardList = [];
          }
          this.boardList = response.data;
        })
        .catch((response) => {
          console.log("axios board get error: ", response);
        });
    },
    async getBoardReply(board_id) {
      await axios({
        method: "GET",
        url: boardreplyurl + "?board_id=" + board_id,
      })
        .then((response) => {
          if (this.boardReplyList.length > 0) {
            this.boardReplyList = [];
          }

          this.boardReplyList = response.data.results;
          if (this.boardRereplyList.length > 0) {
            this.boardRereplyList = [];
          }
          for (var idx in this.boardReplyList) {
            this.getBoardRereply(this.boardReplyList[idx].id);
          }
        })
        .catch((response) => {
          console.log("axios board reply get error: ", response);
        });
    },
    async getBoardRereply(reply_id) {
      await axios({
        method: "GET",
        url: boardrereplyurl + "?reply_id=" + reply_id,
      })
        .then((response) => {
          for (var idx in response.data.results) {
            if (response.data.results[idx].reply_id == reply_id) {
              this.boardRereplyList.push(response.data.results[idx]);
            }
          }
        })
        .catch((response) => {
          console.log("axios board rereply get error: ", response);
        });
    },

    getRereplyList(id) {
      var obj = [];
      this.boardRereplyList.forEach(function (entry) {
        if (entry.reply_id == id) {
          obj.push(entry);
        }
      });
      return obj;
    },
    getCountReply: function () {
      const count = Object.keys(this.boardReplyList).length;
      return count;
    },
    gotoBoard: function () {
      this.$router.push({ name: "MbtiBoard" });
      // this.$emit('patched');
    },
    replyFormClear() {
      this.writeReplyData.message = "";
      this.writeReplyData.username = "";
      this.writeReplyDialog = false;
    },
    closeDialog: function () {
      this.updateDialog = false;
      this.writeReplyDialog = false;

      this.updateReplyDialog = false;
      this.updateRereplyDialog = false;

      this.updateData.mbtitype = "";
      this.updateData.subject = "";
      this.updateData.content = "";
      this.updateData.username = "";

      this.writeReplyData.uid = "";
      this.writeReplyData.username = "";
      this.writeReplyData.message = "";
      this.writeReplyData.date = "";

      this.writeRereplyData.uid = "";
      this.writeRereplyData.username = "";
      this.writeRereplyData.message = "";
      this.writeRereplyData.date = "";

      this.updateReplyData.id = 0;
      this.updateReplyData.message = "";

      this.expanded = [];
    },
    onScroll(e) {
      if (typeof window === "undefined") return;
      const top = window.pageYOffset || e.target.scrollTop || 0;
      this.fab = top > 20;
    },
    toTop() {
      this.$vuetify.goTo(0);
    },
  },
};
</script>
