import Vue from "vue";
import VueRouter from "vue-router";
import store from "../store";
import AskmeMain from "../views/AskmeMain.vue";
import AskmeDetail from "../views/AskmeDetail.vue";
import { ACCESS_TOKEN, REFRESH_TOKEN } from "../services/api/askmeService";
// import { store } from "@/store/store";
// import VueCookies from 'vue-cookies';

Vue.use(VueRouter);

const routes = [
  {
    path: "/", // 경로
    name: "AskmeMain", // 해당 경로의 이름
    component: AskmeMain, // 이동할 컴포넌트
  },
  {
    path: "/:userid",
    name: "AskmeDetail",
    component: AskmeDetail,
  },
];

const router = new VueRouter({
  mode: "history", // history 모드는 자연스러운 url 가능, 지정하지 않으면 해시(#)기호로 url 사용
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  if (!window.location.host.indexOf("askme")) {
    if (ACCESS_TOKEN in window.localStorage) {
      // need to verify
      store.dispatch("askmeStore/verifyToken");
      next();
    } else if (
      !(ACCESS_TOKEN in window.localStorage) &&
      !(REFRESH_TOKEN in window.localStorage)
    ) {
      // need to login
      next();
    }
  }
});

export default router;
