<template>
  <div id="app">
    <header>
        <h1> 로또 랜덤 발생기 v1.0 </h1>
        <!-- nav -->
        <router-link to="/lottorandom">랜덤 뽑기</router-link>
        <router-link to="/lottohistory">이력 조회</router-link>
        <router-link to="/lottosimulator">시뮬레이션</router-link>
    </header>
    <router-view></router-view>
    <LottoFooter></LottoFooter>
  </div>
</template>
<!-- <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-5746542177729335"
     crossorigin="anonymous"></script> -->
<script>
// import LottoHistory from './components/LottoHistory'
// import LottoRandom from './components/LottoRandom'
import LottoFooter from '../components/LottoFooter'

export default {
  name: 'app',
  data () {
    return {
     
    }
  },
  components: { 
    // 'LottoHistory': LottoHistory,
    // 'LottoRandom' : LottoRandom,
    'LottoFooter' : LottoFooter,
    
  }
}
</script>

<style>

header { 
    color : white;
    background-color: #35495e;
}
a {
        color: #fff;
        margin-left: 10px;
        align-self: center;
}
.router-link-exact-active { 
    color : #fff;
}
#app {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}

h1, h2 {
  font-weight: normal;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  display: inline-block;
  margin: 0 10px;
}

a {
  color: #42b983;
}
</style>
