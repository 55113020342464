var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',[_c('v-container',[_c('h3',[_vm._v(" 나의 최고 등수 : "),_c('font',{attrs:{"color":"red"}},[_vm._v(_vm._s(_vm.my_num)+"등")])],1),_c('p',[_vm._v("이정도면, 자랑할만 하네요 :)")]),_c('v-btn',{staticClass:"ma-2",attrs:{"outlined":"","rounded":"","text":""},on:{"click":_vm.gotcha_start}},[_vm._v(" 공유/인증하기 ")]),_c('v-card',{staticClass:"mx-auto pa-10 ma-10",attrs:{"max-width":"600px","outlined":""}},[_c('p',{staticClass:"p_title"},[_vm._v(" 가장 최근 로또 번호에 대해서 무제한 뽑기를 수행하며 당신의 운을 체크해 보세요! ")]),_c('p',[_c('strong',[_vm._v(_vm._s(_vm.lotto_round[0][0])+" 회차 번호 ")]),_vm._v("("+_vm._s(_vm.date)+") ")]),_c('div',[_c('v-row',[_c('v-col',{attrs:{"cols":"2"}},[_c('div',{staticClass:"ball",style:({
                backgroundColor: _vm.computedBgColor(_vm.lotto_num[0][0]),
              })},[_vm._v(" "+_vm._s(_vm.lotto_num[0][0])+" ")])]),_c('v-col',{attrs:{"cols":"2"}},[_c('div',{staticClass:"ball",style:({
                backgroundColor: _vm.computedBgColor(_vm.lotto_num[0][1]),
              })},[_vm._v(" "+_vm._s(_vm.lotto_num[0][1])+" ")])]),_c('v-col',{attrs:{"cols":"2"}},[_c('div',{staticClass:"ball",style:({
                backgroundColor: _vm.computedBgColor(_vm.lotto_num[0][2]),
              })},[_vm._v(" "+_vm._s(_vm.lotto_num[0][2])+" ")])]),_c('v-col',{attrs:{"cols":"2"}},[_c('div',{staticClass:"ball",style:({
                backgroundColor: _vm.computedBgColor(_vm.lotto_num[0][3]),
              })},[_vm._v(" "+_vm._s(_vm.lotto_num[0][3])+" ")])]),_c('v-col',{attrs:{"cols":"2"}},[_c('div',{staticClass:"ball",style:({
                backgroundColor: _vm.computedBgColor(_vm.lotto_num[0][4]),
              })},[_vm._v(" "+_vm._s(_vm.lotto_num[0][4])+" ")])]),_c('p',[_c('br'),_vm._v(" + ")]),_c('v-col',{attrs:{"cols":"1"}},[_c('div',{staticClass:"ball",style:({
                backgroundColor: _vm.computedBgColor(_vm.lotto_num[0][4]),
              })},[_vm._v(" "+_vm._s(_vm.lotto_num[0][5])+" ")])])],1),(_vm.is_start)?_c('div',[_c('v-btn',{staticClass:"ma-2",attrs:{"outlined":"","rounded":"","text":""},on:{"click":_vm.gotcha_stop}},[_vm._v(" 시뮬레이션 중지 ")])],1):_c('div',[_c('v-btn',{staticClass:"ma-2",attrs:{"outlined":"","rounded":"","text":""},on:{"click":_vm.gotcha_start}},[_vm._v(" 시뮬레이션 시작 ")])],1)],1)])],1),_c('div',[_c('p',[_c('strong',[_vm._v(_vm._s(_vm.cnt))]),_vm._v(" 게임 중 "),_c('br')]),_c('hr'),_c('p',[_vm._v("1등 당첨 : "+_vm._s(_vm.first_prize)+" 번 ( "+_vm._s(_vm.fir_per)+"%)")]),_c('p',[_vm._v("2등 당첨 : "+_vm._s(_vm.second_prize)+" 번 ( "+_vm._s(_vm.sec_per)+"%)")]),_c('p',[_vm._v("3등 당첨 : "+_vm._s(_vm.third_prize)+" 번 ( "+_vm._s(_vm.third_per)+"%)")]),_c('p',[_vm._v("4등 당첨 : "+_vm._s(_vm.fourth_prize)+" 번 ( "+_vm._s(_vm.fourth_per)+"%)")]),_c('p',[_vm._v("5등 당첨 : "+_vm._s(_vm.fifth_prize)+" 번 ( "+_vm._s(_vm.fifth_per)+"%)")]),_c('p',[_vm._v("낙첨 : "+_vm._s(_vm.no_prize)+" ( "+_vm._s(_vm.no_per)+"%)")]),_c('hr'),_c('p',[_c('strong',[_vm._v(" 결과 ")])]),_c('p'),_c('p',[_c('strong',[_vm._v(" 사용한 돈 ")]),_vm._v(" "+_vm._s(_vm._f("mon_filter")(_vm.get_total_money()))+" 원 ")]),_c('p',[_c('strong',[_vm._v(" 상금 ")]),_vm._v(_vm._s(_vm._f("mon_filter")(_vm.get_prized_money()))+" 원")]),_c('p',[_c('strong',[_vm._v(" 결과 ")]),_vm._v(" "+_vm._s(_vm._f("mon_filter")(_vm.get_result_money()))+" 원")]),_c('hr')])],1)}
var staticRenderFns = []

export { render, staticRenderFns }