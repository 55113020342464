var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"12","md":"6"}},[_c('div',{staticClass:"ma-3",attrs:{"align":"start"}},[_c('font',{attrs:{"size":"5em","color":"black"}},[_c('v-icon',[_vm._v("mdi-more")]),_vm._v(" "),_c('strong',[_vm._v("Conpurple's Daily ")])],1)],1),_c('div',{staticClass:"box",staticStyle:{"overflow":"scroll","height":"400px"}},[_c('v-hover',{staticClass:"ma-3 pa-3",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{class:{ 'on-hover': hover },attrs:{"elevation":hover ? 12 : 2}},[_c('v-spacer'),_c('v-btn',{attrs:{"icon":""}},[_c('v-icon',[_vm._v("mdi-account")]),_c('strong',[_vm._v(" Visitor ")])],1),_c('v-sheet',{staticClass:"mx-auto text-center",attrs:{"color":"white","align":"top","max-width":"calc(100% - 32px)"}},[_c('v-sparkline',{attrs:{"labels":_vm.labels,"auto-draw":"","value":_vm.cnt_history,"color":"blue","line-width":"2","padding":"16","show-labels":_vm.label_value}})],1),_c('v-card-text',{staticClass:"ma-2 pa-2 pt-0"},[_c('div',{staticClass:"subheading font-weight-light grey--text"},[_vm._v(" 오늘 방문자 : "),_c('font',{attrs:{"color":"black"}},[_vm._v(" "+_vm._s(_vm.cnt_today)+" ")]),_c('br'),_vm._v(" 전체 방문자 : "),_c('font',{attrs:{"color":"black"}},[_vm._v(" "+_vm._s(_vm.cnt_total)+" ")]),_c('br')],1)])],1)]}}])}),_c('v-hover',{staticClass:"ma-3 pa-3",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{class:{ 'on-hover': hover },attrs:{"elevation":hover ? 12 : 2}},[_c('v-spacer'),_c('v-btn',{attrs:{"icon":""}},[_c('v-icon',[_vm._v("mdi-account")]),_c('strong',[_vm._v(" Board ")])],1),_c('v-sheet',{staticClass:"mx-auto text-center",attrs:{"color":"white","align":"top","max-width":"calc(100% - 32px)"}},[_c('v-sparkline',{attrs:{"labels":_vm.labels,"auto-draw":"","value":_vm.cnt_board_today,"color":"green","line-width":"2","padding":"16","show-labels":_vm.label_value}})],1),_c('v-card-text',{staticClass:"ma-2 pa-2 pt-0"},[_c('div',{staticClass:"subheading font-weight-light grey--text"},[_vm._v(" 오늘 작성글 : "),_c('font',{attrs:{"color":"black"}},[_vm._v(" "+_vm._s(_vm.cnt_board_today[4])+" ")]),_vm._v(" "),_c('br'),_vm._v(" 전체 작성글 : "),_c('font',{attrs:{"color":"black"}},[_vm._v(" "+_vm._s(_vm.cnt_board_total)+" ")]),_vm._v(" "),_c('br')],1)])],1)]}}])})],1)]),_c('v-col',{attrs:{"sm":"12","md":"6","cols":"12"}},[_c('div',{staticClass:"ma-3",attrs:{"align":"start"}},[_c('font',{attrs:{"size":"5em","color":"black"}},[_c('v-icon',[_vm._v("mdi-more")]),_vm._v(" "),_c('strong',[_vm._v("Conpurple's News")])],1)],1),_c('div',{staticClass:"box",staticStyle:{"overflow":"scroll","height":"400px"}},[_c('v-hover',{staticClass:"ma-3 pa-3",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{class:{ 'on-hover': hover },attrs:{"elevation":hover ? 12 : 2}},[_c('v-spacer'),_c('v-btn',{attrs:{"icon":""}},[_c('v-icon',[_vm._v("mdi-heart")]),_c('strong',[_vm._v(" L2P 소식 ")])],1),_c('h2',[_c('strong',[_vm._v(" Wonderpod 출시 ")])]),_c('h5',{staticClass:"ma-1 pa-1"},[_vm._v("#wonderpod #airpod #airpodpro")]),_c('div',[_c('v-img',{staticClass:"mx-auto",attrs:{"max-width":"200px","src":_vm.items[5].src}}),_c('p',[_c('font',{staticClass:"ma-1 pa-1",attrs:{"size":"2em"}},[_vm._v(" Airpod 전용 Wonderpod이 출시 됩니다!!"),_c('br'),_vm._v(" Airpod, Airpod pro 모두 지원되니 많은 이용 부탁 드립니다 :)"),_c('br'),_c('br')])],1)],1)],1)]}}])}),_c('v-hover',{staticClass:"ma-3 pa-3",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{class:{ 'on-hover': hover },attrs:{"elevation":hover ? 12 : 2}},[_c('v-spacer'),_c('v-btn',{attrs:{"icon":""}},[_c('v-icon',[_vm._v("mdi-heart")]),_c('strong',[_vm._v(" L2P 소식 ")])],1),_c('h2',[_c('strong',[_vm._v(" L2P가 Askme를 런칭합니다:) ")])]),_c('h5',{staticClass:"ma-1 pa-1"},[_vm._v(" #askme #나만의 게시판 #익명질문 #답글달기 ")]),_c('div',[_c('v-img',{attrs:{"src":_vm.items[4].src}}),_c('p',[_c('font',{attrs:{"size":"2em"}},[_vm._v(" 친구에게 익명으로 질문을 남겨보세요. "),_c('br'),_vm._v(" 회원 가입을 하면 나만의 게시판을 만들어 질문을 받을 수 있어요. :) ")])],1)],1)],1)]}}])})],1)]),_c('v-col',{attrs:{"sm":"12","md":"12","cols":"12"}},[_c('div',[_c('InFeedAdsense',{staticStyle:{"display":"block"},attrs:{"data-ad-format":"fluid","data-ad-layout-key":"-e0+46+2h-1n-4v","data-ad-client":"ca-pub-5746542177729335","data-ad-slot":"4278281776"}})],1)]),_c('v-col',{attrs:{"sm":"12","md":"12","cols":"12"}},[_c('div',{staticClass:"ma-3",attrs:{"align":"start"}},[_c('font',{attrs:{"size":"5em","color":"black"}},[_c('v-icon',[_vm._v("mdi-more")]),_vm._v(" "),_c('Strong',[_vm._v("Conpurple's Promotion")])],1)],1),_c('div',{staticClass:"box",staticStyle:{"overflow":"scroll","height":"400px"}},[_c('v-hover',{staticClass:"ma-3 pa-3",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{class:{ 'on-hover': hover },attrs:{"elevation":hover ? 12 : 2}},[_c('v-spacer'),_c('v-btn',{attrs:{"icon":""}},[_c('v-icon',[_vm._v("mdi-youtube")]),_c('strong',[_vm._v(" Qdroid ")])],1),_c('h2',[_c('strong',[_vm._v(" 홍보 영상 ")])]),_c('h5',{staticClass:"ma-1 pa-1"},[_vm._v("#로쮸티비 #Qdroid Guide")]),_c('iframe',{attrs:{"width":"300","height":"315","src":"https://www.youtube.com/embed/3T1DpL_7XnQ"}})],1)]}}])})],1)])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }