<template>
  <v-container fluid>
    <!-- <Adsense
      data-ad-client="ca-pub-5746542177729335"
      data-ad-slot="3800733658"
      data-ad-format="auto"
      data-full-width-responsive="true"
    >
    </Adsense> -->
    <v-row>
      <v-col cols="12" sm="12" md="6" offset-md="3">
        <v-card class="mt-2">
          <v-row justify="center">
            <v-btn
              v-scroll="onScroll"
              v-show="fab"
              fab
              dark
              fixed
              bottom
              right
              color="primary"
              @click="toTop"
            >
              <!-- <v-icon>keyboard_arrow_up</v-icon> -->
              위로
            </v-btn>
          </v-row>
        </v-card>
        <v-card>
          <v-row justify="center">
            <v-card-text
              class="
                my-4
                text-h4 text-center
                blue-grey--text
                text--blue-grey
                darken-1
              "
              >아래 타입을 클릭해보세요!</v-card-text
            >
          </v-row>
          <v-row justify="center">
            <v-btn
              class="
                background-color:
                transparent
                text-center
                outline
                blue--text
              "
              :ripple="false"
              icon
              :href="`https://www.16personalities.com/ko/%EB%AC%B4%EB%A3%8C-%EC%84%B1%EA%B2%A9-%EC%9C%A0%ED%98%95-%EA%B2%80%EC%82%AC`"
              target="_blank"
              color="deep-orange"
            >
              <v-icon>mdi-launch</v-icon>검사하러 가기
            </v-btn>
          </v-row>
          <v-item-group active-class="primary">
            <v-container mt-3>
              <v-row justify="center">
                <v-col cols="6" md="4">
                  <v-item v-slot="{ active, toggle }">
                    <v-card
                      class="d-flex align-center"
                      dark
                      height="100"
                      @click="toggle"
                      v-model="selectedE"
                    >
                      <v-card-text>
                        <v-scroll-y-transition>
                          <div
                            v-if="!active"
                            class="text-h2 flex-grow-1 text-center"
                            @click="toggleE(true)"
                          >
                            E
                          </div>
                          <div
                            v-else
                            class="pt-3 text-h6 flex-grow-1 text-center"
                            @click="toggleE(false)"
                          >
                            E: 외향적
                            <br />
                            <p class="text-caption">
                              폭 넓은 대인관계를 유지하며 사교적이며 정열적이고
                              활동적임.
                            </p>
                          </div>
                        </v-scroll-y-transition>
                      </v-card-text>
                    </v-card>
                  </v-item>
                </v-col>
                <v-spacer></v-spacer>
                <v-col cols="6" md="4">
                  <v-item v-slot="{ active, toggle }">
                    <v-card
                      class="d-flex align-center"
                      dark
                      height="100"
                      @click="toggle"
                      v-model="selectedI"
                    >
                      <v-card-text>
                        <v-scroll-y-transition>
                          <div
                            v-if="!active"
                            class="text-h2 flex-grow-1 text-center"
                            @click="toggleI(true)"
                          >
                            I
                          </div>
                          <div
                            v-else
                            class="pt-3 text-h6 flex-grow-1 text-center"
                            @click="toggleI(false)"
                          >
                            I: 내향적
                            <br />
                            <p class="text-caption">
                              깊이있는 대인관계를 유지하며 조용하고 신중하며
                              이해한 다음에 경험함.
                            </p>
                          </div>
                        </v-scroll-y-transition>
                      </v-card-text>
                    </v-card>
                  </v-item>
                </v-col>
              </v-row>
            </v-container>
          </v-item-group>
          <v-divider></v-divider>
          <v-item-group active-class="primary">
            <v-container>
              <v-row>
                <v-col cols="6" md="4">
                  <v-item v-slot="{ active, toggle }">
                    <v-card
                      class="d-flex align-center"
                      dark
                      height="100"
                      @click="toggle"
                      v-model="selectedS"
                    >
                      <v-card-text>
                        <v-scroll-y-transition>
                          <div
                            v-if="!active"
                            class="text-h2 flex-grow-1 text-center"
                            @click="toggleS(true)"
                          >
                            S
                          </div>
                          <div
                            v-else
                            class="pt-3 text-h6 flex-grow-1 text-center"
                            @click="toggleS(false)"
                          >
                            S: 감각적
                            <br />
                            <p class="text-caption">
                              오감에 의존하여 실제의 경험을 중시하고, 현재에
                              초점을 맞추어 일처리함.
                            </p>
                          </div>
                        </v-scroll-y-transition>
                      </v-card-text>
                    </v-card>
                  </v-item>
                </v-col>
                <v-spacer></v-spacer>
                <v-col cols="6" md="4">
                  <v-item v-slot="{ active, toggle }">
                    <v-card
                      class="d-flex align-center"
                      dark
                      height="100"
                      @click="toggle"
                      v-model="selectedN"
                    >
                      <v-card-text>
                        <v-scroll-y-transition>
                          <div
                            v-if="!active"
                            class="text-h2 flex-grow-1 text-center"
                            @click="toggleN(true)"
                          >
                            N
                          </div>
                          <div
                            v-else
                            class="pt-3 text-h6 flex-grow-1 text-center"
                            @click="toggleN(false)"
                          >
                            N: 직관적
                            <br />
                            <p class="text-caption">
                              육감 내지 영감에 의존하며 미래지향적이고 가능성을
                              추구함.
                            </p>
                          </div>
                        </v-scroll-y-transition>
                      </v-card-text>
                    </v-card>
                  </v-item>
                </v-col>
              </v-row>
            </v-container>
          </v-item-group>
          <v-divider></v-divider>
          <v-item-group active-class="primary">
            <v-container>
              <v-row>
                <v-col cols="6" md="4">
                  <v-item v-slot="{ active, toggle }">
                    <v-card
                      class="d-flex align-center"
                      dark
                      height="100"
                      @click="toggle"
                      v-model="selectedT"
                    >
                      <v-card-text>
                        <v-scroll-y-transition>
                          <div
                            v-if="!active"
                            class="text-h2 flex-grow-1 text-center"
                            @click="toggleT(true)"
                          >
                            T
                          </div>
                          <div
                            v-else
                            class="pt-3 text-h6 flex-grow-1 text-center"
                            @click="toggleT(false)"
                          >
                            T: 사고적
                            <br />
                            <p class="text-caption">
                              진실과 사실에 주관심을 갖고 논리적이고 분석적이며
                              객관적으로 판단함.
                            </p>
                          </div>
                        </v-scroll-y-transition>
                      </v-card-text>
                    </v-card>
                  </v-item>
                </v-col>
                <v-spacer></v-spacer>
                <v-col cols="6" md="4">
                  <v-item v-slot="{ active, toggle }">
                    <v-card
                      class="d-flex align-center"
                      dark
                      height="100"
                      @click="toggle"
                      v-model="selectedF"
                    >
                      <v-card-text>
                        <v-scroll-y-transition>
                          <div
                            v-if="!active"
                            class="text-h2 flex-grow-1 text-center"
                            @click="toggleF(true)"
                          >
                            F
                          </div>
                          <div
                            v-else
                            class="pt-3 text-h6 flex-grow-1 text-center"
                            @click="toggleF(false)"
                          >
                            F: 감정적
                            <br />
                            <p class="text-caption">
                              사람과 관계에 주관심을 갖고 상황적이며 정상을
                              참작한 설명함.
                            </p>
                          </div>
                        </v-scroll-y-transition>
                      </v-card-text>
                    </v-card>
                  </v-item>
                </v-col>
              </v-row>
            </v-container>
          </v-item-group>
          <v-divider></v-divider>
          <v-item-group active-class="primary">
            <v-container>
              <v-row>
                <v-col cols="6" md="4">
                  <v-item v-slot="{ active, toggle }">
                    <v-card
                      class="d-flex align-center"
                      dark
                      height="100"
                      @click="toggle"
                      v-model="selectedJ"
                    >
                      <v-card-text>
                        <v-scroll-y-transition>
                          <div
                            v-if="!active"
                            class="text-h2 flex-grow-1 text-center"
                            @click="toggleJ(true)"
                          >
                            J
                          </div>
                          <div
                            v-else
                            class="pt-3 text-h6 flex-grow-1 text-center"
                            @click="toggleJ(false)"
                          >
                            J: 판단적
                            <br />
                            <p class="text-caption">
                              분명한 목적과 방향이 있으며 기한을 엄수하고 철저히
                              사전 계획하고 체계적임.
                            </p>
                          </div>
                        </v-scroll-y-transition>
                      </v-card-text>
                    </v-card>
                  </v-item>
                </v-col>
                <v-spacer></v-spacer>
                <v-col cols="6" md="4">
                  <v-item v-slot="{ active, toggle }">
                    <v-card
                      class="d-flex align-center"
                      dark
                      height="100"
                      @click="toggle"
                      v-model="selectedP"
                    >
                      <v-card-text>
                        <v-scroll-y-transition>
                          <div
                            v-if="!active"
                            class="text-h2 flex-grow-1 text-center"
                            @click="toggleP(true)"
                          >
                            P
                          </div>
                          <div
                            v-else
                            class="pt-3 text-h6 flex-grow-1 text-center"
                            @click="toggleP(false)"
                          >
                            P: 인식적
                            <br />
                            <p class="text-caption">
                              목적과 방향은 가능하고 상황에 따라 일정이 달라지며
                              자율적이고 융통성 있음.
                            </p>
                          </div>
                        </v-scroll-y-transition>
                      </v-card-text>
                    </v-card>
                  </v-item>
                </v-col>
              </v-row>
            </v-container>
          </v-item-group>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<!-- <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-5746542177729335"
     crossorigin="anonymous"></script> -->
<script>
export default {
  name: "MbtiFeature",
  data: () => ({
    toggle: false,
    first: "",
    fab: false,
    selectedE: false,
    selectedEString: "",
    selectedI: false,
    selectedIString: "",
    selectedS: false,
    selectedSString: "",
    selectedN: false,
    selectedNString: "",
    selectedT: false,
    selectedTString: "",
    selectedF: false,
    selectedFString: "",
    selectedJ: false,
    selectedJString: "",
    selectedP: false,
    selectedPString: "",
  }),
  mounted() {
    this.$vuetify.goTo(0);
  },
  methods: {
    onScroll(e) {
      if (typeof window === "undefined") return;
      const top = window.pageYOffset || e.target.scrollTop || 0;
      this.fab = top > 20;
    },
    toTop() {
      this.$vuetify.goTo(0);
    },
    selectedDone() {
      if (
        (this.selectedE || this.selectedI) &&
        (this.selectedS || this.selectedN) &&
        (this.selectedT || this.selectedF) &&
        (this.selectedJ || this.selectedP)
      ) {
        return true;
      } else {
        return false;
      }
    },
    toggleE(type) {
      if (type == true) {
        this.selectedEString = "외향적";
        this.selectedE = type;
        this.selectedI = false;
      } else {
        this.selectedEString = "";
        this.selectedE = type;
      }
    },
    toggleI(type) {
      if (type == true) {
        this.selectedIString = "내향적";
        this.selectedI = type;
        this.selectedE = false;
      } else {
        this.selectedIString = "";
        this.selectedI = type;
      }
    },
    toggleS(type) {
      if (type == true) {
        this.selectedSString = "감각적";
        this.selectedS = type;
        this.selectedN = false;
      } else {
        this.selectedSString = "";
        this.selectedS = type;
      }
    },
    toggleN(type) {
      if (type == true) {
        this.selectedNString = "직관적";
        this.selectedN = type;
        this.selectedS = false;
      } else {
        this.selectedNString = "";
        this.selectedN = type;
      }
    },
    toggleT(type) {
      if (type == true) {
        this.selectedTString = "사고적";
        this.selectedT = type;
        this.selectedF = false;
      } else {
        this.selectedTString = "";
        this.selectedT = type;
      }
    },
    toggleF(type) {
      if (type == true) {
        this.selectedFString = "감정적";
        this.selectedF = type;
        this.selectedT = false;
      } else {
        this.selectedFString = "";
        this.selectedF = type;
      }
    },
    toggleJ(type) {
      if (type == true) {
        this.selectedJString = "판단적";
        this.selectedJ = type;
        this.selectedP = false;
      } else {
        this.selectedJString = "";
        this.selectedJ = type;
      }
    },
    toggleP(type) {
      if (type == true) {
        this.selectedPString = "인식적";
        this.selectedP = type;
        this.selectedJ = false;
      } else {
        this.selectedPString = "";
        this.selectedP = type;
      }
    },
  },
};
</script>