<template>
  <div>
    <!-- <Adsense
      data-ad-client="ca-pub-5746542177729335"
      data-ad-slot="3800733658"
      data-ad-format="auto"
      data-full-width-responsive="true"
    >
    </Adsense> -->
    <v-row>
      <v-col cols="12" sm="12" md="6">
        <div class="ma-3" align="start">
          <font size="5em" color="black"
            ><v-icon>mdi-more</v-icon> <strong>Conpurple's Daily </strong>
          </font>
        </div>
        <div class="box" style="overflow: scroll; height: 400px">
          <v-hover class="ma-3 pa-3" v-slot="{ hover }">
            <v-card :elevation="hover ? 12 : 2" :class="{ 'on-hover': hover }">
              <v-spacer></v-spacer>
              <v-btn icon>
                <v-icon>mdi-account</v-icon>
                <strong> Visitor </strong>
              </v-btn>
              <v-sheet
                class="mx-auto text-center"
                color="white"
                align="top"
                max-width="calc(100% - 32px)"
              >
                <v-sparkline
                  :labels="labels"
                  auto-draw
                  :value="cnt_history"
                  color="blue"
                  line-width="2"
                  padding="16"
                  :show-labels="label_value"
                ></v-sparkline>
              </v-sheet>
              <v-card-text class="ma-2 pa-2 pt-0">
                <div class="subheading font-weight-light grey--text">
                  오늘 방문자 : <font color="black"> {{ cnt_today }} </font>
                  <br />
                  전체 방문자 : <font color="black"> {{ cnt_total }} </font>
                  <br />
                </div>
              </v-card-text>
            </v-card>
          </v-hover>
          <v-hover class="ma-3 pa-3" v-slot="{ hover }">
            <v-card :elevation="hover ? 12 : 2" :class="{ 'on-hover': hover }">
              <v-spacer></v-spacer>
              <v-btn icon>
                <v-icon>mdi-account</v-icon>
                <strong> Board </strong>
              </v-btn>
              <v-sheet
                class="mx-auto text-center"
                color="white"
                align="top"
                max-width="calc(100% - 32px)"
              >
                <v-sparkline
                  :labels="labels"
                  auto-draw
                  :value="cnt_board_today"
                  color="green"
                  line-width="2"
                  padding="16"
                  :show-labels="label_value"
                ></v-sparkline>
              </v-sheet>
              <v-card-text class="ma-2 pa-2 pt-0">
                <div class="subheading font-weight-light grey--text">
                  오늘 작성글 :
                  <font color="black"> {{ cnt_board_today[4] }} </font> <br />
                  전체 작성글 :
                  <font color="black"> {{ cnt_board_total }} </font> <br />
                </div>
              </v-card-text>
            </v-card>
          </v-hover>
        </div>
      </v-col>
      <v-col sm="12" md="6" cols="12">
        <div class="ma-3" align="start">
          <font size="5em" color="black"
            ><v-icon>mdi-more</v-icon> <strong>Conpurple's News</strong>
          </font>
        </div>

        <div class="box" style="overflow: scroll; height: 400px">
          <v-hover class="ma-3 pa-3" v-slot="{ hover }">
            <v-card :elevation="hover ? 12 : 2" :class="{ 'on-hover': hover }">
              <v-spacer></v-spacer>
              <v-btn icon>
                <v-icon>mdi-heart</v-icon>
                <strong> L2P 소식 </strong>
              </v-btn>
              <h2>
                <strong> Wonderpod 출시 </strong>
              </h2>
              <h5 class="ma-1 pa-1">#wonderpod #airpod #airpodpro</h5>
              <div>
                <v-img max-width="200px" class="mx-auto" :src="items[5].src">
                </v-img>
                <p>
                  <font size="2em" class="ma-1 pa-1">
                    Airpod 전용 Wonderpod이 출시 됩니다!!<br />
                    Airpod, Airpod pro 모두 지원되니 많은 이용 부탁 드립니다 :)<br /><br />
                  </font>
                </p>
              </div>
              <!-- <v-btn
                  class="ma-2 pa-2"
                  :style="{ 'background-color': '#00ff0000' }"
                  :color="transparent"
                >
                  자세히 보기
                </v-btn>
                <v-btn
                  :class="{ 'show-btn': hover }"
                  :style="{ 'background-color': '#00ff0000' }"
                  :color="transparent"
                >
                  경험하기
                </v-btn> -->
            </v-card>
          </v-hover>
          <v-hover class="ma-3 pa-3" v-slot="{ hover }">
            <v-card :elevation="hover ? 12 : 2" :class="{ 'on-hover': hover }">
              <v-spacer></v-spacer>
              <v-btn icon>
                <v-icon>mdi-heart</v-icon>
                <strong> L2P 소식 </strong>
              </v-btn>
              <h2>
                <strong> L2P가 Askme를 런칭합니다:) </strong>
              </h2>
              <h5 class="ma-1 pa-1">
                #askme #나만의 게시판 #익명질문 #답글달기
              </h5>
              <div>
                <v-img :src="items[4].src"> </v-img>
                <p>
                  <font size="2em">
                    친구에게 익명으로 질문을 남겨보세요. <br />
                    회원 가입을 하면 나만의 게시판을 만들어 질문을 받을 수
                    있어요. :)
                  </font>
                </p>
              </div>
              <!-- <v-btn
                  class="ma-2 pa-2"
                  :style="{ 'background-color': '#00ff0000' }"
                  :color="transparent"
                >
                  자세히 보기
                </v-btn>
                <v-btn
                  :class="{ 'show-btn': hover }"
                  :style="{ 'background-color': '#00ff0000' }"
                  :color="transparent"
                >
                  경험하기
                </v-btn> -->
            </v-card>
          </v-hover>
        </div>
      </v-col>
      <v-col sm="12" md="12" cols="12">
        <div>
          <InFeedAdsense
            style="display: block"
            data-ad-format="fluid"
            data-ad-layout-key="-e0+46+2h-1n-4v"
            data-ad-client="ca-pub-5746542177729335"
            data-ad-slot="4278281776"
          >
          </InFeedAdsense>
        </div>
      </v-col>
      <v-col sm="12" md="12" cols="12">
        <div class="ma-3" align="start">
          <font size="5em" color="black"
            ><v-icon>mdi-more</v-icon> <Strong>Conpurple's Promotion</Strong>
          </font>
        </div>
        <div class="box" style="overflow: scroll; height: 400px">
          <v-hover class="ma-3 pa-3" v-slot="{ hover }">
            <v-card :elevation="hover ? 12 : 2" :class="{ 'on-hover': hover }">
              <v-spacer></v-spacer>
              <v-btn icon>
                <v-icon>mdi-youtube</v-icon>
                <strong> Qdroid </strong>
              </v-btn>
              <h2>
                <strong> 홍보 영상 </strong>
              </h2>
              <h5 class="ma-1 pa-1">#로쮸티비 #Qdroid Guide</h5>
              <iframe
                width="300"
                height="315"
                src="https://www.youtube.com/embed/3T1DpL_7XnQ"
              >
              </iframe>
            </v-card>
          </v-hover>
        </div>
      </v-col>
    </v-row>
  </div>
</template>
<script
  async
  src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-5746542177729335"
  crossorigin="anonymous"
></script>
<script>
import axios from "axios";
export default {
  data() {
    return {
      cnt_today: 0,
      cnt_total: 0,
      cnt_history: [],
      cnt_board_total: 0,
      cnt_board_today: [],
      labels: [],
      label_value: true,
      transparent: "rgba(255, 255, 255, 0)",

      items: [
        {
          src: require("../assets/alldroid_logo.png"),
        },

        {
          src: require("../assets/qdroid.png"),
        },
        {
          src: require("../assets/mbting_logo.jpeg"),
        },
        {
          src: require("../assets/dotdroid.png"),
        },
        {
          src: require("../assets/askme_sample.png"),
        },
        {
          src: require("../assets/wonderpod.png"),
        },
      ],
      // cards : [
      //   {title: "qdroid",
      //   card_title: "2019. 4. 12.",
      //   card_text:
      //   "QCY 관련 이어폰의 기능들을 확장 시킬 수 있는 유틸리티" ,
      //   icon: require('../assets/qdroid_logo.png'),  flex:3 },
      //   {title: "dotdroid", icon: require('../assets/dotdroid_logo.png'),  flex:3 },
      //   {title: "alldroid", icon: require('../assets/alldroid_logo.png'),  flex:3 },
      //   {title: "mbting", icon: require('../assets/mbting_logo.jpeg'),  flex:3 },
      //   {title: "let's lotto",icon: require('../assets/lotto_logo.png'), flex: 12},
      // ],
      cards: [
        {
          publish_date: "2019. 4. 12",
          card_title: "qdroid",
          card_text: "QCY 관련 이어폰의 기능들을 확장 시킬 수 있는 유틸리티",
          icon: require("../assets/qdroid_logo.png"),
        },
        {
          publish_date: "2019. 5. 27",
          card_title: "dotdroid",
          card_text: "샤오미 관련 이어폰의 기능들을 확장 시킬 수 있는 유틸리티",
          icon: require("../assets/dotdroid_logo.png"),
        },
        {
          publish_date: "2021. 9. 11",
          card_title: "alldroid",
          card_text: "모든 이어폰(범용)의 기능들을 확장 시킬 수 있는 유틸리티",
          icon: require("../assets/alldroid_logo.png"),
        },
        {
          publish_date: "2022. 3. 11",
          card_title: "mbting",
          card_text: "MBTI 궁합 보기 / 연예인과 MBTI 궁합 보기 / MBTI 커뮤니티",
          icon: require("../assets/mbting_logo.jpeg"),
        },
        {
          publish_date: "2022. 4. 1(Launch Plan)",
          card_title: "let's lotto",
          card_text:
            "로또 자동 추첨 / 고정 추첨 / 당첨 시뮬레이션 / 로또 커뮤니티",
          icon: require("../assets/lotto_logo.png"),
        },
      ],
    };
  },
  computed: {
    columns() {
      if (this.$vuetify.breakpoint.xl) {
        return 4;
      }
      if (this.$vuetify.breakpoint.lg) {
        return 3;
      }
      if (this.$vuetify.breakpoint.md) {
        return 2;
      }
      return 1;
    },
    getwidth() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 100;
        case "sm":
          return 200;
        case "md":
          return 280;
        case "lg":
          return 310;
        case "xl":
          return 340;
      }
      return 30;
    },
    getheight() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 100;
        case "sm":
          return 200;
        case "md":
          return 280;
        case "lg":
          return 310;
        case "xl":
          return 340;
      }
      return 30;
    },
    getsize() {
      // alert(this.$vuetify.breakpoint.name)
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 50;
        case "sm":
          return 100;
        case "md":
          return 150;
        case "lg":
          return 180;
        case "xl":
          return 210;
      }
      return 30;
    },
  },
  created: function () {
    var date = new Date();
    // alert(date)
    var str_month = date.getMonth() + 1;
    var str_day = date.getDate();
    if (str_month < 10) str_month = "0" + str_month;
    if (str_day < 10) str_day = "0" + str_day;

    var query_date = date.getFullYear() + str_month + str_day;
    var query_date_raw = date.getFullYear() + "-" + str_month + "-" + str_day;

    date.setTime(date.getTime() + 1 * 24 * 60 * 60 * 1000);

    for (var i = 0; i < 5; i++) {
      var now = new Date(); // 현재 날짜 및 시간
      var before_day = new Date(now.setDate(now.getDate() - i));
      var bday_month = before_day.getMonth() + 1;
      var bday_day = before_day.getDate();
      if (bday_month < 10) bday_month = "0" + bday_month;
      if (bday_day < 10) bday_day = "0" + bday_day;
      var complete_bday = bday_month + "/" + bday_day;
      console.log("DDDD ==== " + complete_bday);
      complete_bday += "";
      this.labels.push(complete_bday);
    }
    this.labels.reverse();

    // dat
    // for(var i = 0 ; i < 5; i++){
    //   this.cnt_history_label.push()
    // }

    axios.defaults.headers.common["Content-Type"] =
      "application/x-www-form-urlencoded";
    axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
    axios.defaults.xsrfCookieName = "csrftoken";
    axios.defaults.xsrfHeaderName = "x-CSRFToken";
    axios
      .get("/l2pboard/history/?history_date=" + query_date + "&history_new=0")
      .then((res) => {
        console.log(res.data);
        this.cnt_today = res.data.history_today;
        this.cnt_total = res.data.history_sum;
        for (var i = 0; i < res.data.history_tot.length; i++) {
          this.cnt_history.push(res.data.history_tot[i]);
        }
        // alert(JSON.stringify(this.cnt_history))
        this.cnt_history.reverse();
      });

    axios.get("/l2pboard/post/?post_date=" + query_date_raw).then((res) => {
      this.cnt_board_today = res.data.today_board;
      this.cnt_board_today.reverse();
      // alert(JSON.stringify(this.cnt_board_today))
      this.cnt_board_total = res.data.total_board;
      console.log("KEONWOO BOARD RES = " + res.data.total_board);
      console.log("KEONWOO BOARD RES = " + res.data.today_board);
    });
    // alert("created");
  },
  //name: 'Home'
};
</script>

<style>
.v-sheet--offset {
  top: -24px;
  position: relative;
}
body {
  -ms-overflow-style: none;
}
::-webkit-scrollbar {
  display: none;
} /*특정 부분 스크롤바 없애기*/
.box {
  -ms-overflow-style: none;
}
.box::-webkit-scrollbar {
  display: none;
}
</style>
