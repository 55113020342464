<template>
  <v-row>
    <v-col cols="12" sm="12" md="8" offset-md="2">
      <!-- <div class="ma-3" align="start"> -->
      <!-- <font size="5em" color="black"> -->

      <!-- </font> -->
      <!-- </div> -->
      <v-card class="mt-2 pa-2">
        <v-row class="mt-2" justify="center" v-if="this.idx == 0">
          <strong> Alldroid 게시판 글쓰기 </strong>
        </v-row>
        <v-row class="mt-2" justify="center" v-else-if="this.idx == 1">
          <strong> Dotdroid 게시판 글쓰기 </strong>
        </v-row>
        <v-row class="mt-2" justify="center" v-else-if="this.idx == 2">
          <strong> Qdroid 게시판 글쓰기 </strong>
        </v-row>
        <v-row class="mt-2" justify="center" v-else-if="this.idx == 3">
          <strong> Wonderpod 게시판 글쓰기 </strong>
        </v-row>
        <v-form
          class="mx-auto pa-4"
          ref="form"
          v-model="valid"
          lazy-validation
          align="center"
        >
          <v-text-field
            v-model="subject"
            :counter="100"
            :rules="subjectRules"
            label="제목"
            required
          ></v-text-field>

          <v-text-field
            v-model="name"
            :counter="10"
            :rules="nameRules"
            label="이름"
            required
          ></v-text-field>
          <v-textarea
            name="content"
            :counter="500"
            :rules="contentRules"
            label="내용을 입력하세요"
            value=""
            v-model="content"
          ></v-textarea>
          <v-text-field
            v-model="password"
            :counter="20"
            :rules="passwordRules"
            type="password"
            label="비밀번호"
            required
            prepend-inner-icon="mdi-lock"
          >
          </v-text-field>
          <v-btn color="white" class="mr-4" @click="back">
            <font color="black"> 뒤로 가기 </font>
          </v-btn>
          <v-btn color="white" class="mr-4" @click="reset">
            <font color="black"> 초기화 </font>
          </v-btn>
          <v-btn :disabled="!valid" color="white" class="mr-4" @click="post">
            <font color="black"> 등록하기 </font>
          </v-btn>
        </v-form>
      </v-card>
    </v-col>
  </v-row>
</template>
<!-- <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-5746542177729335"
     crossorigin="anonymous"></script> -->
<script>
import axios from "axios";

export default {
  data: () => ({
    idx: 0,
    valid: true,
    name: "",
    subject: "",
    subjectRules: [
      (v) => !!v || "제목은 필수 입력사항 입니다.",
      (v) => !(v && v.length >= 100) || "제목은 100자 이상 입력할 수 없습니다.",
    ],
    nameRules: [
      (v) => !!v || "사용자 이름은 필수 입력사항 입니다.",
      (v) =>
        !(v && v.length >= 10) || "사용자 이름은 10자 이상 입력할 수 없습니다.",
    ],
    contentRules: [
      (v) => !!v || "내용은 필수 입력사항 입니다.",
      (v) => !(v && v.length >= 500) || "내용은 500자 이상 입력할 수 없습니다.",
      // v => !/[~!@#$%^&*()_+|<>?:{}]/.test(v) || '제목에는 특수문자를 사용할 수 없습니다.'
    ],
    email: "",
    emailRules: [
      (v) => !!v || "E-mail is required",
      (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
    ],
    password: "",
    passwordRules: [
      (v) => !!v || "패스워드 형식이 올바르지 않습니다.",
      (v) => !(v && v.length >= 20) || "패스워드 형식이 올바르지 않습니다.",
    ],
    select: null,
    content: null,
    items: ["어플리케이션 문의", "L2P 프로젝트 문의 "],
  }),

  methods: {
    back() {
      this.$router.go(-1);
    },
    reset() {
      this.$refs.form.reset();
    },
    validate: function () {
      if (this.subject == null || !this.subject.length) {
        return -1;
      }
      if (this.name == null || !this.name.length) {
        return -1;
      }
      if (this.content == null || !this.content.length) {
        return -1;
      }
      if (this.password == null || !this.password.length) {
        return -1;
      }
      return 0;
    },
    post: function () {
      var ret = this.validate();
      if (ret == -1) {
        alert("빈칸을 모두 채워주세요!");
        return;
      }

      var dt = new Date();
      var str =
        dt.getFullYear() + "-" + (dt.getMonth() + 1) + "-" + dt.getDate();
      str +=
        "T" +
        dt.getHours() +
        ":" +
        dt.getMinutes() +
        ":" +
        dt.getSeconds() +
        "Z";
      // alert("date = " + str);

      var post_dat = {
        content: this.content,
        name: this.name,
        subject: this.subject,
        create_date: str,
        password: this.password,
      };

      var query = "";
      switch (this.$route.params.idx) {
        case 0:
          //alldroid
          query = "/l2pboard/alldroid_questions/";
          break;
        case 1:
          //dotdroid
          query = "/l2pboard/dotdroid_questions/";
          break;
        case 2:
          //qdroid
          query = "/l2pboard/qdroid_questions/";
          break;
        case 3:
          //wonderpod
          query = "/l2pboard/wonderpod_questions/";
          break;
      }

      axios({
        method: "POST",
        url: query,
        data: post_dat,
      })
        .then(() => {
          alert("게시글 등록 완료!");
          this.$router.go(-1);
        })
        .catch((error) => {
          alert("게시글 등록 실패! 관리자에게 문의 하세요.");
          console.log("Axios get error: ", error.reponse);
        });
    },
  },
  created: function () {
    this.$vuetify.goTo(0);
    this.idx = this.$route.params.idx;
    // alert(this.idx);
  },
};
</script>
