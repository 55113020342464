<!--
0. 최신 로또 번호 
 ex : 925회차 1 ,2,2,3,3, 

1. 내 당첨 이력(기록 기반)
 내가 기록해 두었던 로또 번호가 당첨되었는지 확인
 
2. 모든 유저 당첨 현황(기록 기반) - 이건 서버에 저장해야 될듯? 

4. 오른쪽에 실시간 채팅 놓으면 재밌을 듯 ? 



    일단 api를 사용해서 최신 당첨 정보를 확인해야 됨
        - 1주일 단위로 계산해서 보여줌



    - 내가 세팅한 번호로 역대 로또가 얼만큼 당첨되는지에 대한 확률 계산(10회 단위) 

-->
<template>
  <v-app>
    <v-container>
      <!-- <Adsense
        data-ad-client="ca-pub-5746542177729335"
        data-ad-slot="3800733658"
        data-ad-format="auto"
        data-full-width-responsive="true"
      >
      </Adsense> -->
      <v-row style="height: 500px">
        <v-col cols="12" sm="12" md="12">
          <v-card class="mx-auto pa-10 ma-10" max-width="600px" outlined>
            <div>
              <p>
                나만의 로또와 당첨번호를 비교해서<br />
                <strong>당첨여부</strong>를 알려드려요!
              </p>
              <p>
                <strong>{{ lotto_round[0][0] }} 회차 번호 </strong>({{ date }})
              </p>
              <v-row>
                <v-col cols="2">
                  <div
                    v-bind:style="{
                      backgroundColor: computedBgColor(lotto_num[0][0]),
                    }"
                    class="ball"
                  >
                    {{ lotto_num[0][0] }}
                  </div>
                </v-col>
                <v-col cols="2">
                  <div
                    v-bind:style="{
                      backgroundColor: computedBgColor(lotto_num[0][1]),
                    }"
                    class="ball"
                  >
                    {{ lotto_num[0][1] }}
                  </div>
                </v-col>
                <v-col cols="2">
                  <div
                    v-bind:style="{
                      backgroundColor: computedBgColor(lotto_num[0][2]),
                    }"
                    class="ball"
                  >
                    {{ lotto_num[0][2] }}
                  </div>
                </v-col>
                <v-col cols="2">
                  <div
                    v-bind:style="{
                      backgroundColor: computedBgColor(lotto_num[0][3]),
                    }"
                    class="ball"
                  >
                    {{ lotto_num[0][3] }}
                  </div>
                </v-col>
                <v-col cols="2">
                  <div
                    v-bind:style="{
                      backgroundColor: computedBgColor(lotto_num[0][4]),
                    }"
                    class="ball"
                  >
                    {{ lotto_num[0][4] }}
                  </div>
                </v-col>
                +
                <v-col cols="1">
                  <div
                    v-bind:style="{
                      backgroundColor: computedBgColor(lotto_num[0][4]),
                    }"
                    class="ball"
                  >
                    {{ lotto_num[0][5] }}
                  </div>
                </v-col>
              </v-row>
            </div>
          </v-card>
        </v-col>
        <v-col cols="12" sm="12" md="12">
          <v-card class="mx-auto pa-10 ma-10" max-width="600px" outlined>
            <p><strong> 나의 로또 번호 </strong></p>
            <v-row>
              <v-col
                class="mx-auto pa-2"
                cols="12"
                sm="12"
                md="12"
                v-for="(item, index) in my_lotto_num"
                v-bind:key="item.id"
              >
                <strong>{{ computedPrize(index) }} :</strong>
                <div
                  v-bind:style="{ backgroundColor: computedBgColor(item[0]) }"
                  class="ball"
                >
                  {{ item[0] }}
                </div>
                <div
                  v-bind:style="{ backgroundColor: computedBgColor(item[1]) }"
                  class="ball"
                >
                  {{ item[1] }}
                </div>
                <div
                  v-bind:style="{ backgroundColor: computedBgColor(item[2]) }"
                  class="ball"
                >
                  {{ item[2] }}
                </div>
                <div
                  v-bind:style="{ backgroundColor: computedBgColor(item[3]) }"
                  class="ball"
                >
                  {{ item[3] }}
                </div>
                <div
                  v-bind:style="{ backgroundColor: computedBgColor(item[4]) }"
                  class="ball"
                >
                  {{ item[4] }}
                </div>
                <div
                  v-bind:style="{ backgroundColor: computedBgColor(item[5]) }"
                  class="ball"
                >
                  {{ item[5] }}
                </div>
                <v-divider></v-divider>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-app>
</template>

<!-- <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-5746542177729335"
     crossorigin="anonymous"></script> -->
<script>
import axios from "axios";

export default {
  data() {
    return {
      lotto_num: [],
      lotto_bonus_num: [],
      lotto_round: [],
      date: "",
      my_lotto_num: [],
    };
  },
  methods: {
    computedBgColor: function (num) {
      var color = "#ffffff";
      // console.log("num = " + num);
      if (num < 10) {
        // this.balls_num.
        color = "#ffd400";
      } else if (num < 20) {
        color = "#00d4ff";
      } else if (num < 30) {
        color = "#FF5a5a";
      } else if (num < 40) {
        color = "#808080";
      } else {
        color = "#92DF45";
      }
      return color;
    },
    computedPrize: function (idx) {
      console.log("DD: " + idx);
      /* idx 번째의 localStorage의 로또 번호를 구하고,
            그 번호가 매칭되는 걸 확인한다 */
      var lotto_num_idx = this.my_lotto_num[idx];
      var is_num_match = [0, 0, 0, 0, 0, 0];
      var is_bonus_match = 0;
      var cnt = 0;
      for (var i = 0; i < lotto_num_idx.length; i++) {
        /* idx의 숫자를 탐색 */
        console.log(" total nu = " + lotto_num_idx[i]);

        // var ret = this.lotto_num[0].includes(lotto_num_idx[i],0);
        // console.log(this.lotto_num + " includes : " + lotto_num_idx[i] + " : " + ret);
        for (var j = 0; j < this.lotto_num[0].length; j++) {
          var target_num = this.lotto_num[0][j];
          console.log(" bb = " + target_num);
          if (lotto_num_idx[i] == target_num) {
            is_num_match[i] = 1;
            console.log("match");
          }
          if (lotto_num_idx[i] == this.lotto_bonus_num[0][0]) {
            is_bonus_match = 1;
          }
        }
        /* TODO : bodnus checking */
        // for()
      }
      for (var k = 0; k < 6; k++) {
        if (is_num_match[k] == 1) cnt++;
      }
      if (cnt == 6) {
        return "1등";
      } else if (cnt == 5 && is_bonus_match) {
        return "2등";
      } else if (cnt == 5 && !is_bonus_match) {
        return "3등";
      } else if (cnt == 4) {
        return "4등";
      } else if (cnt == 3) {
        return "5등";
      } else {
        return "낙첨";
      }
    },
  },
  beforeMount: function () {
    /*  일단은 기준 날짜를 구하자 */
    // var base_var = "2020-02-29";
    // var base_arr = base_var.split('-');
    const base_date = new Date(2020, 1, 29);
    var base_round = 900;
    const cur_date = new Date();

    var year = cur_date.getFullYear();
    var month = cur_date.getMonth();
    var day = cur_date.getDay();
    this.date = year + "년 " + month + "월 " + day + "일";

    // console.log("기준 날짜 : " + base_date);
    // console.log("현재 날짜 : " + cur_date) ;
    var diff = cur_date - base_date;
    var diff_day = 24 * 60 * 60 * 1000;
    // var diff_month = diff_day * 30 ;
    // var diff_year = diff_month * 12;

    var remain_day = parseInt(diff / diff_day);
    console.log("일 : " + parseInt(diff / diff_day));

    var cnt = remain_day / 7;
    console.log("cnt = " + cnt);
    var cur_round = parseInt(base_round + cnt);
    console.log("cur round = " + cur_round);

    var temp = [];
    var temp_bn = [];
    var temp_round = [];

    if (localStorage.getItem(cur_round)) {
      console.log("DONT NEED TO QUERY");
      var num_cache = localStorage.getItem(cur_round);
      num_cache = num_cache.split(",");
      for (i = 0; i < num_cache.length; i++) {
        console.log("num = " + num_cache[i]);
        if (i <= num_cache.length - 2) {
          temp.push(num_cache[i]);
        } else {
          temp_bn.push(num_cache[i]);
        }
        temp_round.push(cur_round);
      }
    } else {
      axios.defaults.headers.common["Content-Type"] =
        "application/x-www-form-urlencoded";
      axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
      var query_str =
        "/dhlottery/common.do?method=getLottoNumber&drwNo=" + cur_round;
      axios.get(query_str).then(function (response) {
        console.log(response);
        temp.push(response.data.drwtNo1);
        temp.push(response.data.drwtNo2);
        temp.push(response.data.drwtNo3);
        temp.push(response.data.drwtNo4);
        temp.push(response.data.drwtNo5);
        temp.push(response.data.drwtNo6);
        temp_bn.push(response.data.bnusNo);
        temp_round.push(response.data.drwNo);

        var num_cache = [];
        num_cache.push(temp);
        num_cache.push(temp_bn);
        localStorage.setItem(response.data.drwNo, num_cache);
        console.log("SAVE COMPLETE!");
        // console.log(temp_round);
        // alert(temp_round);
      });
    }
    console.log("hey = " + temp);
    this.lotto_num.push(temp);
    this.lotto_bonus_num.push(temp_bn);
    this.lotto_round.push(temp_round);
    // alert(this.lotto_round);
    // this.a = 10;
    /* component 가 로딩되고 렌더링 되기 전에 최신 로또 번호를 가져와야 된다 */

    for (var i = 0; i < 5; i++) {
      var lotto_num = localStorage.getItem(i);
      console.log(lotto_num);
      //   alert(lotto_num)
      if (lotto_num) {
        var split_lotto_num = lotto_num.split(",");
        console.log(split_lotto_num);
        this.my_lotto_num.push(split_lotto_num);
      }
    }
  },
};
</script>
<style scoped>
.ball {
  background-color: #ffd400;
  width: 30px;
  height: 30px;
  margin-left: 2px;
  font-weight: bold;
  line-height: 30px;
  border-radius: 50%;
  color: white;
  display: inline-block;
}
.bonus_ball {
  background-color: #ff22ff;
  width: 30px;
  height: 30px;
  margin-left: 2px;
  font-weight: bold;
  line-height: 30px;
  border-radius: 50%;
  color: white;
  display: inline-block;
}
</style>
