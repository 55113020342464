// Vue Libraries
import Vue from "vue"; // main lib
import VueCarousel from "vue-carousel"; //banner lib

// Main View
import App from "./App.vue";
import AppTwo from "./AppTwo.vue";
import AppMbting from "./AppMbting.vue";
import AppAskme from "./AppAskme.vue";

// Sub View (Routers)
import router from "./router/index";
import lotto_router from "./router/lotto_index";
import mbting_router from "./router/mbting_router";
import askme_router from "./router/askme_router";

// Stores
import store from "@/store/index.js";

// Etc,
import vuetify from "./plugins/vuetify";

// Ads
import Ads from "vue-google-adsense";
Vue.use(require('vue-script2'))
Vue.use(Ads.Adsense) //디스플레이 광고
Vue.use(Ads.InArticleAdsense) //콘텐츠 내 자동 삽입 광고
Vue.use(Ads.InFeedAdsense) //인피드 광고
Vue.config.productionTip = false;

const getapp = () => {
  const host = window.location.host;
  if (host.indexOf("lotto") >= 0) {
    return AppTwo;
  } else if (host.indexOf("mbting") >= 0) {
    return AppMbting;
  } else if (host.indexOf("askme") >= 0) {
    return AppAskme;
  } else {
    return App;
  }
};

const getrouter = () => {
  const host = window.location.host;
  if (host.indexOf("lotto") >= 0) {
    return lotto_router;
  } else if (host.indexOf("mbting") >= 0) {
    return mbting_router;
  } else if (host.indexOf("askme") >= 0) {
    return askme_router;
  } else {
    return router;
  }
};

const preload = async () => {
  new Vue({
    router: getrouter(),
    vuetify,
    VueCarousel,
    store,
    render: (h) => h(getapp()),
  }).$mount("#app");
};
preload();