import Vue from 'vue'
import VueRouter from 'vue-router'

import LottoRandom from "../components/LottoRandom"
import LottoHistory from "../components/LottoHistory"
import LottoSimulator from "../components/LottoSimulator"
import LottoMain from "../components/LottoMain"

Vue.use(VueRouter)
// export default new Router

const routes = [ 
    {
        path: "/",
        name: "/",
        component: LottoRandom,  
    },
    {
        path : "/lottomain",
        name : "/LottoMain",
        component: LottoMain
    },
    {
        path : "/lottorandom",
        name : "/LottoRandom",
        component: LottoRandom
    },
    {
        path : "/lottohistory",
        name : "/LottoHistory",
        component: LottoHistory
    }, 
    {
        path : "/lottosimulator",
        name : "/LottoSimulator",
        component : LottoSimulator
    }
]
const router = new VueRouter({
    // mode: history,
    routes,
})
export default router