import {
  loginUser,
  logoutUser,
  authVerifyToken,
} from "../../services/api/askmeService";

const askmeStore = {
  namespaced: true,
  state: {
    userinfo: {
      userid: "",
      username: "",
    },
    isLoggedIn: false,
  },
  getters: {
    isLogin(state) {
      return state.isLoggedIn;
    },
    getUserInfo(state) {
      return state.userinfo.userid;
    },
  },
  /* 동기 */
  mutations: {
    loginSuccess(state, userid, username) {
      state.userinfo.userid = userid;
      state.userinfo.username = username;
      state.isLoggedIn = true;
    },
    logoutSuccess(state) {
      state.userinfo.userid = "";
      state.userinfo.username = "";
      state.isLoggedIn = false;
    },
  },
  /* 비동기 */
  actions: {
    login({ commit }, { userid, password }) {
      // alert('userid: ' + userid + ", passwd: " + password)
      console.log('userid: ' + userid + ', passwd: ' + password)
      return loginUser(userid, password)
        .then((response) => {
          var userid = response.data.items[0].fields.userid;
          var username = response.data.items[0].fields.name;
          commit({ type: "loginSuccess", userid, username });
          return Promise.resolve();
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    },
    logout({ commit }) {
      logoutUser();
      commit("logoutSuccess");
    },
    verifyToken({ commit }) {
      return authVerifyToken()
        .then((response) => {
          var userid = response.data.items[0].fields.userid;
          var username = response.data.items[0].fields.name;
          commit({ type: "loginSuccess", userid, username });
          return Promise.resolve();
        })
        .catch((error) => {
          alert("로그인 세션이 만료 되었습니다.");
          logoutUser();
          commit("logoutSuccess");
          return Promise.reject(error);
        });
    },
  },
};

export default askmeStore;
