<template>
  <v-app>
    <header>
      <v-app-bar color="black" app height="100">
        <v-spacer></v-spacer>
        <router-link :to="{ path: '/' }">
          <v-img
            class="mx-auto"
            style="display: block; margin: 0 auto"
            :src="require('@/assets/askme_logo_update.png')"
            height="100"
            width="140"
            contain
          ></v-img>
          <!-- askme logo -->
        </router-link>
        <v-spacer></v-spacer>
        <v-dialog
          v-if="!isLogin"
          v-model="accountDialog"
          persistent
          max-width="300px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              v-bind="attrs"
              v-on="on"
              color="white darken-3"
              style="cursor: pointer"
              class="mr-2"
              >mdi-account</v-icon
            >
          </template>
          <v-card>
            <v-card-title>
              <span class="text-h6">계정 관리</span>
              <v-spacer></v-spacer>
              <v-btn icon @click="closeAccountForm()">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-card-title>
            <v-card-text>
              <v-row justify="center">
                <v-col>
                  <v-text-field
                    v-model="loginForm.userid"
                    :rules="useridrules"
                    label="아이디"
                    outlined
                    required
                    autofocus
                    prepend-inner-icon="mdi-account"
                    v-on:keyup.enter="loginFormTimeoutDialog = true"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-text-field
                    v-model="loginForm.password"
                    :counter="20"
                    :rules="passwordrules"
                    type="password"
                    label="비밀번호"
                    outlined
                    required
                    prepend-inner-icon="mdi-lock"
                    v-on:keyup.enter="loginFormTimeoutDialog = true"
                  >
                  </v-text-field>
                </v-col>
              </v-row>
              <v-row justify="center" align="center">
                <v-btn
                  outlined
                  rounded
                  text
                  v-on:click="loginFormTimeoutDialog = true"
                  :disabled="loginFormTimeoutDialog"
                  :loading="loginFormTimeoutDialog"
                  >로그인</v-btn
                >

                <v-btn
                  outlined
                  rounded
                  text
                  class="ml-2"
                  @click="openRegisterForm()"
                  >회원 가입</v-btn
                >
              </v-row>
              <v-row justify="center">
                <v-btn
                  outlined
                  rounded
                  text
                  class="ml-2 mt-2"
                  @click="openFindIDAccountForm()"
                  >아이디 찾기</v-btn
                >
              </v-row>
              <v-row justify="center">
                <v-btn
                  outlined
                  rounded
                  text
                  class="ml-2 mt-2"
                  @click="openFindPasswordAccountForm()"
                  >비밀번호 찾기</v-btn
                >
              </v-row>
              <!-- <v-divider></v-divider> -->
              <!-- <v-row justify="center">
                <v-btn
                  outlined
                  rounded
                  text
                  class="yellow lighten-1 mt-2"
                  @click="kakaoLogin"
                  >카카오톡 로그인</v-btn
                >
              </v-row>
              <v-row justify="center">
                <v-btn
                  rounded
                  text
                  class="green lighten-1 mt-2"
                  @click="googleLogin"
                  >구글 로그인</v-btn
                >
              </v-row> -->
            </v-card-text>
          </v-card>
        </v-dialog>
        <v-dialog v-model="registerDialog" persistent max-width="400px">
          <v-card>
            <v-card-title>
              <span class="text-h6">회원 가입</span>
              <v-spacer></v-spacer>
              <v-btn icon @click="closeRegisterForm()">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-card-title>
            <v-card-text>
              <v-row justify="center">
                <v-col cols="8">
                  <v-text-field
                    ref="registerFormEmail"
                    v-model="registerFormUserid"
                    :rules="useridrules"
                    label="아이디"
                    autofocus
                    outlined
                    required
                    prepend-inner-icon="mdi-account"
                  ></v-text-field>
                  <!-- <pre v-if="$refs.registerFormEmail">
                    hasError: {{ $refs.registerFormEmail.hasError }}
                    valid: {{ $refs.registerFormEmail.valid }}
                  </pre> -->
                </v-col>
                <v-col cols="4">
                  <v-btn
                    v-if="registerFormUserIdValidCheck == 0"
                    outlined
                    rounded
                    text
                    class="mt-3 red--text"
                    @click="registerUserIdCheck()"
                    >중복 체크
                  </v-btn>
                  <v-btn
                    v-else
                    outlined
                    rounded
                    text
                    class="mt-3 green--text"
                    @click="registerUserIdCheck()"
                    >중복 체크
                  </v-btn>
                </v-col>
              </v-row>
              <v-row justify="center">
                <v-col>
                  <v-text-field
                    v-model="registerForm.name"
                    label="이름"
                    :counter="10"
                    :rules="namerules"
                    outlined
                    required
                    prepend-inner-icon="mdi-account-outline"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-text-field
                    v-model="registerFormPassword1"
                    :counter="20"
                    :rules="passwordrules"
                    type="password"
                    label="비밀번호"
                    outlined
                    required
                    prepend-inner-icon="mdi-lock"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-text-field
                    v-model="registerFormPassword2"
                    :counter="20"
                    :rules="passwordrules"
                    type="password"
                    label="비밀번호 재입력"
                    outlined
                    required
                    prepend-inner-icon="mdi-lock"
                  ></v-text-field>
                  <v-card-text style="color: red">{{
                    this.registerFormPasswordInfo
                  }}</v-card-text>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-select
                    v-model="registerForm.sex"
                    :items="sexItems"
                    :rules="sexrules"
                    item-text="name"
                    item-value="value"
                    label="성별"
                    outlined
                    prepend-inner-icon="mdi-human-male-female"
                  ></v-select>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-menu
                    ref="menu"
                    v-model="menu"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        :rules="daterules"
                        v-model="registerForm.date_of_birth"
                        label="생일"
                        prepend-inner-icon="mdi-calendar"
                        readonly
                        outlined
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="registerForm.date_of_birth"
                      :active-picker.sync="activePicker"
                      :max="
                        new Date(
                          Date.now() - new Date().getTimezoneOffset() * 60000
                        )
                          .toISOString()
                          .substr(0, 10)
                      "
                      min="1950-01-01"
                      @change="save"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-btn
                    outlined
                    rounded
                    text
                    @click="registerFormTimeoutDialog = true"
                    :disabled="registerFormTimeoutDialog"
                    :loading="registerFormTimeoutDialog"
                  >
                    <!-- <v-icon>mdi-pencil</v-icon> -->
                    등록
                  </v-btn>

                  <v-btn outlined rounded text @click="closeRegisterForm()">
                    <!-- <v-icon>mdi-trash-can</v-icon> -->
                    취소
                  </v-btn>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-dialog>
        <v-menu v-if="isLogin" bottom min-width="200px" rounded offset-y>
          <template v-slot:activator="{ on }">
            <v-btn class="mx-auto" icon x-large v-on="on">
              <v-icon v-on="on" color="white darken-3" style="cursor: pointer"
                >mdi-account-edit</v-icon
              >
            </v-btn>
          </template>
          <v-card>
            <v-list-item-content class="justify-center">
              <div class="mx-auto text-center">
                <!-- <v-avatar color="brown"> -->
                <h3>{{ getUserInfo.userid }}({{ getUserInfo.username }})</h3>
                <!-- </v-avatar> -->
                <v-divider class="my-3"></v-divider>
                <v-btn
                  depressed
                  rounded
                  text
                  @click="updateAccountDialog = true"
                >
                  정보 변경
                </v-btn>
                <v-divider class="my-3"></v-divider>
                <v-btn
                  depressed
                  rounded
                  text
                  @click="updatePasswordDialog = true"
                >
                  비밀번호 변경
                </v-btn>
                <v-divider class="my-3"></v-divider>
                <v-btn depressed rounded text v-on:click="normalLogout($event)">
                  로그아웃
                </v-btn>
              </div>
            </v-list-item-content>
          </v-card>
        </v-menu>
        <v-dialog v-model="updatePasswordDialog" persistent max-width="300px">
          <v-card>
            <v-card-title>
              <span class="text-h6">비밀번호 변경</span>
              <v-spacer></v-spacer>
              <v-btn icon @click="updatePasswordDialog = false">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-card-title>
            <v-card-text>
              <v-row>
                <v-col>
                  <v-text-field
                    v-model="changePasswordCur"
                    :counter="20"
                    :rules="passwordrules"
                    type="password"
                    label="현재 비밀번호"
                    outlined
                    required
                    prepend-inner-icon="mdi-lock"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-text-field
                    v-model="changePassword"
                    :counter="20"
                    :rules="passwordrules"
                    type="password"
                    label="비밀번호"
                    outlined
                    required
                    prepend-inner-icon="mdi-lock"
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row>
                <v-col>
                  <v-text-field
                    v-model="changePassword2"
                    :counter="20"
                    :rules="passwordrules"
                    type="password"
                    label="비밀번호 재입력"
                    outlined
                    required
                    prepend-inner-icon="mdi-lock"
                  ></v-text-field>
                  <v-card-text style="color: red">{{
                    this.changePasswordInfo
                  }}</v-card-text>
                </v-col>
              </v-row>

              <v-row>
                <v-col>
                  <v-btn
                    outlined
                    rounded
                    text
                    @click="updatePasswordTimeoutDialog = true"
                    :disabled="updatePasswordTimeoutDialog"
                    :loading="updatePasswordTimeoutDialog"
                  >
                    수정
                  </v-btn>
                  <v-btn
                    outlined
                    rounded
                    text
                    @click="updatePasswordDialog = false"
                  >
                    취소
                  </v-btn>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-dialog>
        <!-- 정보 수정 시작 -->
        <v-dialog v-model="updateAccountDialog" persistent max-width="300px">
          <v-card>
            <v-card-title>
              <span class="text-h6">회원정보 수정</span>
              <v-spacer></v-spacer>
              <v-btn icon @click="updateAccountDialog = false">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-card-title>
            <v-card-text>
              <v-row justify="center">
                <v-col>
                  <v-text-field
                    v-model="updateAccountInfo.name"
                    :counter="10"
                    label="이름"
                    outlined
                    required
                    prepend-inner-icon="mdi-account-outline"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-select
                    v-model="updateAccountInfo.sex"
                    :items="sexItems"
                    item-text="name"
                    item-value="value"
                    label="성별"
                    outlined
                    prepend-inner-icon="mdi-human-male-female"
                  ></v-select>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-btn
                    outlined
                    rounded
                    text
                    @click="updateAccountTimeoutDialog = true"
                    :disabled="updateAccountTimeoutDialog"
                    :loading="updateAccountTimeoutDialog"
                  >
                    수정
                  </v-btn>
                  <v-btn
                    outlined
                    rounded
                    text
                    @click="updateAccountDialog = false"
                  >
                    취소
                  </v-btn>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-dialog>
        <!-- 계정 찾기 -->
        <v-dialog v-model="findIDAccountDialog" persistent max-width="400px">
          <v-card>
            <v-card-title>
              <span class="text-h6">ID 찾기</span>
              <v-spacer></v-spacer>
              <v-btn icon @click="closeFindIDAccountForm()">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-card-title>
            <v-card-text>
              <v-row justify="center">
                <v-col>
                  <v-text-field
                    v-model="findPutNameForId"
                    label="이름"
                    outlined
                    required
                    prepend-inner-icon="mdi-account-outline"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-menu
                    ref="findmenu"
                    v-model="findmenu"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        :rules="daterules"
                        v-model="findPutBirth"
                        label="생일"
                        prepend-inner-icon="mdi-calendar"
                        readonly
                        outlined
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="findPutBirth"
                      :active-picker.sync="findActivePicker"
                      :max="
                        new Date(
                          Date.now() - new Date().getTimezoneOffset() * 60000
                        )
                          .toISOString()
                          .substr(0, 10)
                      "
                      min="1950-01-01"
                      @change="findsave"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
              </v-row>

              <v-row>
                <v-col>
                  <v-btn
                    outlined
                    rounded
                    text
                    v-on:click="findIDAccountTimeoutDialog = true"
                    :disabled="findIDAccountTimeoutDialog"
                    :loading="findIDAccountTimeoutDialog"
                  >
                    찾기
                  </v-btn>
                </v-col>
              </v-row>
              <v-row justify="center">
                <v-col>
                  <v-simple-table
                    fixed-header
                    height="150px"
                    v-if="findGetIdList.length != 0"
                  >
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th class="text-center">ID</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="item in findGetIdList" :key="item.id">
                          <td>
                            <strong>{{ item.userid }}</strong>
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-dialog>
        <v-dialog
          v-model="findPasswordAccountDialog"
          persistent
          max-width="400px"
        >
          <v-card>
            <v-card-title>
              <span class="text-h6">패스워드 찾기</span>
              <v-spacer></v-spacer>
              <v-btn icon @click="closeFindPasswordAccountForm()">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-card-title>
            <v-card-text>
              <v-row justify="center">
                <v-col>
                  <v-text-field
                    v-model="findPutId"
                    :counter="10"
                    :rules="useridrules"
                    label="ID"
                    outlined
                    required
                    prepend-inner-icon="mdi-account-outline"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row justify="center">
                <v-col>
                  <v-text-field
                    v-model="findPutNameForPasswd"
                    label="이름"
                    outlined
                    required
                    prepend-inner-icon="mdi-account-outline"
                    v-on:keyup.enter="findPasswordAccountTimeoutDialog = true"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-btn
                    outlined
                    rounded
                    text
                    v-on:click="findPasswordAccountTimeoutDialog = true"
                    :disabled="findPasswordAccountTimeoutDialog"
                    :loading="findPasswordAccountTimeoutDialog"
                  >
                    찾기
                  </v-btn>
                </v-col>
              </v-row>

              <v-row justify="center">
                <v-col>
                  <v-simple-table
                    fixed-header
                    height="100px"
                    v-if="findGetPasswordList.length != 0"
                  >
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th class="text-center">Password</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="item in findGetPasswordList" :key="item.id">
                          <td>
                            <strong>{{ item.cur_password }}</strong>
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-dialog>
      </v-app-bar>
    </header>
    <v-main>
      <v-card height="100%">
        <askme-content></askme-content>
      </v-card>
    </v-main>
    <v-divider></v-divider>
    <v-footer padless>
      <v-col class="white text-center" cols="12">
        <v-card flat tile width="100%" class="text-center">
          <v-card-text>
            <v-btn
              class="mx-4"
              icon
              :href="`https://conpurple.com:443`"
              target="_blank"
            >
              <v-icon>mdi-home</v-icon>
            </v-btn>
          </v-card-text>

          <!-- <v-divider></v-divider> -->
          <strong
            >Copyright(c) {{ new Date().getFullYear() }}. l2pcompany All rights
            reserved.</strong
          >
        </v-card>
      </v-col>
    </v-footer>
  </v-app>
</template>
<!-- <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-5746542177729335"
     crossorigin="anonymous"></script> -->
<script>
import AskmeContent from "./components/AskmeContent.vue";
import axios from "axios";
import { mapGetters, mapActions } from "vuex";
let updateurl = "api/update/";
let registerurl = "api/askmeregister/";
let passwordchangeurl = "api/change-password/";
let findidurl = "api/askmefindid/";
let findpasswdurl = "api/askmefindpasswd/";

export default {
  name: "AppAskme",
  components: {
    "askme-content": AskmeContent,
  },
  data: () => ({
    accountDialog: false,
    registerDialog: false,
    updateAccountDialog: false,
    updatePasswordDialog: false,
    loginFormTimeoutDialog: false,
    registerFormTimeoutDialog: false,
    updateAccountTimeoutDialog: false,
    updatePasswordTimeoutDialog: false,
    findIDAccountDialog: false,
    findIDAccountTimeoutDialog: false,
    findPasswordAccountDialog: false,
    findPasswordAccountTimeoutDialog: false,
    activePicker: null,
    findActivePicker: null,
    menu: false,
    findmenu: false,
    loginForm: {
      // email: "",
      userid: "",
      password: "",
    },
    registerFormPassword1: "",
    registerFormPassword2: "",
    registerFormPasswordInfo: "",
    registerFormUserIdValidCheck: 0,
    registerFormUserid: "",
    registerFormUserIdSet: "",
    registerForm: {
      // email: "",
      userid: "",
      name: "",
      password: "",
      password2: "",
      sex: "",
      date_of_birth: "",
      createdate: "",
    },
    updateAccountInfo: {
      userid: "",
      name: "",
      sex: 0,
    },
    changePasswordType: 0,
    changePasswordInfo: "",
    changePasswordCur: "",
    changePassword: "",
    changePassword2: "",
    passwordChangeData: {
      current_password: "",
      new_password: "",
    },
    findPutNameForId: "",
    findPutNameForPasswd: "",
    findPutId: "",
    findGetIdList: [],
    findGetPasswordList: [],
    findPutBirth: "",
    sexItems: [
      { name: "남", value: 1 },
      { name: "여", value: 2 },
    ],
    useridrules: [
      (v) => !!v || "아이디는 필수 입력사항 입니다.",
      (v) => !(v && v.length >= 20) || "아이디는 20자 이상 입력할 수 없습니다.",
      (v) =>
        !/[~!@#$%^&*()_+|<>?:{}]/.test(v) ||
        "아이디에는 특수문자를 사용할 수 없습니다.",
    ],
    emailrules: [
      (v) =>
        /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
        "이메일 형식이 올바르지 않습니다.",
    ],
    passwordrules: [
      (v) => !!v || "패스워드 형식이 올바르지 않습니다.",
      (v) => !(v && v.length >= 20) || "패스워드 형식이 올바르지 않습니다.",
    ],
    namerules: [
      (v) => !!v || "이름은 필수 입력사항 입니다.",
      (v) => !(v && v.length >= 10) || "아이디는 10자 이상 입력할 수 없습니다.",
      (v) =>
        !/[a-zA-Z0-9~!@#$%^&*()_+|<>?:{}]/.test(v) ||
        "이름에는 한글만 사용할 수 있습니다.",
    ],
    sexrules: [(v) => !!v || "성별은 필수 입력사항 입니다."],
    daterules: [(v) => !!v || "생일은 필수 입력사항 입니다."],
  }),
  mounted() {},
  computed: {
    ...mapGetters("askmeStore", ["isLogin", "getUserInfo"]),
  },
  watch: {
    findIDAccountTimeoutDialog(val) {
      if (!val) return;

      setTimeout(() => this.findIdForm(), 1000);
    },
    findPasswordAccountTimeoutDialog(val) {
      if (!val) return;

      setTimeout(() => this.findPasswordForm(), 1000);
    },
    loginFormTimeoutDialog(val) {
      if (!val) return;

      setTimeout(() => this.normalLogin(), 1000);
    },
    registerFormTimeoutDialog(val) {
      if (!val) return;

      setTimeout(() => this.normalRegister(), 1000);
    },
    registerFormPassword1(val) {
      if (val != this.registerFormPassword2) {
        this.registerFormPasswordInfo = "패스워드가 일치하지 않습니다.";
      } else {
        this.registerFormPasswordInfo = "";
      }
    },
    registerFormPassword2(val) {
      if (val != this.registerFormPassword1) {
        this.registerFormPasswordInfo = "패스워드가 일치하지 않습니다.";
      } else {
        this.registerFormPasswordInfo = "";
      }
    },
    registerFormUserid: function () {
      if (this.registerFormUserid != this.registerFormUserIdSet) {
        this.registerFormUserIdValidCheck = 0;
      }
    },
    updateAccountDialog(val) {
      if (!val) return;

      this.getAccountInfo();
    },
    updateAccountTimeoutDialog(val) {
      if (!val) return;

      setTimeout(
        () => (this.updateForm(), (this.updateAccountTimeoutDialog = false)),
        1000
      );
    },
    updatePasswordTimeoutDialog(val) {
      if (!val) return;

      setTimeout(
        () => (
          this.changePasswordForm(), (this.updatePasswordTimeoutDialog = false)
        ),
        1000
      );
    },
    changePassword: function () {
      if (this.changePassword != this.changePassword2) {
        this.changePasswordInfo = "패스워드가 일치하지 않습니다.";
        this.changePasswordType = 0;
      } else {
        this.changePasswordInfo = "";
        this.changePasswordType = 1;
      }
    },
    changePassword2: function () {
      if (this.changePassword != this.changePassword2) {
        this.changePasswordInfo = "패스워드가 일치하지 않습니다.";
        this.changePasswordType = 0;
      } else {
        this.changePasswordInfo = "";
        this.changePasswordType = 1;
      }
    },
  },
  methods: {
    closeAccountForm: function () {
      this.accountDialog = false;
      // this.loginForm.email = "";
      this.loginForm.userid = "";
      this.loginForm.password = "";
    },
    closeRegisterForm: function () {
      this.registerDialog = false;
      this.registerFormUserIdSet = "";
      this.registerFormUserid = "";
      this.registerForm.userid = "";
      this.registerForm.name = "";
      this.registerForm.password = "";
      this.registerForm.password2 = "";
      this.registerForm.sex = "";
      this.registerForm.date_of_birth = "";
      this.registerFormPassword1 = "";
      this.registerFormPassword2 = "";
      this.registerFormPasswordInfo = "";
      this.registerFormUserIdValidCheck = 0;
    },
    normalLogin: function () {
      if (
        this.passwordrules[0](this.loginForm.password) != true ||
        this.passwordrules[1](this.loginForm.password) != true
      ) {
        this.loginFormTimeoutDialog = false;
        alert("패스워드 형식이 올바르지 않습니다.");
        return;
      }

      this.login({
        userid: this.loginForm.userid,
        password: this.loginForm.password,
      })
        .then(() => {
          this.closeAccountForm();
          // this.$router.go("");
          // alert("로그인 성공! " + this.getUserInfo.userid);
          const path = "/" + this.getUserInfo.userid;
          if (this.$route.path !== path) {
            // alert("main diff path: " + this.$route.path);
            // this.$router.replace(path);
            alert("로그인 성공 하였습니다.")
            this.$router.go("");
          }
        })
        .catch(() => {
          this.loginFormTimeoutDialog = false;
          alert("패스워드를 확인해주세요.");
        });
    },
    normalLogout: function () {
      if (confirm("로그아웃 하시겠습니까?")) {
        event.preventDefault();
        this.logout();
        this.$router.go("");
      }
    },
    openRegisterForm: function () {
      this.closeAccountForm();
      this.registerDialog = true;
    },
    openFindIDAccountForm: function () {
      this.accountDialog = false;
      this.findIDAccountDialog = true;
    },
    openFindPasswordAccountForm: function () {
      this.accountDialog = false;
      this.findPasswordAccountDialog = true;
    },
    closeFindIDAccountForm: function () {
      this.findIDAccountTimeoutDialog = false;
      this.findIDAccountDialog = false;
      this.findPutNameForId = "";
      this.findPutBirth = "";
      this.findGetIdList = [];
    },
    closeFindPasswordAccountForm: function () {
      this.findPasswordAccountTimeoutDialog = false;
      this.findPasswordAccountDialog = false;
      this.findPutNameForPasswd = "";
      this.findPutId = "";
      this.findGetPasswordList = [];
    },
    registerUserIdCheck: function () {
      if (
        this.useridrules[0](this.registerFormUserid) != true ||
        this.useridrules[1](this.registerFormUserid) != true ||
        this.useridrules[2](this.registerFormUserid) != true
      ) {
        alert("아이디를 확인해주세요.");
        return;
      }

      axios({
        method: "GET",
        url: registerurl + "?userid=" + this.registerFormUserid,
      })
        .then((response) => {
          if (response.data.items[0] == undefined) {
            this.registerFormUserIdValidCheck = 1;
            this.registerFormUserIdSet = this.registerFormUserid;
            alert("사용 가능한 아이디 입니다.");
          } else {
            this.registerFormUserIdValidCheck = 0;
            this.registerFormUserIdSet = "";
            alert("아이다가 중복 됩니다.");
          }
        })
        .catch((response) => {
          console.log("axios error: ", response);
          return 0;
        });
    },
    save(date) {
      this.$refs.menu.save(date);
    },
    findsave(date) {
      this.$refs.findmenu.save(date);
    },
    normalRegister: function () {
      if (this.registerFormUserIdValidCheck == 0) {
        this.registerFormTimeoutDialog = false;
        alert("아이디 중복 체크를 완료해주세요.");
        return;
      }

      if (
        this.registerFormPasswordInfo != "" ||
        this.passwordrules[0](this.registerFormPassword1) != true ||
        this.passwordrules[1](this.registerFormPassword1) != true ||
        this.passwordrules[0](this.registerFormPassword2) != true ||
        this.passwordrules[1](this.registerFormPassword2) != true
      ) {
        this.registerFormTimeoutDialog = false;
        alert("패스워드를 확인해주세요.");
        return;
      }
      if (this.sexrules[0](this.registerForm.sex) != true) {
        this.registerFormTimeoutDialog = false;
        alert("성별을 확인해주세요.");
        return;
      }
      if (this.daterules[0](this.registerForm.date_of_birth) != true) {
        this.registerFormTimeoutDialog = false;
        alert("생일을 확인해주세요.");
        return;
      }

      this.registerForm.userid = this.registerFormUserid;
      // this.registerForm.name = encodeURIComponent(this.registerForm.name);
      this.registerForm.password = this.registerFormPassword1;
      this.registerForm.password2 = this.registerFormPassword2;
      var date = new Date();
      var year = date.getFullYear();
      var month = ("0" + (date.getMonth() + 1)).slice(-2);
      var day = ("0" + date.getDate()).slice(-2);
      this.registerForm.createdate = year + "-" + month + "-" + day;
      axios({
        method: "POST",
        url: registerurl,
        data: this.registerForm,
      })
        .then(() => {
          this.$store.dispatch("askmeStore/verifyToken");
          alert("회원 가입 완료 되었습니다.\n");
          this.closeRegisterForm();
          this.registerFormTimeoutDialog = false;
        })
        .catch((response) => {
          alert("회원 가입 실패하였습니다.\n관리자에게 문의하세요.");
          this.registerFormTimeoutDialog = false;
          console.log("axios error: ", response);
          return 0;
        });
    },
    async getAccountInfo() {
      await axios({
        method: "GET",
        url: registerurl + "?userid=" + this.getUserInfo.userid,
      })
        .then((response) => {
          console.log(response.data.items[0]);
          let userInfo = response.data.items[0].fields;
          this.updateAccountInfo.name = userInfo.name;
          this.updateAccountInfo.sex = userInfo.sex;
        })
        .catch((response) => {
          console.log("axios user info get error: ", response);
        });
    },
    updateForm: function () {
      if (
        this.updateAccountInfo.name == "" ||
        this.updateAccountInfo.sex == -1
      ) {
        alert("데이터가 유효하지 않습니다.");
        return;
      }
      this.updateAccountInfo.userid = this.getUserInfo.userid;
      axios({
        method: "PATCH",
        url: updateurl,
        data: this.updateAccountInfo,
      })
        .then(() => {
          this.verifyToken();
          alert("회원정보 변경이 완료 되었습니다.");
        })
        .catch((error) => {
          console.log("Axios patch error: ", error.reponse);
        });
      this.updateAccountDialog = false;
    },
    changePasswordForm() {
      if (this.changePasswordType == 0) {
        alert("패스워드를 확인해주세요.");
        return;
      }
      this.passwordChangeData.current_password = this.changePasswordCur;
      this.passwordChangeData.new_password = this.changePassword;
      axios({
        method: "POST",
        url: passwordchangeurl,
        data: this.passwordChangeData,
      })
        .then(() => {
          alert("비밀번호 변경이 완료 되었습니다. 다시 로그인 하십시오.");
          this.logout();
          this.$router.go();
        })
        .catch((response) => {
          alert("비밀번호 변경 실패하였습니다.\n 비밀번호를 확인 하십시오.");
          console.log("axios error: ", response);
          return 0;
        });
      this.changePassword = "";
      this.changePassword2 = "";
      this.changePasswordCur = "";
      this.updatePasswordDialog = false;
    },
    charToUnicode: function (str) {
      if (!str) return false; // Escaping if not exist
      var unicode = "";
      for (var i = 0, l = str.length; i < l; i++) {
        unicode += "\\" + str[i].charCodeAt(0).toString(16);
      }
      return unicode;
    },
    findIdForm: function () {
      if (this.findPutNameForId == null || this.findPutNameForId == "") {
        alert("이름을 입력하세요.");
        return;
      }
      if (this.findPutBirth == null || this.findPutBirth == "") {
        alert("생일을 입력하세요.");
        return;
      }
      this.findGetIdList = [];
      // alert(registerurl + "?name=" + this.findPutNameForId);
      axios({
        method: "GET",
        url:
          findidurl +
          "?name=" +
          this.findPutNameForId +
          "&date_of_birth=" +
          this.findPutBirth,
      })
        .then((response) => {
          if (response.data.count > 0) {
            for (var i = 0; i < response.data.count; i++) {
              // alert(JSON.stringify(response.data.results[i].userid.replace(response.data.results[i].userid.substr(-3,2), "**")))
              response.data.results[i].userid = response.data.results[
                i
              ].userid.replace(
                response.data.results[i].userid.substr(-3, 2),
                "**"
              );
              this.findGetIdList.push(response.data.results[i]);
            }
          } else {
            alert("해당 정보를 가진 ID가 존재하지 않습니다.");
          }
          this.findIDAccountTimeoutDialog = false;
        })
        .catch((response) => {
          this.findIDAccountTimeoutDialog = false;
          alert("조회 실패하였습니다.\n관리자에게 문의 하십시오.");
          console.log("axios find ID get error: ", response);
        });
    },
    findPasswordForm: function () {
      if (this.findPutId == null || this.findPutId == "") {
        alert("ID를 입력하세요.");
        return;
      }
      if (
        this.findPutNameForPasswd == null ||
        this.findPutNameForPasswd == ""
      ) {
        alert("이름을 입력하세요.");
        return;
      }

      this.findGetPasswordList = [];

      axios({
        method: "GET",
        url:
          findpasswdurl +
          "?userid=" +
          this.findPutId +
          "&name=" +
          this.findPutNameForPasswd,
      })
        .then((response) => {
          if (response.data.count > 0) {
            // alert(JSON.stringify(response.data.results));
            for (var i = 0; i < response.data.count; i++) {
              this.findGetPasswordList.push(response.data.results[i]);
            }
          } else {
            alert("해당 정보를 가진 패스워드가 존재하지 않습니다.");
          }
          this.findPasswordAccountTimeoutDialog = false;
        })
        .catch((response) => {
          this.findPasswordAccountTimeoutDialog = false;
          alert("조회 실패하였습니다.\n관리자에게 문의 하십시오.");
          console.log("axios find Passwd get error: ", response);
        });
    },
    ...mapActions("askmeStore", ["logout", "login", "verifyToken"]),
  },
};
</script>
