import Vue from 'vue'
import VueRouter from 'vue-router'

import LottoRandom from "../components/LottoRandom"
import LottoHistory from "../components/LottoHistory"
import LottoSimulator from "../components/LottoSimulator"
import LottoMain from "../components/LottoMain"

import MainHome from "../components/MainHome";
import MainProjects from "../components/MainProjects";
import MainAbout from "../components/MainAbout";
import MainBoard from "../components/MainBoard";
import MainPost from "../components/MainPost";
import MainFaq from "../components/MainFaq";
import MainViewPost from "../components/MainViewPost";

Vue.use(VueRouter)
// export default new Router

const routes = [ 
        {
        path: "/",
        name: "/",
        component: MainHome,  
    },
    {
        path: "/home",
        name: "/Home",
        component: MainHome,  
    },
    {
        path: "/projects",
        name: "/Projects", 
        component: MainProjects,
    },
    {
        path : "/about",
        name : "/About",
        component : MainAbout,        
    },
    {
        path : "/board/",
        name : "/Board",
        component : MainBoard,             
    },  
    {
        path : "/post/:category",
        name : "/Post", 
        component : MainPost, 
    },
    {
        path : "/mvpost/", 
        name : "Mvpost", 
        component : MainViewPost,
    },
    {
        path : "/faq", 
        name : "/Faq", 
        component : MainFaq,
    },
    {
        path : "/lottomain",
        name : "/LottoMain",
        component: LottoMain
    },
    {
        path : "/lottorandom",
        name : "/LottoRandom",
        component: LottoRandom
    },
    {
        path : "/lottohistory",
        name : "/LottoHistory",
        component: LottoHistory
    }, 
    {
        path : "/lottosimulator",
        name : "/LottoSimulator",
        component : LottoSimulator
    }
]
const router = new VueRouter({
    // mode: history,
    routes,
})
export default router