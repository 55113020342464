<!-- 
 1. 고정 기능 필요(순서 고정)
 2. 자주 나오는 숫자 기반 뽑기(확률 보기등)
 3. 
--> 
<template>
  <v-app>
    <v-main>
      <!-- <Adsense
        data-ad-client="ca-pub-5746542177729335"
        data-ad-slot="3800733658"
        data-ad-format="auto"
        data-full-width-responsive="true"
      >
      </Adsense> -->
      <v-row align="start" justify="start" no-gutters style="height: 500px">
        <v-col cols="12" sm="12" md="12">
          <div>
            <v-card class="mx-auto pa-10 ma-12" max-width="600px" outlined>
              <p>편하게 로또 번호 추첨해볼까요 :)</p>
              <v-btn class="ma-1" outlined rounded text @click="num_gen">
                랜덤추첨
              </v-btn>
              <v-btn class="ma-1" outlined rounded text @click="num_5_gen">
                5번랜덤
              </v-btn>
              <v-btn
                class="ma-1"
                outlined
                rounded
                text
                @click="isFixedNumModalViewed = true"
              >
                숫자고정
              </v-btn>
              <v-row class="mx-auto ma-1">
                <v-col cols="2">
                  <div v-bind:style="balls_attr_0">{{ balls_num[0] }}</div>
                </v-col>
                <v-col cols="2">
                  <div v-bind:style="balls_attr_1">{{ balls_num[1] }}</div>
                </v-col>
                <v-col cols="2">
                  <div v-bind:style="balls_attr_2">{{ balls_num[2] }}</div>
                </v-col>
                <v-col cols="2">
                  <div v-bind:style="balls_attr_3">{{ balls_num[3] }}</div>
                </v-col>
                <v-col cols="2">
                  <div v-bind:style="balls_attr_4">{{ balls_num[4] }}</div>
                </v-col>
                <v-col cols="2">
                  <div v-bind:style="balls_attr_5">{{ balls_num[5] }}</div>
                </v-col>
              </v-row>
            </v-card>
            <v-card class="mx-auto pa-10 ma-12" max-width="600px" outlined>
              <p>랜덤 생성 결과 화면, <strong>일등</strong>이 있길..!</p>
              <v-btn class="ma-2" outlined rounded text @click="save_lotto">
                내 로또 번호 저장
              </v-btn>
              <v-btn class="ma-2" outlined rounded text @click="focus_my_lotto">
                내 로또 번호 보기
              </v-btn>
              <transition-group name="list" tag="ul" class="ul_history">
                <li v-for="(history, idx) in num_history" :key="idx">
                  {{ idx + 1 }} 번째 결과 :
                  <v-row class="mx-auto ma-1">
                    <v-col cols="2">
                      <div
                        class="ball"
                        v-bind:style="{
                          backgroundColor: computedBgColor(history[0]),
                        }"
                      >
                        {{ history[0] }}
                      </div>
                    </v-col>
                    <v-col cols="2">
                      <div
                        class="ball"
                        v-bind:style="{
                          backgroundColor: computedBgColor(history[1]),
                        }"
                      >
                        {{ history[1] }}
                      </div>
                    </v-col>
                    <v-col cols="2">
                      <div
                        class="ball"
                        v-bind:style="{
                          backgroundColor: computedBgColor(history[2]),
                        }"
                      >
                        {{ history[2] }}
                      </div>
                    </v-col>
                    <v-col cols="2">
                      <div
                        class="ball"
                        v-bind:style="{
                          backgroundColor: computedBgColor(history[3]),
                        }"
                      >
                        {{ history[3] }}
                      </div>
                    </v-col>
                    <v-col cols="2">
                      <div
                        class="ball"
                        v-bind:style="{
                          backgroundColor: computedBgColor(history[4]),
                        }"
                      >
                        {{ history[4] }}
                      </div>
                    </v-col>
                    <v-col cols="2">
                      <div
                        class="ball"
                        v-bind:style="{
                          backgroundColor: computedBgColor(history[5]),
                        }"
                      >
                        {{ history[5] }}
                      </div>
                    </v-col>
                  </v-row>
                </li>
              </transition-group>
            </v-card>
          </div>
        </v-col>
        <v-col sm="12" md="12" col="12">
          <v-hover v-slot="{ hover }">
            <v-card
              class="mx-auto pa-10 ma-12"
              max-width="600px"
              outlined
              :elevation="hover_my_lotto ? 20 : 2"
              :class="{ 'on-hover': hover }"
            >
              <form id="my_lotto" />
              <p id="my_lotto">나만의 로또 번호에요. 로또 사러 가봐요 우리!</p>
              <v-btn class="ma-2" outlined rounded text @click="removeAll">
                초기화 하기
              </v-btn>
              <v-btn
                class="ma-2"
                outlined
                rounded
                text
                @click="isAnalysisView = true"
              >
                로또번호 분석기
              </v-btn>
              <!-- created 시점에서 내로또 번호 가져오고,
                        저장할때 업데이트해서 최신 로또 번호 가져오고 v-for로
                        로또 번호 기록 관리 -->
              <br /><br />
              <v-row>
                <v-col
                  v-for="(my_lotto_info_item, idx) in my_lotto_info"
                  :key="idx"
                  cols="12"
                >
                  <p>{{ idx + 1 }} 번째 로또 번호</p>
                  <v-row class="mx-auto">
                    <v-col cols="2">
                      <div
                        class="ball"
                        v-bind:style="{
                          backgroundColor: computedBgColor(
                            my_lotto_info_item[0]
                          ),
                        }"
                      >
                        {{ my_lotto_info_item[0] }}
                      </div>
                    </v-col>
                    <v-col cols="2">
                      <div
                        class="ball"
                        v-bind:style="{
                          backgroundColor: computedBgColor(
                            my_lotto_info_item[1]
                          ),
                        }"
                      >
                        {{ my_lotto_info_item[1] }}
                      </div>
                    </v-col>
                    <v-col cols="2">
                      <div
                        class="ball"
                        v-bind:style="{
                          backgroundColor: computedBgColor(
                            my_lotto_info_item[2]
                          ),
                        }"
                      >
                        {{ my_lotto_info_item[2] }}
                      </div>
                    </v-col>
                    <v-col cols="2">
                      <div
                        class="ball"
                        v-bind:style="{
                          backgroundColor: computedBgColor(
                            my_lotto_info_item[3]
                          ),
                        }"
                      >
                        {{ my_lotto_info_item[3] }}
                      </div>
                    </v-col>
                    <v-col cols="2">
                      <div
                        class="ball"
                        v-bind:style="{
                          backgroundColor: computedBgColor(
                            my_lotto_info_item[4]
                          ),
                        }"
                      >
                        {{ my_lotto_info_item[4] }}
                      </div>
                    </v-col>
                    <v-col cols="2">
                      <div
                        class="ball"
                        v-bind:style="{
                          backgroundColor: computedBgColor(
                            my_lotto_info_item[5]
                          ),
                        }"
                      >
                        {{ my_lotto_info_item[5] }}
                      </div>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-card>
          </v-hover>
        </v-col>
      </v-row>
    </v-main>
    <ModalView
      v-if="isMyLottoModalViewed"
      @close-modal="isMyLottoModalViewed = false"
    >
      <InnerContent />
    </ModalView>

    <ModalView
      v-if="isFixedNumModalViewed"
      @close-modal="isFixedNumModalViewed = false"
    >
      <FixedNumContent v-on:fixedEvent="fixedResult" />
    </ModalView>
    <ModalView v-if="isAnalysisView" @close-modal="isAnalysisView = false">
      <InnerContent />
    </ModalView>
  </v-app>
</template>
<!-- <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-5746542177729335"
     crossorigin="anonymous"></script> -->
<script>
import ModalView from "./ModalView";
import InnerContent from "./InnerContent";
import FixedNumContent from "./FixedNumContent";
// import axios from 'axios';

export default {
  components: {
    ModalView,
    InnerContent,
    FixedNumContent,
  },
  data() {
    return {
      my_lotto_info: [],
      hover_my_lotto: false,

      isMyLottoModalViewed: false,
      isFixedNumModalViewed: false,
      isAnalysisView: false,
      balls_num: [0, 0, 0, 0, 0, 0],
      is_fixed: [false, false, false, false, false, false],
      fixed_balls_num: [],
      ball_color: [
        "#ffd400",
        "#ffd400",
        "#ffd400",
        "#ffd400",
        "#ffd400",
        "#ffd400",
        "#ffd400",
        "#ffd400",
        "#ffd400",
        "#ffd400",
        "#ffd400",
        "#ffd400",
        "#ffd400",
        "#ffd400",
        "#ffd400",
        "#ffd400",
        "#ffd400",
        "#ffd400",
        "#ffd400",
        "#ffd400",
      ],
      num_history: [],
      balls_attr: {
        backgroundColor: "#ffd400",
        width: "40px",
        height: "40px",
        color: "white",
        fontWeight: "bold",
        textAlign: "center",
        lineHeight: "40px",
        borderRadius: "50%",
      },
      balls_attr_0: {
        backgroundColor: "#ffd400",
        width: "40px",
        height: "40px",
        color: "white",
        fontWeight: "bold",
        textAlign: "center",
        lineHeight: "40px",
        borderRadius: "50%",
      },
      balls_attr_1: {
        backgroundColor: "#ffd400",
        width: "40px",
        height: "40px",
        color: "white",
        fontWeight: "bold",
        textAlign: "center",
        lineHeight: "40px",
        borderRadius: "50%",
      },
      balls_attr_2: {
        backgroundColor: "#ffd400",
        width: "40px",
        height: "40px",
        color: "white",
        fontWeight: "bold",
        textAlign: "center",
        lineHeight: "40px",
        borderRadius: "50%",
      },
      balls_attr_3: {
        backgroundColor: "#ffd400",
        width: "40px",
        height: "40px",
        color: "white",
        fontWeight: "bold",
        textAlign: "center",
        lineHeight: "40px",
        borderRadius: "50%",
      },
      balls_attr_4: {
        backgroundColor: "#ffd400",
        width: "40px",
        height: "40px",
        color: "white",
        fontWeight: "bold",
        textAlign: "center",
        lineHeight: "40px",
        borderRadius: "50%",
      },
      balls_attr_5: {
        backgroundColor: "#ffd400",
        width: "40px",
        height: "40px",
        color: "white",
        fontWeight: "bold",
        textAlign: "center",
        lineHeight: "40px",
        borderRadius: "50%",
      },
    };
  },
  created: function () {
    console.log("==========CREQTEA+++++=");
    /* 처음 생성될때 내 로또 번호가 있는지 검색 후 정보 저장 */
    this.my_lotto_info = [];
    for (var i = 0; i < 5; i++) {
      var lotto_num = localStorage.getItem(i);
      if (lotto_num == null) break;
      var split_res = lotto_num.split(",");
      console.log("split = " + split_res);
      split_res.sort(function (a, b) {
        if (a > b) return 1;
        if (a == b) return 0;
        if (a < b) return -1;
      });
      console.log("sorted split = " + split_res);

      this.my_lotto_info.push(split_res);
    }
  },
  updated: function () {
    console.log("updated");
  },
  methods: {
    statistics_for_mylotto: function () {
      alert(
        "여러분의 로또 번호와 로또당첨 번호의 상관관계를 분석해서 당첨 확률을 추천해줄께요! 준비중입니다:)"
      );
    },
    removeAll: function () {
      localStorage.clear();
      this.my_lotto_info = [];
      alert("초기화 완료!");
    },
    focus_my_lotto: function () {
      // alert("DD");
      this.hover_my_lotto = true;
      // this.$refs.my_lotto.focus();
      document.querySelector("#my_lotto").scrollIntoView({
        behavior: "smooth",
      });
      setTimeout(() => (this.hover_my_lotto = false), 1000);
      // this.hover_my_lotto = f;
      // window.location.href = "my_lotto";
    },
    fixedResult: function (param) {
      // console.log("KEONWOO");
      // console.log(param);
      this.fixed_balls_num = [...param];
      for (var i = 0; i < this.fixed_balls_num.length; i++) {
        this.balls_num[i] = this.fixed_balls_num[i];
        console.log(" i = " + i + "/" + this.balls_num[i]);
        this.is_fixed[i] = true;
        this.change_ball_color(i);
      }
    },
    created_change_ball_color: function () {},
    show_lotto: function () {},
    save_lotto: function () {
      if (this.num_history.length < 1) {
        alert("최소한 한개의 번호는 생성해주세요");
        return;
      }
      // localStorage.clear();
      localStorage.removeItem(0);
      localStorage.removeItem(1);
      localStorage.removeItem(2);
      localStorage.removeItem(3);
      localStorage.removeItem(4);

      for (var i = 0; i < this.num_history.length; i++) {
        localStorage.setItem(i, this.num_history[i]);
      }
      alert("나만의 번호 저장 완료!");
      this.my_lotto_info = [];
      for (i = 0; i < 5; i++) {
        var lotto_num = localStorage.getItem(i);
        if (lotto_num == null) break;
        var split_res = lotto_num.split(",");
        console.log("split = " + split_res);
        this.my_lotto_info.push(split_res);
      }
    },
    purchase_do: function () {
      // window.open("https://www.dhlottery.co.kr/common.do?method=main");
    },
    validate_check: function (idx, random_val) {
      for (var i = 0; i < 5; i++) {
        if (idx !== i) {
          if (this.balls_num[i] == random_val) return false;
        }
      }
      return true;
    },
    change_ball_color: function (idx) {
      var color = 0;
      //#ffd400 1 - 9
      //#00d4ff 10 -19
      //#FF5a5a 20 - 29
      //#808080 30 - 39
      //#92DF45 40 -45
      if (this.is_fixed[idx]) {
        color = "#000000";
      } else {
        if (this.balls_num[idx] < 10) {
          // this.balls_num.
          color = "#ffd400";
        } else if (this.balls_num[idx] < 20) {
          color = "#00d4ff";
        } else if (this.balls_num[idx] < 30) {
          color = "#FF5a5a";
        } else if (this.balls_num[idx] < 40) {
          color = "#808080";
        } else {
          color = "#92DF45";
        }
      }
      switch (idx) {
        case 0:
          this.balls_attr_0.backgroundColor = color;
          break;
        case 1:
          this.balls_attr_1.backgroundColor = color;
          break;
        case 2:
          this.balls_attr_2.backgroundColor = color;
          break;
        case 3:
          this.balls_attr_3.backgroundColor = color;
          break;
        case 4:
          this.balls_attr_4.backgroundColor = color;
          break;
        case 5:
          this.balls_attr_5.backgroundColor = color;
          break;
      }
    },
    random_number_gen: function (idx) {
      // console.log("idx = " + idx);
      var random_val = 0;
      var is_validate = false;
      while (is_validate == false) {
        var min = Math.ceil(1);
        var max = Math.floor(45);
        random_val = Math.floor(Math.random() * (max - min)) + min;
        is_validate = this.validate_check(idx, random_val);
      }
      // console.log("random_val = " + random_val);
      this.balls_num[idx] = random_val;
      this.change_ball_color(idx);
    },
    num_gen: function () {
      for (var i = 0; i < 6; i++) {
        if (this.is_fixed[i] == false) {
          this.random_number_gen(i);
        }
      }
      this.write_history();
    },
    num_5_gen: function () {
      for (var i = 0; i < 6; i++) {
        this.num_gen();
      }
    },
    fixed_num: function () {},
    write_history: function () {
      var arr_list = [...this.balls_num];

      if (this.num_history.length > 4) {
        this.num_history.splice(0, 1);
      }
      console.log("before DDDD ===== " + arr_list);

      arr_list.sort(function (a, b) {
        if (a > b) return 1;
        if (a == b) return 0;
        if (a < b) return -1;
      });

      console.log("DDDD ===== " + arr_list);
      this.num_history.push(arr_list);
    },
    computedBgColor: function (num) {
      var color = "#ffffff";
      console.log("num = " + num);
      if (num < 10) {
        // this.balls_num.
        color = "#ffd400";
      } else if (num < 20) {
        color = "#00d4ff";
      } else if (num < 30) {
        color = "#FF5a5a";
      } else if (num < 40) {
        color = "#808080";
      } else {
        color = "#92DF45";
      }
      return color;
    },
  },
};
</script>
<style scoped>
.random_page {
  margin-top: 20px;
}
.ball {
  background-color: #ffd400;
  width: 40px;
  height: 40px;
  margin-left: 5px;
  font-weight: bold;
  text-align: center;
  line-height: 40px;
  border-radius: 50%;
  color: white;
}
.list-enter-active,
.list-leave-active {
  transition: all 1s;
}
.list-enter,
.list-leave-to {
  opacity: 0;
  transform: translateY(-30px);
}
.ul_history {
  list-style-type: none;
  padding-left: 0px;
  margin-top: 0;
  text-align: center;
  /* background-color: gray; */
}
.li_history {
  display: block;
  min-height: 10px;
  line-height: 20px;
  margin: 0.5rem 0;
  padding: 0 0.9rem;
  background: white;
  border-radius: 5px;
}
</style>