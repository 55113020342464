<template>
    <footer>
        <!-- <p>Copyright © 2021 l2pcompany.co.,Ltd. All rights reserved.</p>
        <address>Contact webmaster for more information. l2panony@gmail.com</address> -->
    </footer>
</template>

<!-- <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-5746542177729335"
     crossorigin="anonymous"></script> -->
<script>
export default {
    
}
</script>
<style scoped>
footer{
position: fixed; bottom: 0; width: 100%;    
}

</style>