<template>
  <!-- <div> -->
  <v-row>
    <v-col cols="12" sm="12" md="8" offset-md="2">
      <v-card class="mt-2 pa-2">
        <v-tabs v-model="active_tab" centered icons-and-text>
          <v-tabs-slider></v-tabs-slider>
          <v-tab
            v-for="item in menuItems"
            :key="item.index"
            @click="getBoardList(item.index, 1)"
          >
            <b>{{ item.name }}</b>
            <v-img height="45px" width="45px" class="mx-auto" :src="item.path">
            </v-img>
          </v-tab>
        </v-tabs>
        <v-tabs-items v-model="active_tab">
          <v-tab-item v-for="item in menuItems" :key="item.name">
            <v-card flat>
              <v-data-table
                :headers="headers"
                :items="boards"
                :page.sync="page"
                :items-per-page="itemsPerPage"
                :sort-by="['id', 'date']"
                :sort-desc="[true, true]"
                :search="search"
                mobile-breakpoint="0"
                class="elevation-1 pt-3 mt-5"
                @page-count="pageCount = $event"
                style="cursor: pointer"
                hide-default-header
                hide-default-footer
              >
                <template v-slot:header="{}">
                  <th
                    style="font-size: 18px"
                    v-for="head in headers"
                    v-bind:key="head.text"
                  >
                    {{ head.text.toUpperCase() }}
                    <hr />
                  </th>
                </template>
                <template v-slot:item="{ item }">
                  <tr
                    style="text-align: center"
                    @click="rowClick(item, active_tab)"
                  >
                    <td width="5%">{{ calcCurCount(boards.indexOf(item)) }}</td>
                    <!-- <td>{{ item.name }}</td> -->
                    <td>{{ item.subject }} [ {{ item.reply_count }} ]</td>
                    <!-- <td>{{ item.content }}</td> -->
                    <td width="30%">{{ item.create_date }}</td>
                  </tr>
                </template>
              </v-data-table>
            </v-card>
          </v-tab-item>
        </v-tabs-items>
        <v-row class="justify-end">
          <v-col cols="7" sm="7" md="7">
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="검색어를 입력하세요."
              clearable
            ></v-text-field>
          </v-col>
          <v-col cols="3" lg="3" sm="3" md="3" xs="3">
            <v-btn dark rounded class="mt-3" @click="clickWriteBtn(active_tab)"
              >글쓰기</v-btn
            >
          </v-col>
        </v-row>
        <v-pagination
          v-model="page"
          :length="pageInfo.pageCount"
          color="black"
          background-color="block"
          @input="clickPageNum(active_tab, page)"
        ></v-pagination>
      </v-card>
    </v-col>
  </v-row>
</template>
<!-- <script
  async
  src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-5746542177729335"
  crossorigin="anonymous"
></script> -->
<script>
import axios from "axios";

export default {
  data: () => ({
    fab: false,
    active_tab: 0,
    tab: false,
    page: 1,
    reply: [],
    menuItems: [
      {
        name: "Alldroid",
        index: 0,
        path: require("../assets/alldroid_logo.png"),
      },
      {
        name: "Dotdroid",
        index: 1,
        path: require("../assets/dotdroid_logo.png"),
      },
      {
        name: "Qdroid",
        index: 2,
        path: require("../assets/qdroid_logo.png"),
      },
      {
        name: "WonderPod",
        index: 3,
        path: require("../assets/wonderpod.png"),
      },
    ],
    itemsPerPage: 5,
    boardReplyList: [],
    boards: [],
    pageInfo: {
      totalCount: 0,
      curPage: 0,
      pageCount: 0,
      // next: "",
      // prev: "",
    },
    headers: [
      {
        text: "N",
        align: "start",
        sortable: false,
      },
      // {
      //   text: "이름",
      //   align: "start",
      //   sortable: false,
      //   value: "name",
      // },
      { text: "제목", value: "subject", sortable: false },
      // { text: "내용", value: "content", sortable: false },
      { text: "날짜", value: "create_date", sortable: false },
    ],
    search: "",
  }),
  methods: {
    toTop() {
      this.$vuetify.goTo(0);
    },
    calcCurCount: function (index) {
      return (
        this.pageInfo.totalCount -
        (this.pageInfo.curPage - 1) * this.itemsPerPage -
        index
      );
    },
    clickPageNum: function (idx, page) {
      this.getBoardList(idx, page);
    },
    clickWriteBtn: function (idx) {
      this.$router.push({
        name: "/Post",
        params: {
          idx: idx,
        },
      });
    },
    rowClick: function (item, idx) {
      // alert(JSON.stringify(item));
      this.$router.push({
        name: "Mvpost",
        path: "/mvpost",
        query: {
          id: item.id,
          // content: item.content,
          // date: item.create_date,
          // name: item.name,
          // subject: item.subject,
          idx: idx,
        },
      });
    },
    getBoardReplyList: function (idx) {
      var query = "";

      switch (idx) {
        case 0:
          //alldroid
          query = "/l2pboard/alldroid_answers/";
          break;
        case 1:
          //dotdroid
          query = "/l2pboard/dotdroid_answers/";
          break;
        case 2:
          //qdroid
          query = "/l2pboard/qdroid_answers/";
          break;
        case 3:
          //wonderpod
          query = "/l2pboard/wonderpod_answers/";
          break;
        default:
          break;
      }

      axios({
        method: "GET",
        url: query,
      })
        .then((response) => {
          if (this.boardReplyList.length > 0) {
            this.boardReplyList = [];
          }
          // console.log(response.data.results);
          this.boardReplyList = response.data.results;
          // alert(JSON.stringify(this.boardReplyList));
        })
        .catch((response) => {
          console.log("axios board get error: ", response);
        });
    },
    getBoardList: function (idx, page) {
      var boardurl = "";

      switch (idx) {
        case 0:
          boardurl = "/l2pboard/alldroid_questions/?page=" + page;
          break;
        case 1:
          boardurl = "/l2pboard/dotdroid_questions/?page=" + page;
          break;
        case 2:
          boardurl = "/l2pboard/qdroid_questions/?page=" + page;
          break;
        case 3:
          boardurl = "/l2pboard/wonderpod_questions/?page=" + page;
          break;
      }

      axios({
        method: "GET",
        url: boardurl,
      })
        .then((response) => {
          this.pageInfo.totalCount = response.data.count;
          this.pageInfo.pageCount = Math.ceil(
            this.pageInfo.totalCount / Number(this.itemsPerPage)
          );
          this.pageInfo.curPage = page;

          if (this.boards.length > 0) {
            this.boards = [];
          }
          this.boards = response.data.results;
          for (var i = 0; i < response.data.results.length; i++) {
            this.boards[i].create_date =
              this.boards[i].create_date.split("T")[0];
          }
          return 0;
        })
        .catch((response) => {
          console.log("axios get error: ", response);
          return 0;
        });
    },
  },
  created: function () {},
  mounted() {
    if (Object.keys(this.$route.params).length == 0) {
      this.active_tab = 0;

      this.getBoardList(0, 1);
    } else {
      // alert(JSON.stringify(this.$route.params.category));
      this.active_tab = this.$route.params.category - 1;

      this.getBoardList(this.active_tab, 1);
    }
  },
  updated() {},
};
</script>
