<template>
  <v-form>
    <v-container>
      <v-row>
        <v-col cols="12" sm="12" md="8" offset-md="2">
          <v-card class="mt-2">
            <v-row justify="center">
              <v-btn
                v-scroll="onScroll"
                v-show="fab"
                fab
                dark
                fixed
                bottom
                right
                color="primary"
                @click="toTop"
              >
                <!-- <v-icon>keyboard_arrow_up</v-icon> -->
                위로
              </v-btn>
            </v-row>
          </v-card>

          <v-card class="mx-auto" max-width="600" outlined>
            <v-list-item two-line dense>
              <v-list-item-content>
                <v-list-item-title>
                  <v-row class="text-left">
                    <v-col cols="6">
                      <v-card-text class="font-weight-bold"
                        >사용자
                        <v-list-item-subtitle class="mt-1">{{
                          this.boardList.name
                        }}</v-list-item-subtitle>
                      </v-card-text>
                    </v-col>
                    <v-col cols="6">
                      <v-card-text class="font-weight-bold"
                        >날짜
                        <v-list-item-subtitle class="mt-1">{{
                          this.boardList.create_date
                        }}</v-list-item-subtitle>
                      </v-card-text>
                    </v-col>
                  </v-row>
                </v-list-item-title>
                <v-divider></v-divider>
                <v-list-item-title>
                  <v-row class="text-left">
                    <v-col cols="12">
                      <v-card-text class="font-weight-bold"
                        >제목
                        <v-list-item-subtitle class="mt-1">{{
                          this.boardList.subject
                        }}</v-list-item-subtitle>
                      </v-card-text>
                    </v-col>
                  </v-row>
                </v-list-item-title>
                <v-divider></v-divider>
                <v-list-item-title>
                  <v-row class="text-left">
                    <v-col cols="12">
                      <v-card-text class="font-weight-bold"
                        >내용
                        <v-list-item-subtitle
                          class="mt-1"
                          style="white-space: normal"
                          >{{ this.boardList.content }}</v-list-item-subtitle
                        >
                      </v-card-text>
                    </v-col>
                  </v-row>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-card-actions>
              <v-container class="text-left">
                <v-btn outlined rounded text @click="back()"> 목록 </v-btn>
                <v-btn
                  outlined
                  rounded
                  text
                  @click="updateBoardPasswordDialog = true"
                >
                  수정
                </v-btn>
                <v-dialog
                  v-model="updateBoardPasswordDialog"
                  persistent
                  max-width="500px"
                >
                  <v-card>
                    <v-card-title>
                      <span class="text-h5">비밀번호 입력</span>
                    </v-card-title>
                    <v-card-text>
                      <v-container>
                        <v-row>
                          <v-col cols="12">
                            <v-text-field
                              v-model="updatePassword"
                              :counter="20"
                              :rules="passwordRules"
                              type="password"
                              label="비밀번호"
                              outlined
                              required
                              prepend-inner-icon="mdi-lock"
                              v-on:keyup.enter="
                                updateBoardPasswordTimeDialog = true
                              "
                            >
                            </v-text-field>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-card-text>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn
                        outlined
                        rounded
                        text
                        @click="updateBoardPasswordTimeDialog = true"
                        :disabled="updateBoardPasswordTimeDialog"
                        :loading="updateBoardPasswordTimeDialog"
                        >확인
                      </v-btn>

                      <v-btn
                        outlined
                        rounded
                        text
                        @click="
                          (updateBoardPasswordDialog = false),
                            (updatePassword = '')
                        "
                        >닫기
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
                <v-dialog
                  v-model="updateBoardDialog"
                  persistent
                  max-width="500px"
                >
                  <v-card>
                    <v-card-title>
                      <span class="text-h5">수정</span>
                    </v-card-title>
                    <v-card-text>
                      <v-container>
                        <v-row>
                          <v-col cols="12">
                            <v-text-field
                              v-model="boardList.name"
                              :counter="20"
                              label="사용자"
                              :rules="nameRules"
                              outlined
                              required
                              v-on:keyup.enter="updateTimeoutDialog = true"
                            ></v-text-field>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col cols="12">
                            <v-textarea
                              v-model="boardList.subject"
                              :counter="200"
                              label="제목"
                              :rules="subjectRules"
                              outlined
                              required
                              v-on:keyup.enter="updateTimeoutDialog = true"
                            ></v-textarea>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col cols="12">
                            <v-textarea
                              v-model="boardList.content"
                              :counter="200"
                              label="내용"
                              :rules="contentRules"
                              outlined
                              required
                              v-on:keyup.enter="updateTimeoutDialog = true"
                            ></v-textarea>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-card-text>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn
                        outlined
                        rounded
                        text
                        @click="updateTimeoutDialog = true"
                        :disabled="updateTimeoutDialog"
                        :loading="updateTimeoutDialog"
                        >수정
                      </v-btn>

                      <v-btn
                        outlined
                        rounded
                        text
                        @click="
                          (updateBoardPasswordDialog = false),
                            (updateBoardDialog = false)
                        "
                        >닫기
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
                <v-btn
                  outlined
                  rounded
                  text
                  @click="deleteBoardPasswordDialog = true"
                >
                  삭제
                </v-btn>
                <v-dialog
                  v-model="deleteBoardPasswordDialog"
                  persistent
                  max-width="500px"
                >
                  <v-card>
                    <v-card-title>
                      <span class="text-h5">비밀번호 입력</span>
                    </v-card-title>
                    <v-card-text>
                      <v-container>
                        <v-row>
                          <v-col cols="12">
                            <v-text-field
                              v-model="deletePassword"
                              :counter="20"
                              :rules="passwordRules"
                              type="password"
                              label="비밀번호"
                              outlined
                              required
                              prepend-inner-icon="mdi-lock"
                              v-on:keyup.enter="
                                deleteBoardPasswordTimeDialog = true
                              "
                            >
                            </v-text-field>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-card-text>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn
                        outlined
                        rounded
                        text
                        @click="deleteBoardPasswordTimeDialog = true"
                        :disabled="deleteBoardPasswordTimeDialog"
                        :loading="deleteBoardPasswordTimeDialog"
                        >확인
                      </v-btn>

                      <v-btn
                        outlined
                        rounded
                        text
                        @click="
                          (deleteBoardPasswordDialog = false),
                            (deletePassword = '')
                        "
                        >닫기
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </v-container>
            </v-card-actions>
          </v-card>
          <v-card class="mx-auto" max-width="600px" outlined>
            <v-form>
              <v-container>
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                      v-if="!writeReplyDialog"
                      class="mt-2"
                      outlined
                      placeholder="관리자만 답글을 작성할 수 있습니다."
                      @click="writeReplyDialog = true"
                    ></v-text-field>
                    <v-container v-else>
                      <v-row>
                        <v-col cols="12">
                          <v-textarea
                            autofocus
                            v-model="writeReplyData.content"
                            :counter="300"
                            :rules="contentRules"
                            outlined
                            clearable
                            filled
                            required
                            single-line
                            placeholder="내용을 남겨주세요."
                          ></v-textarea>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="12">
                          <v-text-field
                            v-model="writeReplyData.adminpassword"
                            :counter="20"
                            :rules="passwordRules"
                            type="password"
                            label="관리자 비밀번호"
                            outlined
                            required
                            prepend-inner-icon="mdi-lock"
                            v-on:keyup.enter="watchWriteReplyDialog = true"
                          >
                          </v-text-field>
                        </v-col>
                      </v-row>
                      <v-row align="center" justify="end">
                        <v-col cols="auto" class="text-right">
                          <v-btn
                            @click="watchWriteReplyDialog = true"
                            :disabled="watchWriteReplyDialog"
                            :loading="watchWriteReplyDialog"
                            outlined
                            rounded
                            text
                            >쓰기</v-btn
                          >
                          <v-btn
                            @click="writeReplyDialog = false"
                            outlined
                            rounded
                            text
                            >닫기</v-btn
                          >
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-col>
                </v-row>
              </v-container>
            </v-form>
          </v-card>
          <v-card class="mx-auto" max-width="600px" outlined>
            <v-row class="text-left">
              <v-col>
                <v-card-text class="font-weight-bold"
                  >댓글 [ {{ getCountReply() }} ]</v-card-text
                >
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-data-table
                  :items="boardReplyList"
                  :page.sync="page"
                  :items-per-page="itemsPerPage"
                  :sort-by="['id', 'create_date']"
                  :sort-desc="[true, false]"
                  item-key="id"
                  class="elevation-0"
                  @page-count="pageCount = $event"
                  dense
                  hide-default-header
                  hide-default-footer
                >
                  <!-- <template v-slot:item="{ item, expand, isExpanded }"> -->
                  <template v-slot:item="{ item }">
                    <table
                      style="
                        width: 100%;
                        border-collapse: separate;
                        border-spacing: 0 1px;
                      "
                    >
                      <tr style="text-align: left">
                        <td width="100%">
                          <v-menu bottom offset-y>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                class="ml-5 ma-3 background-color: transparent"
                                v-bind="attrs"
                                v-on="on"
                                v-ripple="false"
                                icon
                              >
                                <strong>{{ item.name }}</strong>
                              </v-btn>
                            </template>
                            <v-list>
                              <v-list-item
                                v-for="(menuitem, i) in replyMenuItems"
                                :key="i"
                                @click="
                                  replyNameClick(item.id, menuitem.action)
                                "
                              >
                                <v-list-item-title>{{
                                  menuitem.title
                                }}</v-list-item-title>
                              </v-list-item>
                            </v-list>
                          </v-menu>
                          ( {{ item.create_date }})
                        </td>
                      </tr>
                      <tr style="text-align: left">
                        <v-card-text class="font-weight-bold">
                          <v-icon class="ml-2"
                            >mdi-subdirectory-arrow-right</v-icon
                          >{{ item.content }}
                        </v-card-text>
                      </tr>
                    </table>
                  </template>
                  <template slot="no-data">
                    <div>등록된 댓글이 없습니다.</div>
                  </template>
                </v-data-table>
                <v-pagination
                  v-model="page"
                  :length="pageInfo.pageCount"
                  color="black"
                  background-color="block"
                  @input="clickPageNum(page)"
                ></v-pagination>
              </v-col>
            </v-row>
            <v-dialog
              v-model="updateBoardReplyPasswordDialog"
              persistent
              max-width="500px"
            >
              <v-card>
                <v-card-title>
                  <span class="text-h5">비밀번호 입력</span>
                </v-card-title>
                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col cols="12">
                        <v-text-field
                          v-model="updateReplyPassword"
                          :counter="20"
                          :rules="passwordRules"
                          type="password"
                          label="비밀번호"
                          outlined
                          required
                          prepend-inner-icon="mdi-lock"
                          v-on:keyup.enter="
                            updateBoardReplyPasswordTimeDialog = true
                          "
                        >
                        </v-text-field>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    outlined
                    rounded
                    text
                    @click="updateBoardReplyPasswordTimeDialog = true"
                    :disabled="updateBoardReplyPasswordTimeDialog"
                    :loading="updateBoardReplyPasswordTimeDialog"
                    >확인
                  </v-btn>

                  <v-btn
                    outlined
                    rounded
                    text
                    @click="
                      (updateBoardReplyPasswordDialog = false),
                        (updateReplyPassword = '')
                    "
                    >닫기
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <v-dialog
              v-model="updateBoardReplyDialog"
              persistent
              max-width="500px"
            >
              <v-card>
                <v-card-title>
                  <span class="text-h5">수정</span>
                </v-card-title>
                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col cols="12">
                        <v-textarea
                          v-model="updateReplyData.content"
                          :counter="200"
                          label="내용"
                          :rules="contentRules"
                          outlined
                          required
                          v-on:keyup.enter="
                            updateBoardReplyTimeoutDialog = true
                          "
                        ></v-textarea>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    outlined
                    rounded
                    text
                    @click="updateBoardReplyTimeoutDialog = true"
                    :disabled="updateBoardReplyTimeoutDialog"
                    :loading="updateBoardReplyTimeoutDialog"
                    >수정
                  </v-btn>

                  <v-btn
                    outlined
                    rounded
                    text
                    @click="
                      (updateBoardReplyPasswordDialog = false),
                        (updateReplyPassword = ''),
                        (updateBoardReplyPasswordTimeDialog = false),
                        (updateBoardReplyTimeoutDialog = false),
                        (updateBoardReplyDialog = false)
                    "
                    >닫기
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <v-dialog
              v-model="deleteBoardReplyPasswordDialog"
              persistent
              max-width="500px"
            >
              <v-card>
                <v-card-title>
                  <span class="text-h5">비밀번호 입력</span>
                </v-card-title>
                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col cols="12">
                        <v-text-field
                          v-model="deleteReplyPassword"
                          :counter="20"
                          :rules="passwordRules"
                          type="password"
                          label="비밀번호"
                          outlined
                          required
                          prepend-inner-icon="mdi-lock"
                          v-on:keyup.enter="
                            deleteBoardReplyPasswordTimeDialog = true
                          "
                        >
                        </v-text-field>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    outlined
                    rounded
                    text
                    @click="deleteBoardReplyPasswordTimeDialog = true"
                    :disabled="deleteBoardReplyPasswordTimeDialog"
                    :loading="deleteBoardReplyPasswordTimeDialog"
                    >확인
                  </v-btn>

                  <v-btn
                    outlined
                    rounded
                    text
                    @click="
                      (deleteBoardReplyPasswordDialog = false),
                        (deleteReplyPassword = '')
                    "
                    >닫기
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>
<!-- <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-5746542177729335"
     crossorigin="anonymous"></script> -->
<script>
import axios from "axios";

export default {
  data: () => ({
    itemsPerPage: 10,
    page: 1,
    pageInfo: {
      totalCount: 0,
      curPage: 0,
      pageCount: 0,
      // next: "",
      // prev: "",
    },
    idx: 0,
    id: 0,
    updateBoardPasswordDialog: false,
    updatePassword: "",
    updateBoardPasswordTimeDialog: false,
    updateBoardDialog: false,
    updateTimeoutDialog: false,
    deleteBoardPasswordDialog: false,
    deletePassword: "",
    deleteBoardPasswordTimeDialog: false,
    fab: false,
    boardList: [],
    boardReplyList: [],
    writeReplyDialog: false,
    watchWriteReplyDialog: false,
    writeReplyData: {
      question: "",
      name: "",
      content: "",
      adminpassword: "",
      create_date: "",
    },
    updateBoardReplyPasswordDialog: false,
    updateReplyPassword: "",
    updateBoardReplyPasswordTimeDialog: false,
    updateBoardReplyDialog: false,
    updateBoardReplyTimeoutDialog: false,
    updateReplyData: {
      question: "",
      name: "",
      content: "",
      adminpassword: "",
      create_date: "",
    },
    deleteBoardReplyPasswordDialog: false,
    deleteReplyPassword: "",
    deleteBoardReplyPasswordTimeDialog: false,
    deleteBoardReplyId: "",
    replyMenuItems: [
      {
        title: "수정하기",
        action: "update",
      },
      {
        title: "삭제하기",
        action: "delete",
      },
    ],
    passwordRules: [
      (v) => !!v || "패스워드 형식이 올바르지 않습니다.",
      (v) => !(v && v.length >= 20) || "패스워드 형식이 올바르지 않습니다.",
    ],
    subjectRules: [
      (v) => !!v || "제목은 필수 입력사항 입니다.",
      (v) => !(v && v.length >= 100) || "제목은 100자 이상 입력할 수 없습니다.",
    ],
    nameRules: [
      (v) => !!v || "사용자 이름은 필수 입력사항 입니다.",
      (v) =>
        !(v && v.length >= 10) || "사용자 이름은 10자 이상 입력할 수 없습니다.",
    ],
    contentRules: [
      (v) => !!v || "내용은 필수 입력사항 입니다.",
      (v) => !(v && v.length >= 500) || "내용은 500자 이상 입력할 수 없습니다.",
      // v => !/[~!@#$%^&*()_+|<>?:{}]/.test(v) || '제목에는 특수문자를 사용할 수 없습니다.'
    ],
  }),
  methods: {
    onScroll(e) {
      if (typeof window === "undefined") return;
      const top = window.pageYOffset || e.target.scrollTop || 0;
      this.fab = top > 20;
    },
    toTop() {
      this.$vuetify.goTo(0);
    },
    click_shared: function () {
      alert("Ready...");
    },
    click_reply: function () {
      // alert("DD");
      document.getElementById("reply_textarea").focus();
    },
    back: function () {
      this.$router.go(-1);
    },
    getBoardList(idx, id) {
      var query = "";

      switch (idx) {
        case 0:
          //alldroid
          query = "/l2pboard/alldroid_questions/";
          break;
        case 1:
          //dotdroid
          query = "/l2pboard/dotdroid_questions/";
          break;
        case 2:
          //qdroid
          query = "/l2pboard/qdroid_questions/";
          break;
        case 3:
          //qdroid
          query = "/l2pboard/wonderpod_questions/";
          break;
        default:
          break;
      }

      axios({
        method: "GET",
        url: query + id + "/",
      })
        .then((response) => {
          this.boardList = response.data;
          var create_date = response.data.create_date;
          var convert = create_date.split("T");
          this.boardList.create_date =
            convert[0] + " " + convert[1].replace("Z", " ");
          // alert(JSON.stringify(response.data));
        })
        .catch((response) => {
          console.log("axios board get error: ", response);
        });
    },
    getBoardReplyList(idx, id, page) {
      var query = "";

      switch (idx) {
        case 0:
          //alldroid
          query = "/l2pboard/alldroid_answers/?question=";
          break;
        case 1:
          //dotdroid
          query = "/l2pboard/dotdroid_answers/?question=";
          break;
        case 2:
          //qdroid
          query = "/l2pboard/qdroid_answers/?question=";
          break;
        case 3:
          //wonderpod
          query = "/l2pboard/wonderpod_answers/?question=";
          break;
        default:
          break;
      }

      axios({
        method: "GET",
        url: query + id,
      })
        .then((response) => {
          if (this.boardReplyList.length > 0) {
            this.boardReplyList = [];
          }

          this.pageInfo.totalCount = response.data.count;
          this.pageInfo.pageCount = Math.ceil(
            this.pageInfo.totalCount / Number(this.itemsPerPage)
          );
          this.pageInfo.curPage = page;

          this.boardReplyList = response.data.results;
          for (var i = 0; i < this.boardReplyList.length; i++) {
            var create_date = this.boardReplyList[i].create_date;
            var convert = create_date.split("T");
            this.boardReplyList[i].create_date =
              convert[0] + " " + convert[1].replace("Z", " ");
          }

          // alert(JSON.stringify(this.boardReplyList));
        })
        .catch((response) => {
          console.log("axios board get error: ", response);
        });
    },
    writeReplyForm: function (idx, id) {
      if (
        this.writeReplyData.content == null ||
        this.writeReplyData.content == ""
      ) {
        alert("내용을 입력하세요.");
        return;
      }
      if (
        this.writeReplyData.adminpassword == null ||
        this.writeReplyData.adminpassword == ""
      ) {
        alert("관리자 패스워드를 입력하세요.");
        return;
      } else {
        if (this.writeReplyData.adminpassword != "sniper_001") {
          alert("관리자 패스워드가 틀렸습니다.");
          return;
        }
      }
      var query = "";
      switch (idx) {
        case 0:
          //alldroid
          query = "/l2pboard/alldroid_answers/";
          break;
        case 1:
          //dotdroid
          query = "/l2pboard/dotdroid_answers/";
          break;
        case 2:
          //qdroid
          query = "/l2pboard/qdroid_answers/";
          break;
        case 3:
          //wonderpod
          query = "/l2pboard/wonderpod_answers/";
          break;
      }
      var dt = new Date();
      var str =
        dt.getFullYear() + "-" + (dt.getMonth() + 1) + "-" + dt.getDate();
      str +=
        "T" +
        dt.getHours() +
        ":" +
        dt.getMinutes() +
        ":" +
        dt.getSeconds() +
        "Z";

      this.writeReplyData.question = id;
      this.writeReplyData.name = "admin";
      this.writeReplyData.create_date = str;
      // alert(JSON.stringify(this.writeReplyData))

      axios({
        method: "POST",
        url: query,
        data: this.writeReplyData,
      })
        .then(() => {
          (this.writeReplyDialog = false), (this.watchWriteReplyDialog = false);
          (this.writeReplyData = {
            question: "",
            name: "",
            content: "",
            adminpassword: "",
            create_date: "",
          }),
            alert("답글 등록 되었습니다.");
          this.$router.go("");
        })
        .catch((error) => {
          (this.writeReplyDialog = false), (this.watchWriteReplyDialog = false);
          (this.writeReplyData = {
            question: "",
            name: "",
            content: "",
            adminpassword: "",
            create_date: "",
          }),
            alert("답글 등록 실패하였습니다. 관리자에게 문의 하세요.");
          console.log("Axios get error: ", error.reponse);
        });
    },
    getCountReply: function () {
      return this.boardList.reply_count;
    },
    checkUpdateBoardPassword: function () {
      if (this.boardList.password != this.updatePassword) {
        this.updateBoardPasswordTimeDialog = false;
        alert("패스워드가 틀렸습니다.");
        return false;
      }
      this.updateBoardDialog = true;
    },
    checkDeleteBoardPassword: function () {
      if (this.boardList.password != this.deletePassword) {
        this.deleteBoardPasswordTimeDialog = false;
        alert("패스워드가 틀렸습니다.");
        return false;
      }
      this.deleteBoard();
    },
    checkUpdateBoardReplyPassword: function () {
      if (this.updateReplyPassword != "sniper_001") {
        this.updateBoardReplyPasswordTimeDialog = false;
        alert("패스워드가 틀렸습니다.");
        return false;
      }
      this.updateBoardReplyDialog = true;
    },
    updateForm: function (idx, id) {
      if (this.boardList.name == null || this.boardList.name == "") {
        alert("사용자를 입력하세요.");
        return;
      }
      if (this.boardList.subject == null || this.boardList.subject == "") {
        alert("제목을 입력하세요.");
        return;
      }
      if (this.boardList.content == null || this.boardList.content == "") {
        alert("내용을 입력하세요.");
        return;
      }

      var query = "";
      switch (idx) {
        case 0:
          //alldroid
          query = "/l2pboard/alldroid_questions/";
          break;
        case 1:
          //dotdroid
          query = "/l2pboard/dotdroid_questions/";
          break;
        case 2:
          //qdroid
          query = "/l2pboard/qdroid_questions/";
          break;
        case 3:
          //wonderpod
          query = "/l2pboard/wonderpod_questions/";
          break;
      }
      
      var dt = new Date();
      var str =
        dt.getFullYear() + "-" + (dt.getMonth() + 1) + "-" + dt.getDate();
      str +=
        "T" +
        dt.getHours() +
        ":" +
        dt.getMinutes() +
        ":" +
        dt.getSeconds() +
        "Z";
      this.boardList.create_date = str;
      console.log("11: " + query)
      axios({
        method: "PATCH",
        url: query + id + "/",
        data: this.boardList,
      })
        .then(() => {
          this.updateBoardPasswordDialog = false;
          this.updatePassword = "";
          this.updateBoardPasswordTimeDialog = false;
          this.updateBoardDialog = false;
          this.updateTimeoutDialog = false;
          alert("수정 되었습니다.");
        })
        .catch((error) => {
          this.updateBoardPasswordDialog = false;
          this.updatePassword = "";
          this.updateBoardPasswordTimeDialog = false;
          this.updateBoardDialog = false;
          this.updateTimeoutDialog = false;
          alert("수정 실패하였습니다. 관리자에게 문의 하십시오.");
          console.log("Axios patch error: ", error.reponse);
        });
    },
    deleteBoard: function () {
      var query = "";
      switch (this.idx) {
        case 0:
          //alldroid
          query = "/l2pboard/alldroid_questions/";
          break;
        case 1:
          //dotdroid
          query = "/l2pboard/dotdroid_questions/";
          break;
        case 2:
          //qdroid
          query = "/l2pboard/qdroid_questions/";
          break;
        case 3:
          //wonderpod
          query = "/l2pboard/wonderpod_questions/";
          break;
      }
      if (confirm("정말로 삭제 하시겠습니까?")) {
        axios({
          method: "DELETE",
          url: query + this.id + "/",
        })
          .then(() => {
            this.deleteBoardPasswordDialog = false;
            this.deletePassword = "";
            this.deleteBoardPasswordTimeDialog = false;
            alert("삭제 되었습니다.");
            this.back();
          })
          .catch((error) => {
            this.deleteBoardPasswordDialog = false;
            this.deletePassword = "";
            this.deleteBoardPasswordTimeDialog = false;
            alert("삭제 실패하였습니다. 관리자에게 문의 하십시오.");
            console.log("axios delete error: ", error.reponse);
          });
      } else {
        this.deleteBoardPasswordTimeDialog = false;
      }
    },
    clickPageNum: function (page) {
      this.getBoardReplyList(this.idx, this.id, page);
    },
    replyNameClick(id, action) {
      if (action == "update") {
        for (var i = 0; i < this.boardReplyList.length; i++) {
          if (this.boardReplyList[i].id == id) {
            // alert(this.boardReplyList[i].content);
            this.updateReplyData = this.boardReplyList[i];
            break;
          }
        }
        this.updateBoardReplyPasswordDialog = true;
      } else if (action == "delete") {
        this.deleteBoardReplyId = id;
        this.deleteBoardReplyPasswordDialog = true;
      }
    },
    updateReplyForm: function (idx) {
      var query = "";
      switch (idx) {
        case 0:
          //alldroid
          query = "/l2pboard/alldroid_answers/";
          break;
        case 1:
          //dotdroid
          query = "/l2pboard/dotdroid_answers/";
          break;
        case 2:
          //qdroid
          query = "/l2pboard/qdroid_answers/";
          break;
        case 3:
          //wonderpod
          query = "/l2pboard/wonderpod_answers/";
          break;
      }

      var dt = new Date();
      var str =
        dt.getFullYear() + "-" + (dt.getMonth() + 1) + "-" + dt.getDate();
      str +=
        "T" +
        dt.getHours() +
        ":" +
        dt.getMinutes() +
        ":" +
        dt.getSeconds() +
        "Z";
      this.updateReplyData.create_date = str;

      axios({
        method: "PATCH",
        url: query + this.updateReplyData.id + "/",
        data: this.updateReplyData,
      })
        .then(() => {
          this.updateBoardReplyPasswordDialog = false;
          this.updateReplyPassword = "";
          this.updateBoardReplyPasswordTimeDialog = false;
          this.updateBoardReplyDialog = false;
          this.updateBoardReplyTimeoutDialog = false;
          this.updateReplyData = {
            question: "",
            name: "",
            content: "",
            adminpassword: "",
            create_date: "",
          };
          this.$router.go("");
          alert("답글 수정 되었습니다.");
        })
        .catch((error) => {
          this.updateBoardReplyPasswordDialog = false;
          this.updateReplyPassword = "";
          this.updateBoardReplyPasswordTimeDialog = false;
          this.updateBoardReplyDialog = false;
          this.updateBoardReplyTimeoutDialog = false;
          this.updateReplyData = {
            question: "",
            name: "",
            content: "",
            adminpassword: "",
            create_date: "",
          };
          alert("답글 수정 실패하였습니다. 관리자에게 문의 하십시오.");
          console.log("Axios patch error: ", error.reponse);
        });
    },
    checkDeleteBoardReplyPassword: function () {
      if (this.deleteReplyPassword != "sniper_001") {
        this.deleteBoardReplyPasswordTimeDialog = false;
        alert("패스워드가 틀렸습니다.");
        return false;
      }
      this.deleteBoardReply(this.deleteBoardReplyId);
    },
    deleteBoardReply: function (id) {
      var query = "";
      switch (this.idx) {
        case 0:
          //alldroid
          query = "/l2pboard/alldroid_answers/";
          break;
        case 1:
          //dotdroid
          query = "/l2pboard/dotdroid_answers/";
          break;
        case 2:
          //qdroid
          query = "/l2pboard/qdroid_answers/";
          break;
        case 3:
          //wonderpod
          query = "/l2pboard/wonderpod_answers/";
          break;
      }

      if (confirm("정말로 삭제 하시겠습니까?")) {
        axios({
          method: "DELETE",
          url: query + id + "/",
        })
          .then(() => {
            this.deleteBoardReplyPasswordDialog = false;
            this.deleteReplyPassword = "";
            this.deleteBoardReplyPasswordTimeDialog = false;
            this.deleteBoardReplyId = "";
            this.$router.go("");
            alert("답글 삭제 되었습니다.");
          })
          .catch((error) => {
            this.deleteBoardReplyPasswordDialog = false;
            this.deleteReplyPassword = "";
            this.deleteBoardReplyPasswordTimeDialog = false;
            this.deleteBoardReplyId = "";

            alert("답글 삭제 실패하였습니다. 관리자에게 문의 하십시오.");
            console.log("axios delete error: ", error.reponse);
          });
      } else {
        this.deleteBoardPasswordTimeDialog = false;
      }
    },
  },
  watch: {
    watchWriteReplyDialog(val) {
      if (!val) return;

      setTimeout(
        () => (
          this.writeReplyForm(this.idx, this.id),
          (this.watchWriteReplyDialog = false)
        ),
        1000
      );
    },
    updateBoardPasswordTimeDialog(val) {
      if (!val) return;

      setTimeout(() => this.checkUpdateBoardPassword(), 1000);
    },
    updateTimeoutDialog(val) {
      if (!val) return;

      setTimeout(() => this.updateForm(this.idx, this.id), 1000);
    },
    deleteBoardPasswordTimeDialog(val) {
      if (!val) return;

      setTimeout(() => this.checkDeleteBoardPassword(), 1000);
    },
    updateBoardReplyPasswordTimeDialog(val) {
      if (!val) return;

      setTimeout(() => this.checkUpdateBoardReplyPassword(), 1000);
    },
    updateBoardReplyTimeoutDialog(val) {
      if (!val) return;

      setTimeout(() => this.updateReplyForm(this.idx), 1000);
    },
    deleteBoardReplyPasswordTimeDialog(val) {
      if (!val) return;

      setTimeout(() => this.checkDeleteBoardReplyPassword(), 1000);
    },
  },
  mounted() {
    this.idx = Number.parseInt(this.$route.query.idx);
    this.id = this.$route.query.id;
    this.getBoardList(this.idx, this.id);
    this.getBoardReplyList(this.idx, this.id, 1);
  },
  created() {},
};
</script>

<style>
#rcorners2 {
  border-radius: 10px;
  border: 1px solid #d1d1d1;
  padding: 10px;
  width: 100%;
}
</style>