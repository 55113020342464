<template>
  <v-app>
    <header>
      <v-app-bar color="white" app height="100">
        <v-spacer></v-spacer>
        <router-link :to="{ path: '/' }">
          <v-img
            class="mx-auto"
            style="display: block; margin: 0 auto"
            :src="require('@/assets/logo.png')"
            height="100"
            width="140"
            contain
          ></v-img>
          <!-- askme logo -->
        </router-link>
        <v-spacer></v-spacer>
        <template v-slot:extension>
          <v-tabs v-model="tab" show-arrows centered>
            <v-tab v-for="item in menuItems" :key="item.title" :to="item.path"
              ><p class="font-weight-bold">{{ item.title }}</p></v-tab
            >
          </v-tabs>
        </template>
      </v-app-bar>
    </header>
    <v-main>
      <router-view></router-view>
    </v-main>
    <v-divider></v-divider>
    <v-footer color="black" height="100px" app outlined fixed>
      <v-col class="text-center" cols="12">
        <font color="white">
          <strong
            >Copyright(c) {{ new Date().getFullYear() }}. l2pcompany All rights
            reserved.</strong
          >
        </font>
        <div class="mt-2 mb-2" id="google_translate_element"></div>
      </v-col>
    </v-footer>
  </v-app>
</template>
<!-- <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-5746542177729335"
     crossorigin="anonymous"></script> -->
<script src="https://translate.google.com/translate_a/element.js?cb=googleTranslateElementInit"></script>

<script>
import axios from "axios";

export default {
  name: "App",
  data() {
    return {
      logo: require("./assets/conpurple.png"),

      cnt_history: [],
      cnt_today: 0,
      cnt_total: 0,
      tab: 0,
      currentRouteName: "home",
      sidebar: false,
      menuItems: [
        { title: "Home", path: "/home", icon: "mdi-home" },
        { title: "About", path: "/about", icon: "mdi-alien" },
        { title: "Project", path: "/projects", icon: "mdi-book" },
        { title: "Q&A", path: "/board/", icon: "mdi-diamond" },
        { title: "FaQ", path: "/faq", icon: "mdi-hand-back-left" },
      ],
      icons: [
        "mdi-home",
        "mdi-alien",
        "mdi-book",
        "mdi-diamond",
        "mdi-hand-back-left",
      ],
      items: ["default", "absolute", "fixed"],
      padless: false,
      variant: "default",
    };
  },
  watch: {
    ["$route.name"](route) {
      // alert(route);
      this.currentRouteName = route;
      this.sidebar = false;
    },
  },
  mounted: function () {},
  created: function () {
    let recaptchaScript = document.createElement("script");
    recaptchaScript.setAttribute(
      "src",
      "https://translate.google.com/translate_a/element.js?cb=googleTranslateElementInit"
    );
    document.head.appendChild(recaptchaScript);
    window.googleTranslateElementInit = function () {
      new google.translate.TranslateElement(
        {
          pageLanguage: "ko",
          includedLanguages: "ko,zh-CN,zh-TW,ja,vi,th,tl,km,my,mn,ru,en,fr,ar",
          layout: google.translate.TranslateElement.InlineLayout.SIMPLE,
          autoDisplay: false,
        },
        "google_translate_element"
      );
    };
    // this.sidebar=false;
    // alert("DD");
    var history_cookie_name = "guest";
    var date = new Date();
    /* get cookie */

    // alert(date)
    var str_month = date.getMonth() + 1;
    var str_day = date.getDate();
    if (str_month < 10) str_month = "0" + str_month;
    if (str_day < 10) str_day = "0" + str_day;

    var query_date = date.getFullYear() + str_month + str_day;
    // alert(query_date)

    var value = document.cookie.match(
      "(^|;) ?" + history_cookie_name + "=([^;]*)(;|$)"
    );

    axios.defaults.headers.common["Content-Type"] =
      "application/x-www-form-urlencoded";
    axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
    axios.defaults.xsrfCookieName = "csrftoken";
    axios.defaults.xsrfHeaderName = "x-CSRFToken";
    if (value) {
      // alert("DD");
      axios
        .get("/l2pboard/history/?history_date=" + query_date + "&history_new=0")
        .then((res) => {
          console.log(res.data);
          this.cnt_today = res.data.history_today;
          this.cnt_total = res.data.history_sum;

          for (var i = 0; i < res.data.history_tot.length; i++) {
            this.cnt_history.push(res.data.history_tot[i]);
          }
        });
    } else {
      // if cookie is empty
      date = new Date();
      /* first set the cookie */
      /* cookie remain for one day */
      date.setTime(date.getTime() + 1 * 24 * 60 * 60 * 1000);
      document.cookie =
        history_cookie_name +
        "=" +
        "true" +
        ";expires=" +
        date.toUTCString() +
        ";path=/";

      axios
        .get("/l2pboard/history/?history_date=" + query_date + "&history_new=1")
        .then((res) => {
          console.log(res.data);
          this.cnt_today = res.data.history_today;
          this.cnt_total = res.data.history_sum;
          for (var i = 0; i < res.data.history_tot.length; i++) {
            this.cnt_history.push(res.data.history_tot[i]);
          }
        });
    }
    // if(value){

    //   // var post_dat = {
    //   //     "history_date": query_date,
    //   //     "history_today": 0,
    //   // }

    //   /* and post axios for increase today/total history in database */
    //   /* TODO : have to implement today history */

    //   axios.defaults.headers.common['Content-Type'] = 'application/x-www-form-urlencoded'
    //   axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
    //   axios.defaults.xsrfCookieName = 'csrftoken';
    //   axios.defaults.xsrfHeaderName = 'x-CSRFToken';
    //   axios.get("/api/l2pboard/history/?history_date="+query_date+"&history_new=1").then(
    //     res=>{
    //       this.cnt_today = res.data.history_today;
    //       this.cnt_total = res.data.history_sum;
    //     }
    //   )
    //   // 살아 있으면 get 만 하면 됨
    // /* and post axios for increase today/total history in database */
    //   /* TODO : have to implement today history */
    //   //   axios.defaults.headers.common['Content-Type'] = 'application/x-www-form-urlencoded'
    //   //   axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
    //   //   axios.defaults.xsrfCookieName = 'csrftoken';
    //   //   axios.defaults.xsrfHeaderName = 'x-CSRFToken';
    //   // axios.("/api/l2pboard/history/", post_dat)

    // }else{
    //   // alert("enroll new cookie")
    //   /* if cookie isn't exist, we have to increase today history */
    //   date = new Date();
    //   /* first set the cookie */
    //   /* cookie remain for one day */
    //   date.setTime(date.getTime() + 1*24*60*60*1000);
    //   document.cookie = history_cookie_name + '=' + 'true' + ';expires=' + date.toUTCString() + ';path=/';
    //   var query_int = Number.parseInt(query_date)
    //   var post_dat = {
    //       "history_date": query_int,
    //       "history_today": 0,
    //   }
    //   alert("post_dat = " + post_dat["history_date"])

    //   axios.defaults.headers.common['Content-Type'] = 'application/json'
    //   axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
    //   axios.defaults.xsrfCookieName = 'csrftoken';
    //   axios.defaults.xsrfHeaderName = 'x-CSRFToken';
    //   axios.defaults.withCredentials = true
    //   axios.post("/api/l2pboard/history/", post_dat)
    //   .then(
    //     res=>{
    //       console.log("history added");
    //       console.log(res)
    //       console.log(res.data.history_today);
    //       console.log(res.data.history_sum);
    //       this.cnt_today = res.data.history_today;
    //       this.cnt_total = res.data.history_sum;
    //     }
    //   ).catch((ex) => {
    //     console.log(ex)
    //   });
    // }
  },
  computed: {
    localAttrs() {
      const attrs = {};

      attrs.fixed = true;

      // if (this.variant === 'default') {
      //   attrs.absolute = false
      //   attrs.fixed = false
      // } else {
      //   attrs[this.variant] = true
      // }
      return attrs;
    },
  },
};
</script>
