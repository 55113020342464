<template>
<v-app class="ma-12">
<div>
  <v-card
   color="white"
  >
    <v-card-title class="text-center justify-center">
      <div class="mx-auto" align="center">
        <h1 class="font-weight-bold text-h2 black--text">
          <router-link to="/" tag="span" style="cursor: pointer">
            <v-img class="mx-auto" max-width="250px" :src="logo"></v-img>
          </router-link>
        </h1>
      </div>
    </v-card-title>

    <v-tabs
      background-color="transparent"
      show-arrows
      v-model="tab"
      color="black"
      grow
    >
      <v-tab
        v-for="item in menuItems"
        :key="item.title"
        :to="item.path"
        color="black"
        link
      >
        <font color="black">{{ item.title }}</font>
      </v-tab>
    </v-tabs>
  </v-card>    
  <router-view></router-view>
  <LottoFooter></LottoFooter>
</div>
</v-app>

</template>
<!-- <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-5746542177729335"
     crossorigin="anonymous"></script> -->
<script>
// import LottoHistory from './components/LottoHistory'
// import LottoRandom from './components/LottoRandom'
// import LottoHeader from './components/LottoHeader'
import LottoFooter from './components/LottoFooter'

export default {
  name: 'app',
  data () {
    return {
      tab: false,
      logo: require('./assets/lotto_logo.png'),

      menuItems : [
        {title: "랜덤뽑기", path: '/lottorandom', icon: 'mdi-home'},
        {title: "이력조회", path: '/lottohistory', icon: 'mdi-alien'},
        {title: "시뮬레이션", path: '/lottosimulator', icon: 'mdi-book'},
      ],
    }
  },
  components: { 
    // 'LottoHistory': LottoHistory,
    // 'LottoRandom' : LottoRandom,
    // 'LottoHeader' : LottoHeader, 
    'LottoFooter' : LottoFooter,
    
  }
}
</script>

<style>
#app {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}

h1, h2 {
  font-weight: normal;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  display: inline-block;
  margin: 0 10px;
}

a {
  color: #42b983;
}
</style>
