<template>
  <div class="content">
    <!-- <Adsense
      data-ad-client="ca-pub-5746542177729335"
      data-ad-slot="3800733658"
      data-ad-format="auto"
      data-full-width-responsive="true"
    >
    </Adsense> -->
    <h3>나의 로또 번호를 분석해드려요!</h3>
    <h5>확률에 기반한 점수에요, 로또는 언제까지나 운인거 아시죠?! 재미로 해봅시다 :) </h5>
    <div style="overflow:scroll;height:500px;width=300px">
        <!--<button v-on:click="removeAll">초기화 하기</button>-->
        
        <v-row>       
          <v-col cols="12" align="start" 
          v-for="(lotto_info, idx) in lotto_infos" :key="idx">
            <p> 나의 소중한 <strong>{{idx+1}}</strong> 번째 로또 번호 </p>
            <li class="ball" v-bind:style="{ backgroundColor: computedBgColor(idx,in_idx) }" v-for="(lotto_idx, in_idx) in lotto_info" :key="lotto_idx"> {{lotto_idx}} </li>
            <br /><br /><br />
            <h4><font color="grey"> 번호 분석 결과 </font></h4>
            <v-row>
            <v-col cols="6">
            <v-rating
              v-bind="get_rating(idx)"
              v-model="rating[idx]"
              background-color="black lighten-1"
              color="black"
              small
            ></v-rating>
            </v-col>
            <v-col cols="6">
            <v-btn  
              align="start"                  
              outlined
              rounded
              @click="buy_ticket"
              text >
            사러가기
            </v-btn>
            </v-col>
            </v-row>
            <br /><br />
            <p> 
              해당 번호를 분석해드릴께요!
              <br />모든 로또번호들은 {{num_min[idx]}} - {{num_max[idx]}} 사이에 분포하고 있습니다.
              <br />해당 번호는의 합은 {{sum[idx]}}로 <strong>{{computedSum(idx)}}</strong>
              <br />
              <br />모든 로또 중 가장 <strong>많이</strong> 나온 <font color="red">{{most_num[idx][0]}}</font>를 <strong>{{computedMostNum(idx)}}</strong>합니다.
              <br />또한 최근 100회 중 가장 많이 나온 <font color="red">{{most_num_hunds[idx][0]}}</font>를 <strong>{{computedMostNumHunds(idx)}}</strong>합니다.
              <br />
              <br />모든 로또 중 가장 <strong>적게</strong> 나온 <font color="blue">{{least_num[idx][0]}}</font>를 <strong>{{computedLeastNum(idx)}}</strong>합니다.
              <br />또한 최근 100회 중 가장 많이 나온 <font color="blue">{{least_num_hunds[idx][0]}}</font>를 <strong>{{computedLeastNumHunds(idx)}}</strong>합니다.

              <br />그리고 내 로또 번호의 짝수/홀수 개수가 일치한 역대 로또는 총 <strong>{{even_odd_match_cnt[idx]}}</strong>회 있었네요
              <br />
              <br />해당 번호로 역대 모든 로또를 시뮬레이션 해보았을때 
              <br />
              <br />1등 : <strong>{{first_prize_cnt[idx]}}회</strong>
              <br />2등 : <strong>{{second_prize_cnt[idx]}}회</strong>
              <br />3등 : <strong>{{third_prize_cnt[idx]}}회</strong>
              <br />4등 : <strong>{{fourth_prize_cnt[idx]}}회</strong>
              <br />5등 : <strong>{{fifth_prize_cnt[idx]}}회</strong>
              <br />낙첨 : <strong>{{no_prize_cnt[idx]}}회  </strong>
              <br />
              <br />라는 결과가 나왔으며 {{computedAnalysis(idx)}} 
            </p>
            <v-divider></v-divider>
          </v-col>
        </v-row>
        
   
    </div>
  </div>
</template>

<!-- <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-5746542177729335"
     crossorigin="anonymous"></script> -->
<script>
import axios from 'axios'
export default {
  name: "LottoContent",
  data(){ 
      return {
        analysis_result: [0,0,0,0,0],
        // analysis data
        sum: [],
        num_min: [],
        num_max: [], 
        num_avg: [],
        most_num: [], 
        least_num: [],
        is_most_num_include: [],
        is_least_num_include: [],
        most_num_hunds: [],
        least_num_hunds: [], 
        is_most_num_include_hunds:[],
        is_least_num_include_hunds:[],
        even_odd_match_cnt:  [],
        first_prize_cnt:  [],
        second_prize_cnt:  [],
        third_prize_cnt:  [],
        fourth_prize_cnt:  [],
        fifth_prize_cnt:  [],
        no_prize_cnt:  [],


        rating: [0,0,0,0,0],
        lotto_infos : [],
        ball_color : [
           "#ffd400","#ffd400","#ffd400","#ffd400","#ffd400",
           "#ffd400","#ffd400","#ffd400","#ffd400","#ffd400",
           "#ffd400","#ffd400","#ffd400","#ffd400","#ffd400",
           "#ffd400","#ffd400","#ffd400","#ffd400","#ffd400",
        ]
      }
  },
  created : function() { 
    // var lotto_info = [];
    this.lotto_infos = [];
    for(var i = 0; i < 5; i++) { 
      var lotto_num = localStorage.getItem(i); 
      if(lotto_num == null) break;

      var split_res = lotto_num.split(',');

      console.log("split = " + split_res);
      this.lotto_infos.push(split_res);
    }
    this.change_ball_color();


    /* axios를 통해서 backend에 analysis 정보를 분석 요청한다 
    인자에는 로또 번호들이 저장되어 있어야 된다
    backend 단에서는 로또 번호를 받아서, 해당 로또번호에 대한 여러가지 조사 후 결과를 리턴하는데
    필요한 정보들을 
    [0:가장자주나온숫자포함][1:포함]
    [1:가장안나온숫자포함][1:포함]
    [2:로또번호 총합][1:포함] 
    과 같은 데이터 형태로 받아온다. 
    그리고 이러한 결과 값들이 일치하는 경우 별점을 한개씩 메긴다. 
    */

 
    /* 해당 번호는 이때까지
     로또 번호 총합인 150 - 180 사이에 분포 하고있으며/있지 않으며 
     이때까지 가장 많이 나온 번호 45
     100회동안 가장 많이 나온 번호 23 을 포함하고 있습니다.
     또한 홀수가 3개 짝수가 3개인 번호는 현재까지 15번 있었으며 
     연속 번호 15,16이 존재합니다 1-1013회까지 연속번호가 2개였던 적은 총 20번 있었습니다.
     
     해당 번호로 1-1013회까지 돌려 보았을 때 
     1등 : 0 번
     2등 : 1번 
     3등 : 0번 
     4등 : 1번 
     5등 : 2번 
     낙첨 : 1011번 라는 결과가 나왔으며 아직 1등을 해보지 못한 번호입니다. */ 

    for(i = 0; i < 5; i++){ 
        var q_num1 = this.lotto_infos[i][0]; 
        var q_num2 = this.lotto_infos[i][1]; 
        var q_num3 = this.lotto_infos[i][2]; 
        var q_num4 = this.lotto_infos[i][3]; 
        var q_num5 = this.lotto_infos[i][4]; 
        var q_num6 = this.lotto_infos[i][5]; 
        var tsum = parseInt(q_num1) + parseInt(q_num2) + parseInt(q_num3) + parseInt(q_num4) + parseInt(q_num5) + parseInt(q_num6)
        this.sum.push(tsum)
        axios.defaults.headers.common['Content-Type'] = 'application/x-www-form-urlencoded'
        axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
        var query_str = "/api/lotto/get_lotto_analysis/?num1="+q_num1+"&num2="+q_num2+"&num3="+q_num3+"&num4="+q_num4+"&num5="+q_num5+"&num6="+q_num6; 
        // alert(q_num1 + "/" +q_num2 + "/" +q_num3 + "/" +q_num4 + "/" +q_num5 + "/" + q_num6 + "/" )   
        axios.get(query_str).then(response=>{
          console.log(response)
          this.num_min.push(response.data.min)
          this.num_max.push(response.data.max)
          this.num_avg.push(response.data.avg)
          this.most_num.push(response.data.most_num)
          this.least_num.push(response.data.least_num)
          this.is_most_num_include.push(response.data.is_most_num_include)
          this.is_least_num_include.push(response.data.is_least_num_include)
          this.is_most_num_include_hunds.push(response.data.is_most_num_include_hunds)
          this.is_least_num_include_hunds.push(response.data.is_least_num_include_hunds)
          this.most_num_hunds.push(response.data.most_num_hunds)
          this.least_num_hunds.push(response.data.least_num_hunds)
          this.even_odd_match_cnt.push(response.data.even_odd_match_cnt)
          this.first_prize_cnt.push(response.data.first_prize_cnt)
          this.second_prize_cnt.push(response.data.second_prize_cnt)
          this.third_prize_cnt.push(response.data.third_prize_cnt)
          this.fourth_prize_cnt.push(response.data.fourth_prize_cnt)
          this.fifth_prize_cnt.push(response.data.fifth_prize_cnt)
          this.no_prize_cnt.push(response.data.no_prize_cnt)
        });
    }

  },
  methods: {
      buy_ticket(){
          // alert("DD");
          window.open("https://www.dhlottery.co.kr/common.do?method=main");

      },
      get_rating(idx){
        // this.rating[0] =5;
        // this.rating[1] = 3;
        console.log("match ==== " + idx) 
        var count = 0;
        
        if(this.first_prize_cnt[idx] == 0 && 
        this.second_prize_cnt[idx] == 0){
          count += 1   
        }
        if(this.sum[idx] > this.num_min[idx] && this.sum[idx] < this.num_max[idx]){
          count += 1
        }
        if(this.even_odd_match_cnt[idx] > 300) {
          count += 1
        }
        if(this.is_least_num_include[idx] || this.is_least_num_include_hunds[idx]){
          count +=1 
        }  
        if(this.is_most_num_include[idx] || this.is_most_num_include_hunds[idx]){
          count +=1 
        }
        this.rating[idx] =count;
      },
      computedAnalysis(idx){
        if(this.first_prize_cnt[idx] == 0 && 
        this.second_prize_cnt[idx] == 0){
          return "아직 1등/2등을 해보지 못한 깨끗한 번호입니다 :)"
        }else{
          return "1등/2등 경험이 있는 번호이므로, 확인을 부탁드립니다 :("
        }
      },
      computedLeastNumHunds(idx){
        if(this.is_least_num_include_hunds[idx]){
          return "포함"
        }else 
          return "미포함"
      },
      computedLeastNum(idx){
        if(this.is_least_num_include[idx]){
          return "포함"
        }else 
          return "미포함"
      },
      computedMostNumHunds(idx){
        if(this.is_most_num_include_hunds[idx]){
          return "포함"
        }else 
          return "미포함"
      },
      computedMostNum(idx){
        if(this.is_most_num_include[idx]){
          return "포함"
        }else 
          return "미포함"
      },
      computedSum(idx) {
        console.log(" == sum == " + this.sum);
        if(this.sum[idx] > this.num_min[idx] && this.sum[idx] < this.num_max[idx]){
          return "분포 내 위치합니다.";
        }
        else{
          return "분포 내 위치하지 않아요";
        } 
      },
      removeAll: function() { 
          localStorage.clear();
          this.lotto_infos = [];
          alert('초기화 완료!');
      },
      change_ball_color: function(){ 
          var color = 0;
          console.log("change_ball_color = " + this.lotto_infos.length + "/" + this.lotto_infos[0].length);
          for(var i = 0 ; i < this.lotto_infos.length; i++){ 
            for(var j = 0 ; j < this.lotto_infos[i].length; j++){ 
                var num = this.lotto_infos[i][j];
                if(num < 10){
                      // this.balls_num.
                      color = "#ffd400";
                }else if(num < 20){ 
                    color = "#00d4ff";
                }else if(num < 30){ 
                    color = "#FF5a5a";
                }else if(num < 40){ 
                    color = "#808080";
                }else{ 
                    color = "#92DF45";
                }
                this.ball_color[i*5+j] = color;
                console.log("[" + i + "/" + j + "] return color = " + this.ball_color[i*5+j]);
            }
          }
          //#ffd400 1 - 9
          //#00d4ff 10 -19 
          //#FF5a5a 20 - 29 
          //#808080 30 - 39 
          //#92DF45 40 -45
  
      },
      computedBgColor: function(idx, in_idx) { 
        console.log("change = " + this.ball_color[in_idx] + "/" + idx + "," + in_idx);
        return this.ball_color[idx * 5 + in_idx];
      }
  },
  computed: {
   
  }
};  
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
li { 
    display: inline-table;
    min-height: 10px;
    line-height: 20px;
    margin: 0.5rem 0;
    padding: 0 0.4rem;
    background: white;
    border-radius: 10px;
}
.ball {
    background-color: #ffd400;
    width: 35px;
    height: 35px;
    margin-left: 10px;
    font-weight: bold;
    text-align: center;
    line-height: 30px;
    border-radius: 60%;
    color : white;
}
</style>
