<template>
  <div>
    <!-- <Adsense
        data-ad-client="ca-pub-5746542177729335"
        data-ad-slot="3800733658"
        data-ad-format="auto"
        data-full-width-responsive="true"
      >
      </Adsense> -->
    <h1>Web Projects</h1>
    <v-card
      v-for="(item, index) in slider_web_prj"
      class="mx-auto pa-5 ma-5"
      max-width="800"
      :key="item.idx"
      outlined
    >
      <v-list-item three-line>
        <v-list-item-content>
          <div class="text-h5 mb-4">
            {{ item.title }}
          </div>
          <v-list-item-title class="text-h7 mb-1">
            {{ item.subtitle }}
          </v-list-item-title>
          <v-list-item-subtitle>
            {{ item.description }}
          </v-list-item-subtitle>
        </v-list-item-content>

        <v-list-item-avatar tile :size="getsize" :width="getwidth">
          <v-img :src="item.icon"></v-img>
        </v-list-item-avatar>
      </v-list-item>
      <v-card-actions>
        <v-btn
          class="ml-2 mt-5"
          outlined
          rounded
          small
          @click="goto_web(index)"
        >
          Go site
        </v-btn>
        <!-- <v-btn
          class="ml-2 mt-5"
          outlined
          rounded
          small
          @click="goto_board(0, index)"
        >
          Board
        </v-btn>
        <v-btn
          class="ml-2 mt-5"
          outlined
          rounded
          small
          @click="goto_faq(0, index)"
        >
          FaQ
        </v-btn> -->
      </v-card-actions>
    </v-card>
    <h1>Android Projects</h1>
    <v-card
      v-for="(item, index) in slider_and_prj"
      class="mx-auto pa-5 ma-5"
      max-width="800"
      :key="item.idx"
      outlined
    >
      <v-list-item three-line>
        <v-list-item-content>
          <div class="text-h5 mb-4">
            {{ item.title }}
          </div>
          <v-list-item-title class="text-h7 mb-1">
            {{ item.subtitle }}
          </v-list-item-title>
          <v-list-item-subtitle>
            {{ item.description }}
          </v-list-item-subtitle>
        </v-list-item-content>

        <v-list-item-avatar
          tile
          :size="getsize"
          :height="getheight"
          :width="getwidth"
        >
          <v-img :src="item.icon"></v-img>
        </v-list-item-avatar>
      </v-list-item>
      <v-card-actions>
        <v-btn
          class="ml-2 mt-5"
          outlined
          rounded
          small
          @click="goto_and(index)"
        >
          Download
        </v-btn>
        <v-btn
          class="ml-2 mt-5"
          outlined
          rounded
          small
          @click="goto_board(1, index)"
        >
          Board
        </v-btn>
        <v-btn
          class="ml-2 mt-5"
          outlined
          rounded
          small
          @click="goto_faq(1, index)"
        >
          FaQ
        </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>
<!-- <script
  async
  src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-5746542177729335"
  crossorigin="anonymous"
></script> -->
<script>
export default {
  components: {},
  data() {
    return {
      items: [
        {
          src: "https://cdn.vuetifyjs.com/images/carousel/squirrel.jpg",
        },
        {
          src: "https://cdn.vuetifyjs.com/images/carousel/sky.jpg",
        },
        {
          src: "https://cdn.vuetifyjs.com/images/carousel/bird.jpg",
        },
        {
          src: "https://cdn.vuetifyjs.com/images/carousel/planet.jpg",
        },
      ],
      slider: [
        "red",
        "green",
        "orange",
        "blue",
        "pink",
        "purple",
        "indigo",
        "cyan",
        "deep-purple",
        "light-green",
        "deep-orange",
        "blue-grey",
      ],
      slider_web_prj: [
        {
          title: "MBTing",
          subtitle: "MBTI로 소통해볼까요",
          description:
            "MBTI 궁합 보기 / 연예인과 MBTI 궁합 보기 / MBTI 커뮤니티",
          button: "사이트 가기",
          idx: 0,
          icon: require("../assets/mbting_logo.jpeg"),
          color: "131313",
        },
        {
          title: "Let's Lotto",
          subtitle: "로또 1등 당첨되고 싶은 사람 손!!!",
          description:
            "로또 자동 추첨 / 고정 추첨 / 당첨 시뮬레이션 / 로또 커뮤니티",
          button: "사이트 가기",
          idx: 1,
          icon: require("../assets/lotto_logo.png"),
          color: "131313",
        },
        {
          title: "Askme",
          subtitle: "나만의 게시판을 만들어 보세요!",
          description:
            "친구에게 익명으로 질문을 남겨보고, 답글도 남겨보세요 :)",
          button: "사이트 가기",
          idx: 2,
          icon: require("../assets/askme_sample.png"),
          color: "131313",
        },
      ],
      slider_and_prj: [
        {
          title: "Alldroid",
          subtitle: "범용(General) 이어폰 유틸리티",
          description:
            "모든 범용(General) 이어폰의 기능들을 확장 시킬 수 있는 유틸리티",
          button: "다운로드",
          idx: 3,
          icon: require("../assets/alldroid_logo.png"),
        },
        {
          title: "Dotdroid",
          subtitle: " 샤오미 이어폰 유틸리티",
          description: " 샤오미 이어폰의 기능들을 확장 시킬 수 있는 유틸리티",
          button: "다운로드",
          idx: 4,
          icon: require("../assets/dotdroid_logo.png"),
        },
        {
          title: "Qdroid",
          subtitle: " QCY 전용 이어폰 유틸리티",
          description: " QCY 관련 이어폰의 기능들을 확장 시킬 수 있는 유틸리티",
          button: "다운로드",
          idx: 5,
          icon: require("../assets/qdroid_logo.png"),
        },
        {
          title: "WonderPod",
          subtitle: "에어팟 전용 이어폰 유틸리티",
          description: "에어팟 이어폰의 기능들을 확장 시킬 수 있는 유틸리티",
          button: "다운로드",
          idx: 6,
          icon: require("../assets/wonderpod.png"),
        },
      ],
    };
  },
  computed: {
    columns() {
      if (this.$vuetify.breakpoint.xl) {
        return 4;
      }
      if (this.$vuetify.breakpoint.lg) {
        return 3;
      }
      if (this.$vuetify.breakpoint.md) {
        return 2;
      }
      return 1;
    },
    getwidth() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 100;
        case "sm":
          return 200;
        case "md":
          return 280;
        case "lg":
          return 310;
        case "xl":
          return 340;
      }
      return 30;
    },
    getheight() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 100;
        case "sm":
          return 200;
        case "md":
          return 280;
        case "lg":
          return 310;
        case "xl":
          return 340;
      }
      return 30;
    },
    getsize() {
      // alert(this.$vuetify.breakpoint.name)
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 50;
        case "sm":
          return 100;
        case "md":
          return 150;
        case "lg":
          return 180;
        case "xl":
          return 210;
      }
      return 30;
    },
  },
  methods: {
    download_dotdroid: function () {
      window.open("https://play.google.com/store/search?q=dotdroid&c=apps");
    },
    download_qdroid: function () {
      window.open(
        "https://play.google.com/store/apps/details?id=com.qcdroid.android.qcdroid"
      );
    },
    download_alldroid: function () {
      window.open("https://play.google.com/store/search?q=alldroid&c=apps");
    },
    goto_web: function (index) {
      // alert("goto web" + index);
      if (index == 0) {
        window.open("https://mbting.conpurple.com");
      } else if (index == 1) {
        window.open("https://lotto.conpurple.com");
      } else if (index == 2) {
        window.open("https://askme.conpurple.com");
      }
    },
    goto_and: function (index) {
      // alert("goto web" + index);
      if (index == 0) {
        window.open("https://play.google.com/store/search?q=alldroid&c=apps");
      } else if (index == 1) {
        window.open("https://play.google.com/store/search?q=dotdroid&c=apps");
      } else if (index == 2) {
        window.open("https://play.google.com/store/search?q=qdroid&c=apps");
      } else if (index == 3) {
        window.open("https://play.google.com/store/search?q=wonderpod&c=apps");
      }
    },
    goto_faq: function (category, index) {
      // alert(category + "," + index)
      var router = this.$router;
      router.push({
        name: "/Faq",
        params: {
          category: index + 1,
          // "reply" : this.reply,
        },
      });
    },
    goto_board: function (category, index) {
      // alert(category + "," + index);
      var router = this.$router;
      router.push({
        name: "/Board",
        params: {
          category: index + 1,
          // "reply" : this.reply,
        },
      });
    },
  },
};
</script>
