import axios from "axios";

const BASE_URL = "https://askme.conpurple.com";
const ACCESS_TOKEN = "access_token";
const REFRESH_TOKEN = "refresh_token";

function getAuthUserInfo(userid) {
  return tokenRequest
    .get("/api/logincheck/?userid=" + userid)
    .then((response) => {
      // return Promise.resolve(response.data.items[0].fields)
      return response;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

function getUserIdFromToken(token) {
  var url = token.split(".")[1];
  var base64 = url.replace(/-/g, "+").replace(/_/g, "/");
  var jsonPayload = decodeURIComponent(
    atob(base64)
      .split("")
      .map(function (c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join("")
  );
  var userid = JSON.parse(jsonPayload).name;
  return userid;
}

const loginUser = (userid, password) => {
  const loginBody = { userid: userid, password: password };
  return tokenRequest
    .post("/api/login/", loginBody)
    .then((response) => {
      window.localStorage.setItem(ACCESS_TOKEN, response.data.access);
      window.localStorage.setItem(REFRESH_TOKEN, response.data.refresh);
      axios.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${window.localStorage.getItem(ACCESS_TOKEN)}`;
      
      return Promise.resolve(authVerifyToken());
    })
    .catch((error) => {
      // alert(error)
      return Promise.reject(error);
    });
};

const logoutUser = () => {
  window.localStorage.removeItem(ACCESS_TOKEN);
  window.localStorage.removeItem(REFRESH_TOKEN);
  authRequest.defaults.headers.Authorization = "";
  return Promise.resolve();
};

const refreshToken = () => {
  const refreshBody = { refresh: window.localStorage.getItem(REFRESH_TOKEN) };
  return tokenRequest
    .post("/api/token-refresh/", refreshBody)
    .then((response) => {
      // console.log(response.data.access);
      window.localStorage.setItem(ACCESS_TOKEN, response.data.access);
      return Promise.resolve(authVerifyToken());
    })
    .catch((error) => Promise.reject(error));
};

function authVerifyToken() {
  let access_token = localStorage.getItem(ACCESS_TOKEN);
  if (access_token == null) {
    return Promise.reject();
  }
  
  return tokenRequest
    .post("/api/token-verify/", { token: access_token })
    .then((response) => {
      if (response.status == 200) {
        axios.defaults.headers.common[
          "Authorization"
        ] = `Bearer ${window.localStorage.getItem(ACCESS_TOKEN)}`;
        var userid = getUserIdFromToken(access_token);
        return Promise.resolve(getAuthUserInfo(userid));
      } else {
        logoutUser();
        return Promise.reject();
      }
    })
    .catch(() => {
      return refreshToken();
    });
}

const authRequest = axios.create({
  baseURL: BASE_URL,
  timeout: 5000,
  headers: {
    Authorization: `Bearer ${window.localStorage.getItem(ACCESS_TOKEN)}`,
    "Content-Type": "application/json",
  },
});

const tokenRequest = axios.create({
  baseURL: BASE_URL,
  timeout: 5000,
  headers: {
    "Content-Type": "application/json",
    accept: "application/json",
  },
});

export {
  loginUser,
  logoutUser,
  refreshToken,
  authVerifyToken,
  authRequest,
  BASE_URL,
  ACCESS_TOKEN,
  REFRESH_TOKEN,
};
