<template>
  <v-container fluid>
    <!-- <Adsense
      data-ad-client="ca-pub-5746542177729335"
      data-ad-slot="3800733658"
      data-ad-format="auto"
      data-full-width-responsive="true"
    >
    </Adsense> -->
    <v-row>
      <v-col cols="12" sm="12" md="6" offset-md="3">
        <v-card class="mt-2">
          <v-row justify="center">
            <v-btn
              v-scroll="onScroll"
              v-show="fab"
              fab
              dark
              fixed
              bottom
              right
              color="primary"
              @click="toTop"
            >
              위로
            </v-btn>
          </v-row>
        </v-card>
        <v-card>
          <v-row justify="center">
            <v-card-text
              class="my-4 text-center "
              >친구에게 익명으로 질문을 남겨보세요. <br />
              회원 가입을 하면 나만의 게시판을 만들어 질문을 받을 수 있어요. :)
            </v-card-text>
          </v-row>
          <v-row justify="center">
            <v-btn
              v-if="isLogin"
              outlined
              rounded
              text
              class="ml-2 mt-2 mb-2"
              @click="gotoMyBoard()"
            >
              나만의 게시판으로 이동하기
            </v-btn>
            <v-col v-else cols="8" sm="8" md="8">
              <v-text-field
                label="계정명"
                placeholder="계정명을 입력해주세요."
                v-model="input_account"
                v-on:keyup.enter="gotoMyBoard(input_account)"
              ></v-text-field>
              <v-btn
                outlined
                rounded
                text
                class="ml-2 mt-2 mb-2"
                @click="gotoMyBoard(input_account)"
              >
                이동하기
              </v-btn>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<!-- <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-5746542177729335"
     crossorigin="anonymous"></script> -->
<script>
import axios from "axios";
import { mapGetters, mapState } from "vuex";
let logincheckurl = "api/logincheck/";

export default {
  name: "AskmeMain",
  data: () => ({
    fab: false,
    input_account: "",
  }),
  created() {},
  mounted() {
    if (this.$route.path !== "/") {
      this.$router.replace("/");
    }
  },
  computed: {
    ...mapState("askmeStore", ["isLoggedIn", "userid"]),
    ...mapGetters("askmeStore", ["isLogin", "getUserInfo"]),
  },
  methods: {
    onScroll(e) {
      if (typeof window === "undefined") return;
      const top = window.pageYOffset || e.target.scrollTop || 0;
      this.fab = top > 20;
    },
    toTop() {
      this.$vuetify.goTo(0);
    },
    makeMyBoard: function () {
      if (this.isLogin) {
        alert("userid: " + this.getUserInfo.userid);
      }
    },
    gotoMyBoard: function (account) {
      if (!account) {
        if (this.isLogin) {
          account = this.getUserInfo.userid;
        } else {
          alert("존재하지 않는 계정입니다.");
          return false;
        }
      }
      return axios
        .get(logincheckurl + "?userid=" + account, {})
        .then((response) => {
          if (response.data.items.length) {
            this.$router.replace("/" + account);
          } else {
            alert("존재하지 않는 계정입니다.");
            return false;
          }
        });
    },
  },
};
</script>
