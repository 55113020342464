<template>
  <v-app>
    <v-container>
      <!-- <Adsense
        data-ad-client="ca-pub-5746542177729335"
        data-ad-slot="3800733658"
        data-ad-format="auto"
        data-full-width-responsive="true"
      >
      </Adsense> -->
      <h3>
        나의 최고 등수 : <font color="red">{{ my_num }}등</font>
      </h3>
      <p>이정도면, 자랑할만 하네요 :)</p>
      <v-btn class="ma-2" outlined rounded text @click="gotcha_start">
        공유/인증하기
      </v-btn>
      <v-card class="mx-auto pa-10 ma-10" max-width="600px" outlined>
        <p class="p_title">
          가장 최근 로또 번호에 대해서 무제한 뽑기를 수행하며 당신의 운을 체크해
          보세요!
        </p>
        <p>
          <strong>{{ lotto_round[0][0] }} 회차 번호 </strong>({{ date }})
        </p>
        <div>
          <v-row>
            <v-col cols="2">
              <div
                v-bind:style="{
                  backgroundColor: computedBgColor(lotto_num[0][0]),
                }"
                class="ball"
              >
                {{ lotto_num[0][0] }}
              </div>
            </v-col>
            <v-col cols="2">
              <div
                v-bind:style="{
                  backgroundColor: computedBgColor(lotto_num[0][1]),
                }"
                class="ball"
              >
                {{ lotto_num[0][1] }}
              </div>
            </v-col>
            <v-col cols="2">
              <div
                v-bind:style="{
                  backgroundColor: computedBgColor(lotto_num[0][2]),
                }"
                class="ball"
              >
                {{ lotto_num[0][2] }}
              </div>
            </v-col>
            <v-col cols="2">
              <div
                v-bind:style="{
                  backgroundColor: computedBgColor(lotto_num[0][3]),
                }"
                class="ball"
              >
                {{ lotto_num[0][3] }}
              </div>
            </v-col>
            <v-col cols="2">
              <div
                v-bind:style="{
                  backgroundColor: computedBgColor(lotto_num[0][4]),
                }"
                class="ball"
              >
                {{ lotto_num[0][4] }}
              </div>
            </v-col>
            <p>
              <br />
              +
            </p>
            <v-col cols="1">
              <div
                v-bind:style="{
                  backgroundColor: computedBgColor(lotto_num[0][4]),
                }"
                class="ball"
              >
                {{ lotto_num[0][5] }}
              </div>
            </v-col>
          </v-row>
          <div v-if="is_start">
            <v-btn class="ma-2" outlined rounded text @click="gotcha_stop">
              시뮬레이션 중지
            </v-btn>
          </div>
          <div v-else>
            <v-btn class="ma-2" outlined rounded text @click="gotcha_start">
              시뮬레이션 시작
            </v-btn>
          </div>
        </div>
      </v-card>
    </v-container>
    <div>
      <!-- 
        <h3> 실시간 채팅 </h3> 
        <ul> 
            <li v-for="(msg, index) in messages" :key="index">
                {{msg.message}}
            </li>
        <ul>
        <div>
            <input type="text" @keyup.enter="send_message()" v-model="message">
        </div> -->

      <!--
            1.  
            아니면, 체크박스로 몇회부터 몇회까지 선택하고
            -> 이건 장고에서 받아와야 겠네 데이터 
            내가 뽑은 데이터들이 최대 몇등까지 하는지 확인해보기!

            2. 
            1002회 당첨 번호 10, 20, 30, 40, 50
            시작-> 디리리리리리
        -->

      <p>
        <strong>{{ cnt }}</strong> 게임 중
        <br />
      </p>
      <hr />

      <!-- <p v-for="item in gotcha_result" v-bind:key="item.id"> 
        {{ item }} 
        </p> -->
      <p>1등 당첨 : {{ first_prize }} 번 ( {{ fir_per }}%)</p>
      <p>2등 당첨 : {{ second_prize }} 번 ( {{ sec_per }}%)</p>
      <p>3등 당첨 : {{ third_prize }} 번 ( {{ third_per }}%)</p>
      <p>4등 당첨 : {{ fourth_prize }} 번 ( {{ fourth_per }}%)</p>
      <p>5등 당첨 : {{ fifth_prize }} 번 ( {{ fifth_per }}%)</p>
      <p>낙첨 : {{ no_prize }} ( {{ no_per }}%)</p>

      <hr />
      <p><strong> 결과 </strong></p>
      <p />
      <p>
        <strong> 사용한 돈 </strong> {{ get_total_money() | mon_filter }} 원
      </p>
      <p><strong> 상금 </strong>{{ get_prized_money() | mon_filter }} 원</p>
      <p><strong> 결과 </strong> {{ get_result_money() | mon_filter }} 원</p>

      <hr />

      <!-- <p v-else>

        </p> -->
      <!-- localStorage 를 통해 나의 로또 번호 가져오기 -->
    </div>
  </v-app>
</template>
<!-- <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-5746542177729335"
     crossorigin="anonymous"></script> -->
<script>
import axios from 'axios'

// import io from 'socket.io-client'
export default {
  data() {
    return {
      // for chatting
      // messages : [],
      // // for connecting server
      // socket: io('localhost:3001'),

      // for simulation
      my_num: 0,
      cnt: 0,
      gotcha_result: [],
      lotto_num: [],
      lotto_bonus_num: [],
      lotto_round: [],
      date: "",
      my_lotto_num: [],
      is_start: false,
      /* prize cnt */
      first_prize: 0,
      second_prize: 0,
      third_prize: 0,
      fourth_prize: 0,
      fifth_prize: 0,
      no_prize: 0,
      /* prize per */
      fir_per: 0,
      sec_per: 0,
      third_per: 0,
      fourth_per: 0,
      fifth_per: 0,
      no_per: 0,
      total_money: 0,
      prized_money: 0,
      // total_money: 0,
    };
  },
  filters: {
    mon_filter: function (value) {
      return String(value).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
  },
  methods: {
    // send_message: function(message) {
    //     this.socket.emit("SEND_MESSAGE",{message});
    // },
    computedBgColor: function (num) {
      var color = "#ffffff";
      // console.log("num = " + num);
      if (num < 10) {
        // this.balls_num.
        color = "#ffd400";
      } else if (num < 20) {
        color = "#00d4ff";
      } else if (num < 30) {
        color = "#FF5a5a";
      } else if (num < 40) {
        color = "#808080";
      } else {
        color = "#92DF45";
      }
      return color;
    },
    get_total_money: function () {
      this.total_money = this.cnt * 1000;
      return this.total_money;
    },
    get_prized_money: function () {
      /* add 5 */
      var tot = this.fifth_prize * 1000;
      tot += this.fourth_prize * 50000;
      tot += this.third_prize * 1500000;
      tot += this.second_prize * 50000000;
      tot += this.first_prize * 2000000000;
      this.prized_money = tot;
      return this.prized_money;
    },
    get_result_money: function () {
      // return 100;
      return this.prized_money - this.total_money;
    },

    gotcha_stop: function () {
      this.is_start = false;
    },
    computed_win: function (local_lotto) {
      // console.log(this.lotto_num[0]);

      var match_cnt = 0;
      var bmatch = false;
      for (var i = 0; i < local_lotto.length; i++) {
        /* 회차의 번호를 서칭 */
        for (var j = 0; j < this.lotto_num[0].length; j++) {
          if (local_lotto[i] == this.lotto_num[0][j]) {
            // console.log("match====" + local_lotto[i]);
            match_cnt++;
          }
          if (local_lotto[i] == this.lotto_bonus_num[0][0]) {
            /* bonus numer 매치도 확인 해줘야지 */
            bmatch = true;
          }
        }
      }
      if (match_cnt == 6) {
        // return 1;
        this.first_prize++;
      } else if (match_cnt == 5 && bmatch) {
        // return 2;
        this.second_prize++;
      } else if (match_cnt == 5 && !bmatch) {
        // return 3;
        this.third_prize++;
      } else if (match_cnt == 4) {
        // return 4;
        this.fourth_prize++;
      } else if (match_cnt == 3) {
        // return 5;
        this.fifth_prize++;
      } else {
        // return -1;
        this.no_prize++;
      }
    },
    calc_percentage: function () {
      /* 확률 게산 */
      this.fir_per = ((this.first_prize / this.cnt) * 100).toFixed(2);
      this.sec_per = ((this.second_prize / this.cnt) * 100).toFixed(2);
      this.third_per = ((this.third_prize / this.cnt) * 100).toFixed(2);
      this.fourth_per = ((this.fourth_prize / this.cnt) * 100).toFixed(2);
      this.fifth_per = ((this.fifth_prize / this.cnt) * 100).toFixed(2);
      this.no_per = Math.floor((this.no_prize / this.cnt) * 100);
    },
    gotcha_start: function () {
      if (window.Worker) {
        if (!this.is_start) {
          var worker = new Worker(new URL("../js/worker.js", import.meta.url));
          // var worker = new Worker('src/js/worker.js');

          this.is_start = true;

          worker.onerror = (e) => {
            console.log("error" + e.message);
          };
          worker.onmessage = (e) => {
            this.cnt += 1;
            // console.log(this.cnt + " , " + e.data );
            this.computed_win(e.data);
            // this.gotcha_result.push( e.data );
            this.calc_percentage();
            if (this.is_start == false) {
              console.log("TERMINATE");
              worker.terminate();
              // this.gotcha_result = [];
              this.my_num = 0;
              if (this.first_prize) this.my_num = 1;
              else if (this.second_prize) this.my_num = 2;
              else if (this.third_prize) this.my_num = 3;
              else if (this.fourth_prize) this.my_num = 4;
              else if (this.fifth_prize) this.my_num = 5;
              var prev_num = localStorage.getItem("my_num");
              if (prev_num == null) {
                localStorage.setItem("my_num", this.my_num);
              } else if (this.my_num != 0 && prev_num >= this.my_num) {
                localStorage.setItem("my_num", this.my_num);
              }
              /* TODO 
                            
                                    <p><strong> 사용한 돈 </strong> {{get_total_money() | mon_filter}} 원 </p>
        <p><strong> 상금 </strong>{{get_prized_money() | mon_filter}} 원 </p>
        <p><strong> 결과 </strong> {{get_result_money() | mon_filter}} 원 </p>*/
              // var my_total_money = localStorage.getItem("my_total_money") ;
              // my_total_money += this.get_total_money();
              // localStorage.setItem("my_total_money", my_total_money);
              // var my_prize_money =
            }
          };
        }
      } else {
        alert("미지원 브라우저입니다!");
      }

      if (window.Worker) {
        return;
      } else {
        return;
      }
      // while(true){
      //     (async()=>{
      //         this.cnt++;
      //     })

      // }
    },
    computedPrize: function (idx) {
      // console.log("DD: " + idx);
      /* idx 번째의 localStorage의 로또 번호를 구하고,
            그 번호가 매칭되는 걸 확인한다 */
      var lotto_num_idx = this.my_lotto_num[idx];
      var is_num_match = [0, 0, 0, 0, 0, 0];
      var is_bonus_match = 0;
      var cnt = 0;
      for (var i = 0; i < lotto_num_idx.length; i++) {
        /* idx의 숫자를 탐색 */
        console.log(" total nu = " + lotto_num_idx[i]);

        // var ret = this.lotto_num[0].includes(lotto_num_idx[i],0);
        // console.log(this.lotto_num + " includes : " + lotto_num_idx[i] + " : " + ret);
        for (var j = 0; j < this.lotto_num[0].length; j++) {
          var target_num = this.lotto_num[0][j];
          console.log(" bb = " + target_num);
          if (lotto_num_idx[i] == target_num) {
            is_num_match[i] = 1;
            console.log("match");
          }
          if (lotto_num_idx[i] == this.lotto_bonus_num[0][0]) {
            is_bonus_match = 1;
          }
        }
        /* TODO : bodnus checking */
        // for()
      }
      for (var k = 0; k < 6; k++) {
        if (is_num_match[k] == 1) cnt++;
      }
      if (cnt == 6) {
        return "1등";
      } else if (cnt == 5 && is_bonus_match) {
        return "2등";
      } else if (cnt == 5 && !is_bonus_match) {
        return "3등";
      } else if (cnt == 4) {
        return "4등";
      } else if (cnt == 3) {
        return "5등";
      } else {
        return "낙첨";
      }
    },
  },
  // mounted: function() {
  //     this.socket.on("MESSAGE", (data)=>{
  //         this.messages = [...this.messages, data];
  //     });
  // },
  beforeMount: function () {
    /*  일단은 기준 날짜를 구하자 */
    // var base_var = "2020-02-29";
    // var base_arr = base_var.split('-');

    const base_date = new Date(2020, 1, 29);
    var base_round = 900;
    const cur_date = new Date();

    var year = cur_date.getFullYear();
    var month = cur_date.getMonth();
    var day = cur_date.getDay();
    this.date = year + "년 " + month + "월 " + day + "일";

    // console.log("기준 날짜 : " + base_date);
    // console.log("현재 날짜 : " + cur_date) ;
    var diff = cur_date - base_date;
    var diff_day = 24 * 60 * 60 * 1000;
    // var diff_month = diff_day * 30 ;
    // var diff_year = diff_month * 12;

    var remain_day = parseInt(diff / diff_day);
    console.log("일 : " + parseInt(diff / diff_day));

    var cnt = remain_day / 7;
    console.log("cnt = " + cnt);
    var cur_round = parseInt(base_round + cnt);
    console.log("cur round = " + cur_round);

    var temp = [];
    var temp_bn = [];
    var temp_round = [];

    if (localStorage.getItem(cur_round)) {
      console.log("DONT NEED TO QUERY");
      var num_cache = localStorage.getItem(cur_round);
      num_cache = num_cache.split(",");
      for (i = 0; i < num_cache.length; i++) {
        console.log("num = " + num_cache[i]);
        if (i <= num_cache.length - 2) {
          temp.push(num_cache[i]);
        } else {
          temp_bn.push(num_cache[i]);
        }
        temp_round.push(cur_round);
      }
    } else {
      axios.defaults.headers.common["Content-Type"] =
        "application/x-www-form-urlencoded";
      axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
      var query_str =
        "/dhlottery/common.do?method=getLottoNumber&drwNo=" + cur_round;
      axios.get(query_str).then(function (response) {
        console.log(response);
        temp.push(response.data.drwtNo1);
        temp.push(response.data.drwtNo2);
        temp.push(response.data.drwtNo3);
        temp.push(response.data.drwtNo4);
        temp.push(response.data.drwtNo5);
        temp.push(response.data.drwtNo6);
        temp_bn.push(response.data.bnusNo);
        temp_round.push(response.data.drwNo);
        // console.log(temp_round);
        // alert(temp_round);
      });
    }
    this.lotto_num.push(temp);
    this.lotto_bonus_num.push(temp_bn);
    this.lotto_round.push(temp_round);
    // alert(this.lotto_round);
    // this.a = 10;
    /* component 가 로딩되고 렌더링 되기 전에 최신 로또 번호를 가져와야 된다 */
    this.my_num = localStorage.getItem("my_num");
    for (var i = 0; i < 5; i++) {
      var lotto_num = localStorage.getItem(i);
      if (lotto_num == null) return;
      console.log(lotto_num);
      // alert(lotto_num)
      var split_lotto_num = lotto_num.split(",");
      // console.log("THIS: " + split_lotto_num);
      this.my_lotto_num.push(split_lotto_num);
    }
  },
};
</script>
<style scoped>
.ball {
  background-color: #ffd400;
  width: 30px;
  height: 30px;
  margin-left: 2px;
  font-weight: bold;
  line-height: 30px;
  border-radius: 50%;
  color: white;
  display: inline-block;
}
.bonus_ball {
  background-color: #ff22ff;
  width: 30px;
  height: 30px;
  margin-left: 2px;
  font-weight: bold;
  line-height: 30px;
  border-radius: 50%;
  color: white;
  display: inline-block;
}
</style>
