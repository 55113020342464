
<template>
    <!-- <v-container fluid> -->
          <router-view
            v-bind:propsdata="boardList"
            v-on:saved="getBoardList"
            v-on:deleted="getBoardList"
            v-on:patched="getBoardList"
        ></router-view>
    <!-- </v-container> -->
</template>
<!-- <script
  async
  src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-5746542177729335"
  crossorigin="anonymous"
></script> -->
<script>
import axios from "axios";
let boardurl = "api/board/";

export default {
    name: "MbtiContent",
    data: () => ({
        boardList: [],
    }),
    mounted() {
        axios({
            method: "GET",
            url: boardurl
        })
            .then(response => {
                this.boardList = response.data.results;
            })
            .catch(response => {
                console.log("axios error: ", response);
            });
    },
    methods: {
        getBoardList: function () {
            axios({
                method: "GET",
                url: boardurl
            })
                .then(response => {
                    this.boardList = response.data.results;
                })
                .catch(response => {
                    console.log("axios error: ", response);
                });
        },
    }
}
</script>