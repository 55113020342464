<template>
  <div>
    <!-- <Adsense
        data-ad-client="ca-pub-5746542177729335"
        data-ad-slot="3800733658"
        data-ad-format="auto"
        data-full-width-responsive="true"
      >
      </Adsense> -->
    <v-card
      class="mx-auto pa-10 ma-12"
      max-width="600px"
      outlined
      v-for="(member, idx) in members"
      :key="member.key"
    >
      <v-list-item three-line>
        <v-list-item-content>
          <div class="text-overline mb-4">L2P Member #{{ idx + 1 }}</div>
          <v-list-item-title class="text-h5 mb-1">
            {{ member.name }}
          </v-list-item-title>
          <v-list-item-subtitle>{{ member.major }}</v-list-item-subtitle>
          <br />
          <br />
          <v-list-item-subtitle>{{ member.title }}</v-list-item-subtitle>
        </v-list-item-content>

        <v-list-item-avatar tile size="80" color="grey">
          <v-img :src="member.avatar"></v-img>
        </v-list-item-avatar>
      </v-list-item>

      <v-card-actions>
        <v-btn outlined rounded text>
          <v-icon dark @click="insta_click(member.instagram_url)">
            mdi-instagram
          </v-icon>
        </v-btn>
        <v-btn outlined rounded text>
          <v-icon dark> mdi-facebook </v-icon>
        </v-btn>
      </v-card-actions>
    </v-card>

    <v-img class="mx-auto" max-width="100px" :src="bitcoin"></v-img>
    <p class="pa-2">
      <strong> Bitcoin Wallets for Sponsorship </strong> <br />
      Sponsorship makes us work harder :)
    </p>
  </div>
</template>
<!-- <script
  async
  src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-5746542177729335"
  crossorigin="anonymous"
></script> -->
<script>
export default {
  data: () => ({
    bitcoin: require("../assets/bitcoin_support.jpeg"),

    members: [
      {
        avatar: require("../assets/profile/kunu_main.jpeg"),
        name: "Keonwoo Lee",
        major: "Embedded Engineer",
        title: "누구보다 빠른, 남들과는 다른 개발자",
        excerpt: "L2P 많이 응원해주세용 ㅎㅎ",
        instagram_url: "https://www.instagram.com/kunulee",
      },
      {
        avatar: require("../assets/profile/dongjin_main.jpeg"),
        name: "Dongjin Park",
        major: "Embedded Engineer",
        title: "행복한 우주먼지 개발자",
        excerpt: "L2P 많이 응원해주세용 ㅎㅎ",
        instagram_url: "https://www.instagram.com/djpark386/",
      },
      {
        avatar: require("../assets/profile/changguen_main.jpeg"),
        name: "Changgeun Lee",
        major: "Embedded Engineer",
        title: "적게 일하고 많이 벌어서 평생 놀고 먹자",
        excerpt: "L2P 많이 응원해주세용 ㅎㅎ",
        instagram_url: "https://www.instagram.com/changgeun_89",
      },
    ],
  }),
  methods: {
    insta_click: function (insta) {
      // alert(insta);
      if (insta != null) {
        window.open(insta);
      }
    },
  },
};
</script>
