<template>
    <!-- <v-container fluid> -->
          <router-view
        ></router-view>
    <!-- </v-container> -->
</template>
<!-- <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-5746542177729335"
     crossorigin="anonymous"></script> -->
<script> 

export default {
    name: "AskmeContent",
    data: () => ({
    }),
    mounted() {
    },
    methods: {
    }
}
</script>