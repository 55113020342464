<template>
  <v-app>
    <header>
      <v-app-bar color="white" app>
        <!-- <v-row >
           <v-col cols="12" sm="12">  -->
        <v-spacer></v-spacer>
        <router-link to="/">
          <v-img
            class="mx-auto"
            style="display: block; margin: 0 auto"
            :src="require('@/assets/mbting-logo.jpg')"
            height="50"
            width="140"
            contain
          ></v-img>
        </router-link>
        <v-spacer></v-spacer>
        <v-dialog
          v-if="!isLoggedIn"
          v-model="accountDialog"
          persistent
          max-width="300px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              v-bind="attrs"
              v-on="on"
              color="grey darken-3"
              style="cursor: pointer"
              class="mr-2"
              >mdi-account</v-icon
            >
          </template>
          <v-card>
            <v-card-title>
              <span class="text-h6">계정 관리</span>
              <v-spacer></v-spacer>
              <v-btn icon @click="closeLoginForm()">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-card-title>
            <!-- ID, PW1/2, E-MAIL, -->
            <v-card-text>
              <v-row justify="center">
                <v-col>
                  <v-text-field
                    v-model="loginId"
                    :counter="20"
                    :rules="idrules"
                    label="아이디"
                    outlined
                    required
                    autofocus
                    prepend-inner-icon="mdi-account"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-text-field
                    v-model="loginPassword"
                    :counter="20"
                    :rules="passwordrules"
                    type="password"
                    label="비밀번호"
                    outlined
                    required
                    prepend-inner-icon="mdi-lock"
                    v-on:keyup.enter="loginFormTimeoutDialog = true"
                    :disabled="loginFormTimeoutDialog"
                    :loading="loginFormTimeoutDialog"
                  >
                  </v-text-field>
                </v-col>
              </v-row>
              <v-row justify="center" align="center">
                <v-btn
                  outlined
                  rounded
                  text
                  v-on:click="loginFormTimeoutDialog = true"
                  :disabled="loginFormTimeoutDialog"
                  :loading="loginFormTimeoutDialog"
                  >로그인</v-btn
                >
                <v-dialog
                  v-model="loginFormTimeoutDialog"
                  hide-overlay
                  persistent
                  width="300"
                >
                  <v-card color="green" dark>
                    <v-card-text>
                      Wating...
                      <v-progress-linear
                        indeterminate
                        color="white"
                        class="mb-0"
                      ></v-progress-linear>
                    </v-card-text>
                  </v-card>
                </v-dialog>
                <v-btn
                  outlined
                  rounded
                  text
                  class="ml-2"
                  @click="registerCheck()"
                  >회원 가입</v-btn
                >
              </v-row>
              <!-- <v-divider></v-divider> -->
              <!-- <v-row justify="center">
                <v-btn
                  outlined
                  rounded
                  text
                  class="yellow lighten-1 mt-2"
                  @click="kakaoLogin"
                  >카카오톡 로그인</v-btn
                >
              </v-row>
              <v-row justify="center">
                <v-btn
                  rounded
                  text
                  class="green lighten-1 mt-2"
                  @click="googleLogin"
                  >구글 로그인</v-btn
                >
              </v-row> -->
            </v-card-text>
          </v-card>
          <v-dialog
            v-if="!accountDialog"
            v-model="registerFormDialog"
            persistent
            max-width="400px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-bind="attrs"
                v-on="on"
                color="grey darken-3"
                style="cursor: pointer"
                >mdi-account</v-icon
              >
            </template>
            <v-card>
              <v-card-title>
                <span class="text-h6">회원 가입</span>
                <v-spacer></v-spacer>
                <v-btn icon @click="closeRegisterForm()">
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </v-card-title>
              <v-card-text>
                <v-row justify="center">
                  <v-col cols="8">
                    <v-text-field
                      v-model="registerLoginId"
                      :counter="20"
                      :rules="idrules"
                      label="아이디"
                      outlined
                      required
                      prepend-inner-icon="mdi-account"
                      v-on:keyup.enter="registerIdCheck()"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="4">
                    <v-btn
                      v-if="registerLoginIdType != 1"
                      outlined
                      rounded
                      text
                      class="mt-3 red--text"
                      @click="registerIdCheck"
                      >중복 체크
                    </v-btn>
                    <v-btn
                      v-else
                      outlined
                      rounded
                      text
                      class="mt-3 green--text"
                      @click="registerIdCheck"
                      >중복 체크
                    </v-btn>
                  </v-col>
                </v-row>
                <v-row justify="center">
                  <v-col>
                    <v-text-field
                      v-model="registerLoginName"
                      :counter="10"
                      :rules="namerules"
                      label="이름"
                      outlined
                      required
                      prepend-inner-icon="mdi-account-outline"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row justify="center">
                  <v-col>
                    <v-autocomplete
                      :items="items"
                      :rules="selectrules"
                      :search-input.sync="registerLoginInfo.mbtitype"
                      v-model="registerLoginMbti"
                      label="MBTI 타입"
                      outlined
                      required
                      prepend-inner-icon="mdi-heart"
                    ></v-autocomplete>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-text-field
                      v-model="registerLoginPasswd1"
                      :counter="20"
                      :rules="passwordrules"
                      type="password"
                      label="비밀번호"
                      outlined
                      required
                      prepend-inner-icon="mdi-lock"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-text-field
                      v-model="registerLoginPasswd2"
                      :counter="20"
                      :rules="passwordrules"
                      type="password"
                      label="비밀번호 재입력"
                      outlined
                      required
                      prepend-inner-icon="mdi-lock"
                    ></v-text-field>
                    <v-card-text style="color: red">{{
                      this.registerLoginPasswdInfo
                    }}</v-card-text>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-select
                      v-model="registerLoginSex"
                      :items="sexItems"
                      item-text="name"
                      item-value="value"
                      label="성별"
                      outlined
                      prepend-inner-icon="mdi-human-male-female"
                    ></v-select>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-menu
                      ref="menu"
                      v-model="menu"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="date"
                          label="생일"
                          prepend-inner-icon="mdi-calendar"
                          readonly
                          outlined
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="date"
                        :active-picker.sync="activePicker"
                        :max="
                          new Date(
                            Date.now() - new Date().getTimezoneOffset() * 60000
                          )
                            .toISOString()
                            .substr(0, 10)
                        "
                        min="1950-01-01"
                        @change="save"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-btn
                      outlined
                      rounded
                      text
                      class="green lighten-1 ml-1"
                      @click="registerFormTimeoutDialog = true"
                      :disabled="registerFormTimeoutDialog"
                      :loading="registerFormTimeoutDialog"
                    >
                      <!-- <v-icon>mdi-pencil</v-icon> -->
                      등록
                    </v-btn>
                    <v-dialog
                      v-model="registerFormTimeoutDialog"
                      hide-overlay
                      persistent
                      width="300"
                    >
                      <v-card color="green" dark>
                        <v-card-text>
                          Registering...
                          <v-progress-linear
                            indeterminate
                            color="white"
                            class="mb-0"
                          ></v-progress-linear>
                        </v-card-text>
                      </v-card>
                    </v-dialog>
                    <v-btn
                      outlined
                      rounded
                      text
                      class="deep-purple lighten-1 ml-1"
                      @click="closeRegisterForm()"
                    >
                      <!-- <v-icon>mdi-trash-can</v-icon> -->
                      취소
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-dialog>
        </v-dialog>
        <!-- <v-card
          v-if="isLoggedIn"
          outlined
          class="background-color: transparent"
        >
          <v-card-text>
            <span>{{ getUserInfo.userid }}</span>
          </v-card-text>
        </v-card> -->
        <v-menu v-if="isLoggedIn" bottom min-width="200px" rounded offset-y>
          <template v-slot:activator="{ on }">
            <v-btn class="mx-auto" icon x-large v-on="on">
              <v-icon v-on="on" color="grey darken-3" style="cursor: pointer"
                >mdi-account-edit</v-icon
              >
            </v-btn>
          </template>
          <v-card>
            <v-list-item-content class="justify-center">
              <div class="mx-auto text-center">
                <!-- <v-avatar color="brown"> -->
                <h3>{{ getUserInfo.userid }}({{ getUserInfo.name }})</h3>
                <!-- </v-avatar> -->
                <h3>
                  <p class="text-caption mt-1">{{ getUserInfo.mbtitype }}</p>
                </h3>
                <v-divider class="my-3"></v-divider>
                <v-btn
                  depressed
                  rounded
                  text
                  @click="updateAccountInfoDialog = true"
                >
                  정보 변경
                </v-btn>
                <v-divider class="my-3"></v-divider>
                <v-btn
                  depressed
                  rounded
                  text
                  @click="passwordChangeDialog = true"
                >
                  비밀번호 변경
                </v-btn>
                <v-divider class="my-3"></v-divider>
                <v-btn depressed rounded text v-on:click="normallogout($event)">
                  로그아웃
                </v-btn>
              </div>
            </v-list-item-content>
          </v-card>
        </v-menu>
        <v-dialog v-model="passwordChangeDialog" persistent max-width="300px">
          <v-card>
            <v-card-title>
              <span class="text-h6">비밀번호 변경</span>
              <v-spacer></v-spacer>
              <v-btn icon @click="passwordChangeDialog = false">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-card-title>
            <v-card-text>
              <v-row>
                <v-col>
                  <v-text-field
                    v-model="changePasswordCur"
                    :counter="20"
                    :rules="passwordrules"
                    type="password"
                    label="현재 비밀번호"
                    outlined
                    required
                    prepend-inner-icon="mdi-lock"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-text-field
                    v-model="changePassword"
                    :counter="20"
                    :rules="passwordrules"
                    type="password"
                    label="비밀번호"
                    outlined
                    required
                    prepend-inner-icon="mdi-lock"
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row>
                <v-col>
                  <v-text-field
                    v-model="changePassword2"
                    :counter="20"
                    :rules="passwordrules"
                    type="password"
                    label="비밀번호 재입력"
                    outlined
                    required
                    prepend-inner-icon="mdi-lock"
                  ></v-text-field>
                  <v-card-text style="color: red">{{
                    this.changePasswordInfo
                  }}</v-card-text>
                </v-col>
              </v-row>

              <v-row>
                <v-col>
                  <v-btn
                    outlined
                    rounded
                    text
                    class="green lighten-1 ml-1"
                    @click="passwordChangeTimeoutDialog = true"
                  >
                    수정
                  </v-btn>
                  <v-btn
                    outlined
                    rounded
                    text
                    class="deep-purple lighten-1 ml-1"
                    @click="passwordChangeDialog = false"
                  >
                    취소
                  </v-btn>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-dialog>
        <v-dialog
          v-model="passwordChangeTimeoutDialog"
          hide-overlay
          persistent
          width="300"
        >
          <v-card color="green" dark>
            <v-card-text>
              Changing...
              <v-progress-linear
                indeterminate
                color="white"
                class="mb-0"
              ></v-progress-linear>
            </v-card-text>
          </v-card>
        </v-dialog>
        <!-- 정보 수정 시작 -->
        <v-dialog
          v-model="updateAccountInfoDialog"
          persistent
          max-width="300px"
        >
          <v-card>
            <v-card-title>
              <span class="text-h6">회원정보 수정</span>
              <v-spacer></v-spacer>
              <v-btn icon @click="updateAccountInfoDialog = false">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-card-title>
            <v-card-text>
              <v-row justify="center">
                <v-col>
                  <v-text-field
                    v-model="updateAccountInfo.name"
                    :counter="10"
                    :rules="namerules"
                    label="이름"
                    outlined
                    required
                    prepend-inner-icon="mdi-account-outline"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row justify="center">
                <v-col>
                  <v-autocomplete
                    :items="items"
                    :rules="selectrules"
                    :search-input.sync="updateAccountInfo.mbtitype"
                    v-model="updateAccountInfo.mbtitype"
                    label="MBTI 타입"
                    outlined
                    required
                    prepend-inner-icon="mdi-heart"
                  ></v-autocomplete>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-select
                    v-model="updateAccountInfo.sex"
                    :items="sexItems"
                    item-text="name"
                    item-value="value"
                    label="성별"
                    outlined
                    prepend-inner-icon="mdi-human-male-female"
                  ></v-select>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-btn
                    outlined
                    rounded
                    text
                    class="green lighten-1 ml-1"
                    @click="updateAccountInfoTimeoutDialog = true"
                  >
                    수정
                  </v-btn>
                  <v-btn
                    outlined
                    rounded
                    text
                    class="deep-purple lighten-1 ml-1"
                    @click="updateAccountInfoDialog = false"
                  >
                    취소
                  </v-btn>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-dialog>
        <v-dialog
          v-model="updateAccountInfoTimeoutDialog"
          hide-overlay
          persistent
          width="300"
        >
          <v-card color="green" dark>
            <v-card-text>
              Updating...
              <v-progress-linear
                indeterminate
                color="white"
                class="mb-0"
              ></v-progress-linear>
            </v-card-text>
          </v-card>
        </v-dialog>
        <template v-slot:extension>
          <v-tabs v-model="tab" show-arrows centered>
            <v-tab v-for="item in links" :key="item.title" :to="item.link"
              ><p class="font-weight-bold">{{ item.title }}</p></v-tab
            >
          </v-tabs>
        </template>
      </v-app-bar>
    </header>
    <v-main>
      <v-card height="100%">
        <mbti-content></mbti-content>
      </v-card>
    </v-main>
    <v-divider></v-divider>
    <v-footer padless>
      <v-col class="white text-center" cols="12">
        <v-card flat tile width="100%" class="text-center">
          <v-card-text>
            <v-btn
              class="mx-4"
              icon
              :href="`https://conpurple.com:443`"
              target="_blank"
            >
              <v-icon>mdi-home</v-icon>
            </v-btn>
          </v-card-text>

          <!-- <v-divider></v-divider> -->
          <strong
            >Copyright(c) {{ new Date().getFullYear() }}. l2pcompany All rights
            reserved.</strong
          >
        </v-card>
      </v-col>
    </v-footer>
  </v-app>
</template>
<!-- <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-5746542177729335"
     crossorigin="anonymous"></script> -->
<script>
import MbtiContent from "./components/MbtiContent.vue";
import axios from "axios";
import { mapActions, mapGetters, mapState } from "vuex"
let updateurl = "api/update/";
let registerurl = "api/register/";
let logincheckurl = "api/logincheck/";
let passwordchangeurl = "api/change-password/";

export default {
  name: "App",
  components: {
    "mbti-content": MbtiContent,
  },

  data: () => ({
    token: "",
    logined: 0,
    loginId: "",
    nextPath: "/",
    loginPassword: "",
    loginInfoFound: 0,
    updateAccountInfo: {
      userid: "",
      name: "",
      sex: 0,
      mbtitype: "",
    },
    getRegisterLoginInfo: {
      userid: "",
    },
    registerLoginInfo: {
      userid: "",
      name: "",
      password: "",
      password2: "",
      mbtitype: "",
      sex: 0,
      createdate: "",
      date_of_birth: "",
    },
    registerLoginId: "",
    registerLoginIdSet: "",
    registerLoginIdType: 0,
    registerLoginMbti: "",
    registerLoginName: "",
    registerLoginPasswd1: "",
    registerLoginPasswd2: "",
    registerLoginPasswdType: 0,
    registerLoginSex: 0,
    registerLoginPasswdInfo: "",
    changePasswordType: 0,
    changePasswordInfo: "",
    changePasswordCur: "",
    changePassword: "",
    changePassword2: "",
    passwordChangeData: {
      current_password: "",
      new_password: "",
    },
    activePicker: null,
    date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),
    menu: false,
    sexItems: [
      { name: "남", value: 1 },
      { name: "여", value: 2 },
    ],
    getRegisterInfo: [],
    items: [
      "INFP",
      "ENFP",
      "INFJ",
      "ENFJ",
      "INTJ",
      "ENTJ",
      "INTP",
      "ENTP",
      "ISFP",
      "ESFP",
      "ISTP",
      "ESTP",
      "ISFJ",
      "ESFJ",
      "ISTJ",
      "ESTJ",
    ],
    accountDialog: false,
    updateAccountInfoDialog: false,
    updateAccountInfoTimeoutDialog: false,
    passwordChangeDialog: false,
    watchPasswordChangeDialog: false,
    registerFormDialog: false,
    registerFormTimeoutDialog: false,
    loginFormTimeoutDialog: false,
    passwordChangeTimeoutDialog: false,
    tab: null,
    links: [
      { link: "/", title: "MBTI 타입별 특징" },
      { link: "/match", title: "궁합 보기" },
      { link: "/guessme", title: "나를 맞혀봐" },
      { link: "/board", title: "게시판" },
    ],
    idrules: [
      (v) => !!v || "아이디는 필수 입력사항 입니다.",
      (v) => !(v && v.length >= 20) || "아이디는 20자 이상 입력할 수 없습니다.",
      (v) =>
        !/[~!@#$%^&*()_+|<>?:{}]/.test(v) ||
        "아이디에는 특수문자를 사용할 수 없습니다.",
    ],
    namerules: [
      (v) => !!v || "이름은 필수 입력사항 입니다.",
      (v) => !(v && v.length >= 10) || "아이디는 10자 이상 입력할 수 없습니다.",
      (v) =>
        !/[a-zA-Z0-9~!@#$%^&*()_+|<>?:{}]/.test(v) ||
        "이름에는 한글만 사용할 수 있습니다.",
    ],
    passwordrules: [
      (v) => !!v || "패스워드는 필수 입력사항 입니다.",
      (v) =>
        !(v && v.length >= 20) || "패스워드는 20자 이상 입력할 수 없습니다.",
    ],
    selectrules: [(v) => !!v || "MBTI 타입은 필수 입력사항 입니다."],
  }),
  computed: {
    ...mapState('mbtingStore', ["isLoggedIn", "userid"]),
    ...mapGetters('mbtingStore', ["isLogin", "getUserInfo"]),
  },
  created() {},
  mounted() {
    this.updateAfterLoginNextPath();
  },
  watch: {
    updateAccountInfoDialog(val) {
      if (!val) return;

      this.getAccountInfo();
    },
    updateAccountInfoTimeoutDialog(val) {
      if (!val) return;

      setTimeout(
        () => (
          this.updateForm(), (this.updateAccountInfoTimeoutDialog = false)
        ),
        1000
      );
    },
    passwordChangeTimeoutDialog(val) {
      if (!val) return;

      setTimeout(
        () => (
          this.changePasswordForm(), (this.passwordChangeTimeoutDialog = false)
        ),
        1000
      );
    },
    registerFormTimeoutDialog(val) {
      if (!val) return;

      setTimeout(
        () => (this.registerForm(), (this.registerFormTimeoutDialog = false)),
        1000
      );
    },
    loginFormTimeoutDialog(val) {
      if (!val) return;

      setTimeout(() => this.normalLogin(), 1000);
    },
    registerLoginId: function () {
      if (this.registerLoginId != this.registerLoginIdSet) {
        this.registerLoginIdType = 0;
      }
    },
    registerLoginPasswd1: function () {
      if (this.registerLoginPasswd1 != this.registerLoginPasswd2) {
        this.registerLoginPasswdInfo = "패스워드가 일치하지 않습니다.";
        this.registerLoginPasswdType = 0;
      } else {
        this.registerLoginPasswdInfo = "";
        this.registerLoginPasswdType = 1;
      }
    },
    registerLoginPasswd2: function () {
      if (this.registerLoginPasswd1 != this.registerLoginPasswd2) {
        this.registerLoginPasswdInfo = "패스워드가 일치하지 않습니다.";
        this.registerLoginPasswdType = 0;
      } else {
        this.registerLoginPasswdInfo = "";
        this.registerLoginPasswdType = 1;
      }
    },
    changePassword: function () {
      if (this.changePassword != this.changePassword2) {
        this.changePasswordInfo = "패스워드가 일치하지 않습니다.";
        this.changePasswordType = 0;
      } else {
        this.changePasswordInfo = "";
        this.changePasswordType = 1;
      }
    },
    changePassword2: function () {
      if (this.changePassword != this.changePassword2) {
        this.changePasswordInfo = "패스워드가 일치하지 않습니다.";
        this.changePasswordType = 0;
      } else {
        this.changePasswordInfo = "";
        this.changePasswordType = 1;
      }
    },
    menu(val) {
      val && setTimeout(() => (this.activePicker = "YEAR"));
    },
  },
  methods: {
    async validateLoginInfo() {
      await axios({
        method: "GET",
        url: logincheckurl,
      })
        .then((response) => {
          this.getRegisterInfo = response.data;
          for (var idx in this.getRegisterInfo) {
            if (this.loginId == this.getRegisterInfo[idx].uid) {
              if (this.loginPassword == this.getRegisterInfo[idx].passwd) {
                this.loginInfoFound = 1;
                break;
              }
            }
          }
          return;
        })
        .catch((response) => {
          console.log("axios error: ", response);
          return;
        });
    },
    normalLogin() {
      if (this.loginId == "") {
        alert("ID를 입력하세요.");
        return;
      }
      if (this.loginPassword == "") {
        alert("패스워드를 입력하세요.");
        return;
      }
      this.login({
        userid: this.loginId,
        password: this.loginPassword,
      })
        .then(() => {
          this.loginFormTimeoutDialog = false;
          this.closeLoginForm();
          this.$router.go();
        })
        .catch((error) => {
          alert(error)
          this.loginFormTimeoutDialog = false;
          alert("인증 실패!");
        });
    },
    registerIdCheck: function () {
      if (this.registerLoginId == "") {
        alert("ID를 입력하세요.");
        return;
      }

      this.getRegisterLoginInfo.userid = this.registerLoginId;
      axios({
        method: "GET",
        url: logincheckurl,
        params: this.getRegisterLoginInfo,
      })
        .then((response) => {
          this.getRegisterInfo = response.data.items;
          console.log(response.data.items);
          if (this.getRegisterInfo.length > 0) {
            this.registerLoginIdType = 0;
            this.registerLoginIdSet = "";
            alert("ID가 중복 됩니다.");
          } else {
            this.registerLoginIdType = 1;
            this.registerLoginIdSet = this.registerLoginId;
            alert("사용 가능한 ID 입니다.");
          }
        })
        .catch((response) => {
          console.log("axios error: ", response);
          return 0;
        });
    },
    changePasswordForm() {
      if (this.changePasswordType == 0) {
        alert("패스워드를 확인해주세요.");
        return;
      }
      this.passwordChangeData.current_password = this.changePasswordCur;
      this.passwordChangeData.new_password = this.changePassword;
      axios({
        method: "POST",
        url: passwordchangeurl,
        data: this.passwordChangeData,
      })
        .then(() => {
          alert("비밀번호 변경이 완료 되었습니다. 다시 로그인 하십시오.");
          this.logout();
        })
        .catch((response) => {
          alert("비밀번호 변경 실패하였습니다.\n 비밀번호를 확인 하십시오.");
          console.log("axios error: ", response);
          return 0;
        });
      this.changePassword = "";
      this.changePassword2 = "";
      this.changePasswordCur = "";
      this.passwordChangeDialog = false;
    },
    async getAccountInfo() {
      await axios({
        method: "GET",
        url: registerurl + "?userid=" + this.getUserInfo.userid,
      })
        .then((response) => {
          console.log(response);
          let userInfo = response.data.items[0].fields;
          this.updateAccountInfo.name = userInfo.name;
          this.updateAccountInfo.mbtitype = userInfo.mbtitype;
          this.updateAccountInfo.sex = userInfo.sex;
        })
        .catch((response) => {
          console.log("axios user info get error: ", response);
        });
    },
    updateForm: function () {
      if (
        this.updateAccountInfo.name == "" ||
        this.updateAccountInfo.mbtitype == null ||
        this.updateAccountInfo.sex == -1)
      {
        alert("데이터가 유효하지 않습니다.");
        return;
      }
      this.updateAccountInfo.userid = this.getUserInfo.userid;
      axios({
        method: "PATCH",
        url: updateurl,
        data: this.updateAccountInfo,
      })
        .then(() => {
          this.verifyToken();
          alert("회원정보 변경이 완료 되었습니다.");
        })
        .catch((error) => {
          console.log("Axios patch error: ", error.reponse);
        });
      this.closeUpdateForm();
    },
    registerCheck: function () {
      this.registerFormDialog = true;
      this.accountDialog = false;
    },
    registerForm: function () {
      if (this.registerLoginIdType == 0) {
        alert("ID 중복 검사를 진행해 주세요.");
        return;
      }
      if (this.registerLoginName == "") {
        alert("이름을 확인해주세요.");
        return;
      }
      if (this.registerLoginPasswdType == 0) {
        alert("패스워드를 확인해주세요.");
        return;
      }
      if (this.registerLoginSex == "") {
        alert("성별을 확인해주세요.");
        return;
      }

      this.registerLoginInfo.userid = this.registerLoginId;
      this.registerLoginInfo.name = this.registerLoginName;
      this.registerLoginInfo.password = this.registerLoginPasswd1;
      this.registerLoginInfo.password2 = this.registerLoginPasswd2;
      this.registerLoginInfo.mbtitype = this.registerLoginMbti;
      this.registerLoginInfo.sex = this.registerLoginSex;
      var date = new Date();
      var year = date.getFullYear();
      var month = ("0" + (date.getMonth() + 1)).slice(-2);
      var day = ("0" + date.getDate()).slice(-2);
      this.registerLoginInfo.createdate = year + "-" + month + "-" + day;
      this.registerLoginInfo.date_of_birth = this.date;

      axios({
        method: "POST",
        url: registerurl,
        data: this.registerLoginInfo,
      })
        .then(() => {
          // this.$store.dispatch("mbtingStore/verifyToken");
          alert("회원 가입 성공하였습니다.\n");
        })
        .catch((response) => {
          alert("회원 가입 실패하였습니다.\n관리자에게 문의하세요.");
          console.log("axios error: ", response);
          return 0;
        });

      this.closeRegisterForm();
    },
    kakaoLogin: function () {
      alert("준비중..");
    },
    googleLogin: function () {
      alert("준비중..");
    },
    save(date) {
      this.$refs.menu.save(date);
    },
    closeUpdateForm: function () {
      this.updateAccountInfoDialog = false;
      this.updateAccountInfo.userid = "";
      this.updateAccountInfo.name = "";
      this.updateAccountInfo.mbtitype = "";
      this.updateAccountInfo.sex = -1;
    },
    closeLoginForm: function () {
      this.loginId = "";
      this.loginPassword = "";
      this.accountDialog = false;
    },
    closeRegisterForm: function () {
      (this.registerLoginId = ""),
        (this.registerLoginIdSet = ""),
        (this.registerLoginIdType = 0),
        (this.registerLoginName = ""),
        (this.registerLoginPasswd1 = ""),
        (this.registerLoginPasswd2 = ""),
        (this.registerLoginMbti = ""),
        (this.registerLoginSex = 0),
        (this.date = new Date(
          Date.now() - new Date().getTimezoneOffset() * 60000
        )
          .toISOString()
          .substr(0, 10)),
        (this.accountDialog = false),
        (this.registerFormDialog = false);
      this.closeLoginForm();
    },
    normallogout: function (event) {
      if (confirm("로그아웃 하시겠습니까?")) {
        event.preventDefault();
        this.logout();
        if (this.$route.path !== "/") {
          this.$router.push("/");
        }
        // this.$router.push("/").catch(() => {});
      }
    },
    updateAfterLoginNextPath() {
      if ("next" in this.$route.query) {
        this.nextPath = this.$route.query.next;
      }
    },

    ...mapActions('mbtingStore', ["logout", "login", "verifyToken"]),
  },
};
</script>
