import axios from "axios";

// this base url will be change based on
// if you need to point to production.
const BASE_URL = "https://mbting.conpurple.com";
const ACCESS_TOKEN = "access_token";
const REFRESH_TOKEN = "refresh_token";

function getAuthUserInfo(userid) {
  return tokenRequest
    .get("/api/logincheck/?userid=" + userid)
    .then((response) => {
      // return Promise.resolve(response.data.items[0].fields)
      return response;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

function getUserIdFromToken(token) {
  var url = token.split(".")[1];
  var base64 = url.replace(/-/g, "+").replace(/_/g, "/");
  var jsonPayload = decodeURIComponent(
    atob(base64)
      .split("")
      .map(function (c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join("")
  );
  var userid = JSON.parse(jsonPayload).name;
  return userid;
}

/* refresh */
const refreshToken = () => {
  const refreshBody = { refresh: window.localStorage.getItem(REFRESH_TOKEN) };
  return tokenRequest
    .post("/api/token-refresh/", refreshBody)
    .then((response) => {
      console.log(response.data.access);
      window.localStorage.setItem(ACCESS_TOKEN, response.data.access);
      return Promise.resolve(authVerifyToken());
    })
    .catch((error) => Promise.reject(error));
};

/* verify */
function authVerifyToken() {
  let access_token = localStorage.getItem(ACCESS_TOKEN);
  // console.log(access_token)
  if (access_token == null) {
    return Promise.reject();
  }

  return tokenRequest
    .post("/api/token-verify/", { token: access_token })
    .then((response) => {
      if (response.status == 200) {
        axios.defaults.headers.common[
          "Authorization"
        ] = `Bearer ${window.localStorage.getItem(ACCESS_TOKEN)}`;
        var userid = getUserIdFromToken(access_token);
        return Promise.resolve(getAuthUserInfo(userid));
      } else {
        logoutUser();
        return Promise.reject();
      }
    })
    .catch(() => {
      return refreshToken();
    });
}

/*
 * authRequest
 *
 * This refreshes the request and retries the token if it is invalid.
 * This is what you use to create any requests that need the Tokens.
 * Reference: https://hackernoon.com/110percent-complete-jwt-authentication-with-django-and-react-2020-iejq34ta
 *
 * Example:
 *     authRequest.get('/path/to/endpoint/',extraParameters)
 *        .then(response=>{
 *          // do something with successful request
 *        }).catch((error)=> {
 *          // handle any errors.
 *        });
 */
const tokenRequest = axios.create({
  baseURL: BASE_URL,
  timeout: 5000,
  headers: {
    "Content-Type": "application/json",
    accept: "application/json",
  },
  withCredentials: true,
});

const loginUser = (userid, password) => {
  const loginBody = { userid: userid, password: password };
  return tokenRequest
    .post("/api/login/", loginBody)
    .then((response) => {
      window.localStorage.setItem(ACCESS_TOKEN, response.data.access);
      window.localStorage.setItem(REFRESH_TOKEN, response.data.refresh);
      axios.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${window.localStorage.getItem(ACCESS_TOKEN)}`;

      return Promise.resolve(authVerifyToken());
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

const logoutUser = () => {
  window.localStorage.removeItem(ACCESS_TOKEN);
  window.localStorage.removeItem(REFRESH_TOKEN);
  authRequest.defaults.headers.Authorization = "";
  return Promise.resolve();
};

const isCorrectRefreshError = (status) => status === 401;

const authRequest = axios.create({
  baseURL: BASE_URL,
  timeout: 5000,
  headers: {
    Authorization: `Bearer ${window.localStorage.getItem(ACCESS_TOKEN)}`,
    "Content-Type": "application/json",
  },
});

const errorInterceptor = (error) => {
  const originalRequest = error.config;
  const { status } = error.response;
  if (isCorrectRefreshError(status)) {
    return refreshToken()
      .then(() => {
        const headerAuthorization = `Bearer ${window.localStorage.getItem(
          ACCESS_TOKEN
        )}`;
        authRequest.defaults.headers.Authorization = headerAuthorization;
        originalRequest.headers.Authorization = headerAuthorization;
        return authRequest(originalRequest);
      })
      .catch((tokenRefreshError) => {
        // if token refresh fails, logout the user to avoid potential security risks.
        // logoutUser();
        return Promise.reject(tokenRefreshError);
      });
  }
  return Promise.reject(error);
};

authRequest.defaults.headers.post["Content-Type"] =
  "application/x-www-form-urlencoded";
export default authRequest;

export {
  tokenRequest,
  loginUser,
  logoutUser,
  refreshToken,
  authVerifyToken,
  authRequest,
  errorInterceptor,
  BASE_URL,
  ACCESS_TOKEN,
  REFRESH_TOKEN,
  getAuthUserInfo,
  getUserIdFromToken
};
