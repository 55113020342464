<template>
  <v-container fluid>
    <!-- <Adsense
      data-ad-client="ca-pub-5746542177729335"
      data-ad-slot="3800733658"
      data-ad-format="auto"
      data-full-width-responsive="true"
    >
    </Adsense> -->
    <v-row>
      <v-col cols="12" sm="12" md="6" offset-md="3">
        <v-card class="mt-2">
          <v-row justify="center">
            <v-btn
              v-scroll="onScroll"
              v-show="fab"
              fab
              dark
              fixed
              bottom
              right
              color="primary"
              @click="toTop"
            >
              위로
            </v-btn>
          </v-row>
        </v-card>
        <v-card class="mx-auto" max-width="600px" outlined>
          <v-form>
            <v-container v-if="!isLogin">
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    v-if="!writeDialog"
                    class="mt-2"
                    outlined
                    placeholder="글을 남겨주세요!"
                    @click="writeDialog = true"
                  ></v-text-field>
                  <v-container v-else>
                    <v-row>
                      <v-col cols="12">
                        <v-text-field
                          v-model="writeData.username"
                          :counter="10"
                          label="작성자를 입력해주세요.(default: 익명)"
                          :rules="namerules"
                          outlined
                          required
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12">
                        <v-textarea
                          autofocus
                          v-model="writeData.contents"
                          :counter="400"
                          :rules="contentrules"
                          outlined
                          clearable
                          filled
                          required
                          single-line
                          placeholder="내용을 입력해주세요."
                          @click:clear="writeFormClear()"
                        ></v-textarea>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12">
                        <v-text-field
                          v-model="writeData.password"
                          :counter="20"
                          :rules="passwordrules"
                          type="password"
                          label="비밀번호"
                          outlined
                          required
                          prepend-inner-icon="mdi-lock"
                          v-on:keyup.enter="writeTimeoutDialog = true"
                        >
                        </v-text-field>
                      </v-col>
                    </v-row>
                    <v-row align="center" justify="end">
                      <v-col cols="auto" class="text-right">
                        <v-btn
                          outlined
                          rounded
                          text
                          @click="writeTimeoutDialog = true"
                          :disabled="writeTimeoutDialog"
                          :loading="writeTimeoutDialog"
                          >쓰기
                        </v-btn>

                        <v-btn outlined rounded text @click="closeWriteForm()"
                          >닫기
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card>
        <v-card class="mx-auto" max-width="600px" outlined>
          <v-row justify="center">
            <v-col>
              <v-img
              v-if="isLogin"
                class="mt-4"
                contain
                @click="copyURL"
                style="cursor: pointer"
              >
                <v-btn color="black" dark>URL 복사하기 </v-btn></v-img
              >
            </v-col>
          </v-row>
          <v-row class="text-left">
            <v-col>
              <v-card-text class="font-weight-bold"
                >총 개수 [ {{ getCountBoardList() }} ]</v-card-text
              >
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-data-table
                :headers="headers"
                :items="getBoardList"
                :page.sync="page"
                :items-per-page="itemsPerPage"
                :sort-by="['id', 'date']"
                :sort-desc="[true, true]"
                item-key="id"
                class="elevation-0"
                @page-count="pageCount = $event"
                dense
                hide-default-header
                hide-default-footer
                :expanded.sync="expanded"
                :single-expand="singleExpand"
                ><template v-slot:item="{ item, expand, isExpanded }">
                  <table
                    style="
                      width: 100%;
                      border-collapse: separate;
                      border-spacing: 0 5px;
                    "
                  >
                    <td style="width: 6%">
                      <tr>
                        <td>
                          <v-icon class="ml-2">mdi-face-man-outline</v-icon>
                        </td>
                      </tr>
                    </td>
                    <td>
                      <tr>
                        <td style="font-size: 16px">
                          <!-- <v-card-actions> -->
                          <v-menu bottom offset-y>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                class="ml-10 ma-2 background-color: transparent"
                                v-bind="attrs"
                                v-on="on"
                                v-ripple="false"
                                icon
                              >
                                {{ item.username }}
                              </v-btn>
                            </template>
                            <v-list v-if="!isLogin">
                              <v-list-item
                                v-for="(menuitem, i) in menuItems"
                                :key="i"
                                @click="
                                  boardNameClick(item.id, menuitem.action)
                                "
                              >
                                <v-list-item-title>{{
                                  menuitem.title
                                }}</v-list-item-title>
                              </v-list-item>
                            </v-list>
                          </v-menu>
                          <!-- </v-card-actions> -->
                        </td>
                      </tr>
                      <tr>
                        <td style="font-size: 13px; padding-left: 2px">
                          {{ item.contents }}
                        </td>
                      </tr>
                      <tr>
                        <td style="font-size: 11px; padding-left: 2px">
                          {{ item.date }}
                        </td>
                        <td style="font-size: 11px; padding-left: 6px">
                          <v-btn
                            v-if="isLogin"
                            class="ma-2 background-color: transparent"
                            style="font-size: 12px"
                            v-ripple="false"
                            icon
                            @click="
                              expand(!isExpanded),
                                (writeReplyData.uid = item.id)
                            "
                            >답글 달기</v-btn
                          >
                        </td>
                      </tr>
                    </td>
                  </table>
                  <v-card
                    class="ml-6"
                    max-width="560px"
                    outlined
                    color="transparent"
                  >
                    <v-data-table
                      :headers="headers"
                      :items="getReplyList(item.id)"
                      item-key="id"
                      class="elevation-0"
                      @page-count="pageCount = $event"
                      dense
                      hide-default-header
                      hide-default-footer
                    >
                      <template v-slot:item="{ item }">
                        <table
                          v-show="item"
                          style="
                            width: 100%;
                            border-collapse: separate;
                            border-spacing: 0 5px;
                          "
                        >
                          <td style="width: 6%">
                            <tr>
                              <td>
                                <v-icon class="ml-2"
                                  >mdi-subdirectory-arrow-right</v-icon
                                >
                              </td>
                              <td>
                                <v-icon class="ml-2"
                                  >mdi-face-man-outline</v-icon
                                >
                              </td>
                            </tr>
                          </td>
                          <td>
                            <tr>
                              <td style="font-size: 16px">
                                <v-menu bottom offset-y>
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                      class="
                                        ml-10
                                        ma-2
                                        background-color:
                                        transparent
                                      "
                                      v-bind="attrs"
                                      v-on="on"
                                      v-ripple="false"
                                      icon
                                    >
                                      {{ item.username }}
                                    </v-btn>
                                  </template>
                                  <v-list v-if="isLoggedIn">
                                    <v-list-item
                                      v-for="(menuitem, i) in menuItems"
                                      :key="i"
                                      @click="
                                        replyNameClick(item.id, menuitem.action)
                                      "
                                    >
                                      <v-list-item-title>{{
                                        menuitem.title
                                      }}</v-list-item-title>
                                    </v-list-item>
                                  </v-list>
                                </v-menu>
                              </td>
                            </tr>
                            <tr>
                              <td style="font-size: 13px; padding-left: 2px">
                                {{ item.contents }}
                              </td>
                            </tr>
                            <tr>
                              <td style="font-size: 11px; padding-left: 2px">
                                {{ item.date }}
                              </td>
                            </tr>
                          </td>
                        </table>
                      </template>
                      <template slot="no-data">
                        <tr style="visibility: hidden"></tr>
                      </template>
                    </v-data-table>
                  </v-card>
                </template>
                <template slot="no-data">
                  <div>등록된 댓글이 없습니다.</div>
                </template>
                <template v-slot:expanded-item>
                  <tr>
                    <td>
                      <v-container>
                        <v-row>
                          <v-col cols="12">
                            <v-textarea
                              autofocus
                              v-model="writeReplyData.contents"
                              :counter="400"
                              :rules="contentrules"
                              outlined
                              clearable
                              filled
                              required
                              single-line
                              placeholder="답글을 남겨주세요."
                            ></v-textarea>
                          </v-col>
                        </v-row>
                        <v-row align="center" justify="end">
                          <v-col cols="auto" class="text-right">
                            <v-btn
                              outlined
                              rounded
                              text
                              @click="writeReplyTimeoutDialog = true"
                              :disabled="writeReplyTimeoutDialog"
                              :loading="writeReplyTimeoutDialog"
                              >쓰기</v-btn
                            >
                            <v-btn outlined rounded text @click="expanded = []"
                              >닫기</v-btn
                            >
                          </v-col>
                        </v-row>
                      </v-container>
                    </td>
                  </tr>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-card>
        <v-card>
          <v-dialog
            v-model="updateBoardPasswordDialog"
            persistent
            max-width="500px"
          >
            <v-card>
              <v-card-title>
                <span class="text-h5">비밀번호 입력</span>
              </v-card-title>
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col cols="12">
                      <v-text-field
                        v-model="updatePassword"
                        :counter="20"
                        :rules="passwordrules"
                        type="password"
                        label="비밀번호"
                        outlined
                        required
                        prepend-inner-icon="mdi-lock"
                        v-on:keyup.enter="updateBoardPasswordTimeDialog = true"
                      >
                      </v-text-field>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  outlined
                  rounded
                  text
                  @click="updateBoardPasswordTimeDialog = true"
                  :disabled="updateBoardPasswordTimeDialog"
                  :loading="updateBoardPasswordTimeDialog"
                  >확인
                </v-btn>

                <v-btn outlined rounded text @click="closeUpdateBoardPassword()"
                  >닫기
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-card>
        <v-card>
          <v-dialog v-model="updateBoardDialog" persistent max-width="500px">
            <v-card>
              <v-card-title>
                <span class="text-h5">수정</span>
              </v-card-title>
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col cols="12">
                      <v-text-field
                        v-model="getBoardData.username"
                        :counter="20"
                        label="작성자"
                        :rules="namerules"
                        outlined
                        required
                        v-on:keyup.enter="updateTimeoutDialog = true"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12">
                      <v-textarea
                        v-model="getBoardData.contents"
                        :counter="200"
                        label="내용"
                        :rules="contentrules"
                        outlined
                        required
                        v-on:keyup.enter="updateTimeoutDialog = true"
                      ></v-textarea>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  outlined
                  rounded
                  text
                  @click="updateTimeoutDialog = true"
                  :disabled="updateTimeoutDialog"
                  :loading="updateTimeoutDialog"
                  >수정
                </v-btn>

                <v-btn outlined rounded text @click="closeUpdateDialog()"
                  >닫기
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-card>
        <v-card>
          <v-dialog
            v-model="deleteBoardPasswordDialog"
            persistent
            max-width="500px"
          >
            <v-card>
              <v-card-title>
                <span class="text-h5">비밀번호 입력</span>
              </v-card-title>
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col cols="12">
                      <v-text-field
                        v-model="deletePassword"
                        :counter="20"
                        :rules="passwordrules"
                        type="password"
                        label="비밀번호"
                        outlined
                        required
                        prepend-inner-icon="mdi-lock"
                        v-on:keyup.enter="deleteBoardPasswordTimeDialog = true"
                      >
                      </v-text-field>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  outlined
                  rounded
                  text
                  @click="deleteBoardPasswordTimeDialog = true"
                  :disabled="deleteBoardPasswordTimeDialog"
                  :loading="deleteBoardPasswordTimeDialog"
                  >확인
                </v-btn>

                <v-btn outlined rounded text @click="closeDeleteBoardPassword()"
                  >닫기
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-card>
        <v-card>
          <v-dialog
            v-model="updateBoardReplyDialog"
            persistent
            max-width="500px"
          >
            <v-card>
              <v-card-title>
                <span class="text-h5">수정</span>
              </v-card-title>
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col cols="12">
                      <v-textarea
                        v-model="getBoardReplyData.contents"
                        :counter="200"
                        label="내용"
                        :rules="contentrules"
                        outlined
                        required
                        v-on:keyup.enter="updateReplyTimeoutDialog = true"
                      ></v-textarea>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  outlined
                  rounded
                  text
                  @click="updateReplyTimeoutDialog = true"
                  :disabled="updateReplyTimeoutDialog"
                  :loading="updateReplyTimeoutDialog"
                  >수정
                </v-btn>

                <v-btn outlined rounded text @click="closeUpdateReplyDialog()"
                  >닫기
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<!-- <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-5746542177729335"
     crossorigin="anonymous"></script> -->
<script>
import axios from "axios";
import { mapGetters, mapState } from "vuex";
let askmeboardurl = "api/askmeboard/";
let askmeboardreplyurl = "api/askmeboardreply/";

export default {
  name: "AskmeDetail",
  data: () => ({
    fab: false,
    getBoardList: [],
    getBoardReplyList: [],
    page: 1,
    pageCount: 0,
    itemsPerPage: 20,
    expanded: [],
    singleExpand: true,
    benched: 0,
    headers: [
      {
        text: "N",
        align: "start",
        sortable: false,
      },
      {
        text: "작성자",
        align: "start",
        sortable: false,
        value: "username",
      },
      {
        text: "내용",
        value: "contents",
        sortable: false,
      },
      { text: "날짜", value: "date", sortable: false },
    ],
    menuItems: [
      {
        title: "수정하기",
        action: "update",
      },
      {
        title: "삭제하기",
        action: "delete",
      },
    ],
    hidden: false,
    writeData: {
      board_id: "",
      username: "익명",
      contents: "",
      password: "",
      date: "",
    },
    writeReplyData: {
      uid: "",
      board_id: "",
      username: "",
      contents: "",
      date: "",
    },
    getBoardData: {},
    getBoardReplyData: {},
    watchWriteReplyDialog: false,
    writeDialog: false,
    writeTimeoutDialog: false,
    updatePassword: "",
    updateBoardDialog: false,
    updateBoardPasswordDialog: false,
    updateBoardPasswordTimeDialog: false,
    updateTimeoutDialog: false,
    deletePassword: "",
    deleteBoardDialog: false,
    deleteBoardPasswordDialog: false,
    deleteBoardPasswordTimeDialog: false,
    deleteTimeoutDialog: false,
    writeReplyTimeoutDialog: false,
    updateBoardReplyDialog: false,
    updateReplyTimeoutDialog: false,

    namerules: [
      (v) => !!v || "작성자 이름은 필수 입력사항 입니다.",
      (v) =>
        !(v && v.length >= 10) || "작성자 이름은 10자 이상 입력할 수 없습니다.",
      (v) =>
        !/[~!@#$%^&*()_+|<>?:{}]/.test(v) ||
        "작성자 이름에는 특수문자를 사용할 수 없습니다.",
    ],
    contentrules: [
      (v) => !!v || "내용은 필수 입력사항 입니다.",
      (v) => !(v && v.length >= 400) || "내용은 400자 이상 입력할 수 없습니다.",
      // v => !/[~!@#$%^&*()_+|<>?:{}]/.test(v) || '제목에는 특수문자를 사용할 수 없습니다.'
    ],
    passwordrules: [
      (v) => !!v || "패스워드 형식이 올바르지 않습니다.",
      (v) => !(v && v.length >= 20) || "패스워드 형식이 올바르지 않습니다.",
    ],
  }),
  watch: {
    writeTimeoutDialog(val) {
      if (!val) return;

      setTimeout(() => this.writeForm(), 1000);
    },
    updateTimeoutDialog(val) {
      if (!val) return;

      setTimeout(() => this.updateForm(), 1000);
    },
    updateBoardPasswordTimeDialog(val) {
      if (!val) return;

      setTimeout(() => this.checkUpdateBoardPassword(), 1000);
    },
    deleteBoardPasswordTimeDialog(val) {
      if (!val) return;

      setTimeout(() => this.checkDeleteBoardPassword(), 1000);
    },
    writeReplyTimeoutDialog(val) {
      if (!val) return;

      setTimeout(() => this.writeReplyForm(), 1000);
    },
    updateReplyTimeoutDialog(val) {
      if (!val) return;

      setTimeout(() => this.updateReplyForm(), 1000);
    },
  },
  created() {},
  mounted() {
    var token = localStorage.getItem("access_token");
    var userid;
    // alert("mounted : " + this.$route.path);
    if (token != null) {
      var url = token.split(".")[1];
      var base64 = url.replace(/-/g, "+").replace(/_/g, "/");
      var jsonPayload = decodeURIComponent(
        atob(base64)
          .split("")
          .map(function (c) {
            return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
          })
          .join("")
      );
      userid = JSON.parse(jsonPayload).name;

      this.getBoard(userid);
      this.getBoardReply(userid);
      if (userid) {
        // this.$router.replace("/" + userid);
        const path = "/" + userid;
        if (this.$route.path !== path) {
          // alert("path diff : " + path);
          this.$router.replace(path);
        } else {
          // alert("path same : " + path);
          // this.$router.go("");
        }
      } else {
        this.$router.replace("/");
      }
    } else {
      this.getBoard(this.$route.params.userid);
      this.getBoardReply(this.$route.params.userid);
    }
  },
  computed: {
    ...mapState("askmeStore", ["isLoggedIn", "userid"]),
    ...mapGetters("askmeStore", ["isLogin", "getUserInfo"]),
    items() {
      return Array.from({ length: this.length }, (k, v) => v + 1);
    },
    length() {
      return 7000;
    },
  },
  methods: {
    onScroll(e) {
      if (typeof window === "undefined") return;
      const top = window.pageYOffset || e.target.scrollTop || 0;
      this.fab = top > 20;
    },
    toTop() {
      this.$vuetify.goTo(0);
    },
    makeMyBoard: function () {
      if (this.isLogin) {
        alert("userid: " + this.getUserInfo.userid);
      }
    },
    closeWriteForm: function () {
      this.writeDialog = false;
      this.writeTimeoutDialog = false;
      this.writeData.username = "";
      this.writeData.contents = "";
      this.writeData.password = "";
      this.writeData.date = "";
      this.writeData.board_id = "";
    },
    writeForm: function () {
      if (
        this.writeData.username == "" ||
        this.writeData.contents == "" ||
        this.writeData.password == ""
      ) {
        this.writeTimeoutDialog = false;
        alert("데이터가 유효하지 않습니다.");
        return;
      }

      var date = new Date();
      var year = date.getFullYear();
      var month = ("0" + (date.getMonth() + 1)).slice(-2);
      var day = ("0" + date.getDate()).slice(-2);
      var hour = date.getHours();
      var min = date.getMinutes();
      this.writeData.board_id = this.$route.params.userid;
      this.writeData.date =
        year + "-" + month + "-" + day + " " + hour + ":" + min;

      axios({
        method: "POST",
        url: askmeboardurl,
        data: this.writeData,
      })
        .then(() => {
          this.getBoard(this.$route.params.userid);
          this.closeWriteForm();
          alert("글쓰기 완료!");
        })
        .catch((error) => {
          this.closeWriteForm();
          console.log("Axios get error: ", error.reponse);
        });
    },
    writeReplyForm: function () {
      if (this.writeReplyData.contents == "") {
        this.writeReplyTimeoutDialog = false;
        alert("데이터가 유효하지 않습니다.");
        return;
      }

      var date = new Date();
      var year = date.getFullYear();
      var month = ("0" + (date.getMonth() + 1)).slice(-2);
      var day = ("0" + date.getDate()).slice(-2);
      var hour = date.getHours();
      var min = date.getMinutes();

      this.writeReplyData.board_id = this.$route.params.userid;
      this.writeReplyData.username = this.$route.params.userid;
      this.writeReplyData.date =
        year + "-" + month + "-" + day + " " + hour + ":" + min;

      axios({
        method: "POST",
        url: askmeboardreplyurl,
        data: this.writeReplyData,
      })
        .then(() => {
          this.writeReplyTimeoutDialog = false;
          this.expanded = [];
          this.$router.go("");
          alert("글쓰기 완료!");
        })
        .catch((error) => {
          this.writeReplyTimeoutDialog = false;
          console.log("Axios get error: ", error.reponse);
        });
    },
    updateForm: function () {
      var date = new Date();
      var year = date.getFullYear();
      var month = ("0" + (date.getMonth() + 1)).slice(-2);
      var day = ("0" + date.getDate()).slice(-2);
      var hour = date.getHours();
      var min = date.getMinutes();
      this.getBoardData.date =
        year + "-" + month + "-" + day + " " + hour + ":" + min;

      axios({
        method: "PATCH",
        url: askmeboardurl + this.getBoardData.id + "/",
        data: this.getBoardData,
      })
        .then(() => {
          this.getBoard(this.$route.params.userid);
          this.closeUpdateDialog();
          alert("수정 완료!");
        })
        .catch((error) => {
          this.closeUpdateDialog();
          console.log("Axios patch error: ", error.reponse);
        });
    },
    getBoard: function (userid) {
      axios({
        method: "GET",
        url: askmeboardurl + "?board_id=" + userid,
      })
        .then((response) => {
          if (response.data.results.length) {
            this.getBoardList = response.data.results;
          }
        })
        .catch((response) => {
          console.log("axios error: ", response);
          return 0;
        });
    },
    getBoardReply: function (userid) {
      axios({
        method: "GET",
        url: askmeboardreplyurl + "?board_id=" + userid,
      })
        .then((response) => {
          if (response.data.results.length) {
            this.getBoardReplyList = response.data.results;
          }
        })
        .catch((response) => {
          console.log("axios error: ", response);
          return 0;
        });
    },
    calcCurCount: function (index) {
      return index;
    },
    rowClick: function (id) {
      alert("click : " + id);
    },
    writeFormClear: function () {
      this.writeForm.username = "";
      this.writeForm.contents = "";
    },
    getCountBoardList: function () {
      const count = Object.keys(this.getBoardList).length;
      return count;
    },
    boardNameClick: function (id, action) {
      if (action == "update") {
        this.checkUpdateBoardForm(id)
          .then((ret) => {
            if (!ret) {
              alert("연결에 문제가 있습니다.");
              return;
            }
            this.updateBoardPasswordDialog = true;
          })
          .catch((err) => console.log(err));
      } else if (action == "delete") {
        this.checkDeleteBoardForm(id)
          .then((ret) => {
            if (!ret) {
              alert("연결에 문제가 있습니다.");
              return;
            }
            this.deleteBoardPasswordDialog = true;
          })
          .catch((err) => console.log(err));
      }
    },
    replyNameClick: function (id, action) {
      if (action == "update") {
        this.checkUpdateBoardReplyForm(id)
          .then((ret) => {
            if (!ret) {
              alert("연결에 문제가 있습니다.");
              return;
            }
            this.updateBoardReplyDialog = true;
          })
          .catch((err) => console.log(err));
      } else if (action == "delete") {
        this.checkDeleteBoardReplyForm(id)
          .then((ret) => {
            if (!ret) {
              alert("연결에 문제가 있습니다.");
              return;
            }
            if (confirm("정말로 삭제 하시겠습니까?")) {
              axios({
                method: "DELETE",
                url: askmeboardreplyurl + this.getBoardReplyData.id + "/",
              })
                .then(() => {
                  this.$router.go("");
                  this.closeUpdateReplyDialog();
                })
                .catch((error) => {
                  console.log("axios delete error: ", error.reponse);
                });
            }
          })
          .catch((err) => console.log(err));
      }
    },
    checkUpdateBoardForm(id) {
      return axios.get(askmeboardurl + id + "/", {}).then((response) => {
        if (response.data.id == id) {
          this.getBoardData = response.data;
          return true;
        }
        return false;
      });
    },
    checkUpdateBoardReplyForm(id) {
      return axios.get(askmeboardreplyurl + id + "/", {}).then((response) => {
        if (response.data.id == id) {
          this.getBoardReplyData = response.data;
          return true;
        }
        return false;
      });
    },
    checkDeleteBoardForm(id) {
      return axios.get(askmeboardurl + id + "/", {}).then((response) => {
        if (response.data.id == id) {
          this.getBoardData = response.data;
          return true;
        }
        return false;
      });
    },
    checkDeleteBoardReplyForm(id) {
      return axios.get(askmeboardreplyurl + id + "/", {}).then((response) => {
        if (response.data.id == id) {
          this.getBoardReplyData = response.data;
          return true;
        }
        return false;
      });
    },
    checkUpdateBoardPassword: function () {
      return axios
        .get(askmeboardurl + this.getBoardData.id + "/", {})
        .then((response) => {
          if (response.data.password == this.updatePassword) {
            this.closeUpdateBoardPassword();
            this.updateBoardDialog = true;

            return true;
          }
          this.updateBoardPasswordTimeDialog = false;
          alert("패스워드가 맞지 않습니다.");
          return false;
        });
    },
    checkDeleteBoardPassword: function () {
      return axios
        .get(askmeboardurl + this.getBoardData.id + "/", {})
        .then((response) => {
          if (response.data.password == this.deletePassword) {
            if (confirm("정말로 삭제 하시겠습니까?")) {
              axios({
                method: "DELETE",
                url: askmeboardurl + this.getBoardData.id + "/",
              })
                .then(() => {
                  this.$router.go("");
                  this.closeDeleteBoardPassword();
                })
                .catch((error) => {
                  console.log("axios delete error: ", error.reponse);
                });
            }
            return true;
          }
          this.deleteBoardPasswordTimeDialog = false;
          alert("패스워드가 맞지 않습니다.");
          return false;
        });
    },
    closeUpdateBoardPassword: function () {
      this.updateBoardPasswordDialog = false;
      this.updateBoardPasswordTimeDialog = false;
      this.updatePassword = "";
    },
    closeUpdateDialog: function () {
      this.closeUpdateBoardPassword();
      this.updateBoardDialog = false;
      this.updateTimeoutDialog = false;
      this.getBoardData = {};
    },
    closeDeleteBoardPassword: function () {
      this.deleteBoardPasswordDialog = false;
      this.deleteBoardPasswordTimeDialog = false;
      this.deletePassword = "";
    },
    getReplyList: function (id) {
      var obj = [];
      this.getBoardReplyList.forEach(function (entry) {
        if (entry.uid == id) {
          obj.push(entry);
        }
      });

      return obj;
    },
    closeUpdateReplyDialog: function () {
      this.updateBoardReplyDialog = false;
      this.updateReplyTimeoutDialog = false;
      this.getBoardReplyData = {};
    },
    updateReplyForm: function () {
      var date = new Date();
      var year = date.getFullYear();
      var month = ("0" + (date.getMonth() + 1)).slice(-2);
      var day = ("0" + date.getDate()).slice(-2);
      var hour = date.getHours();
      var min = date.getMinutes();
      this.getBoardReplyData.date =
        year + "-" + month + "-" + day + " " + hour + ":" + min;

      axios({
        method: "PATCH",
        url: askmeboardreplyurl + this.getBoardReplyData.id + "/",
        data: this.getBoardReplyData,
      })
        .then(() => {
          this.getBoard(this.$route.params.userid);
          this.getBoardReply(this.$route.params.userid);
          this.closeUpdateReplyDialog();

          alert("수정 완료!");
        })
        .catch((error) => {
          this.closeUpdateReplyDialog();
          console.log("Axios patch error: ", error.reponse);
        });
    },
    copyURL() {
      var dummy = document.createElement("input");
      var text = location.href;

      document.body.appendChild(dummy);
      dummy.value = text;
      dummy.select();
      document.execCommand("copy");
      document.body.removeChild(dummy);
      alert("URL이 복사 되었습니다.");
    },
  },
};
</script>
