<template>
  <v-row>
    <v-col cols="12" sm="12" md="8" offset-md="2">
      <v-card class="mt-2 pa-2">
        <v-tabs v-model="active_tab" centered icons-and-text>
          <v-tabs-slider></v-tabs-slider>
          <v-tab v-for="item in menuItems" :key="item.index" @click="getBoardList(item.index, 1)">
            <b>{{ item.name }}</b>
            <v-img height="45px" width="45px" class="mx-auto" :src="item.path">
            </v-img>
          </v-tab>
        </v-tabs>
        <v-expansion-panels multiple accordion focusable class="mt-2">
          <v-expansion-panel
            class="mx-auto left pa-2"
            v-for="(item, i) in boards"
            :key="i"
          >
            <v-expansion-panel-header>
              <strong>{{ item.subject }}</strong>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <div align="left" v-html="item.content"></div>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-card>
    </v-col>
  </v-row>
</template>
<!-- <script
  async
  src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-5746542177729335"
  crossorigin="anonymous"
></script> -->
<script>
// import BoardComponent from "./SubFaqComponent.vue";
import axios from "axios";

export default {
  data: () => ({
    models: [
      "alldroid",
      "dotdroid",
      "qdroid",
      "wonderpod",
      // "mbting",
      // "let's lotto",
      // "축심",
      // "우리동네경호원",
    ],
    itemsPerPage: 5,
    pageInfo: {
      totalCount: 0,
      curPage: 0,
      pageCount: 0,
      // next: "",
      // prev: "",
    },
    idx: 0,
    active_tab: 0,
    menuItems: [
      {
        name: "Alldroid",
        index: 0,
        path: require("../assets/alldroid_logo.png"),
      },
      {
        name: "Dotdroid",
        index: 1,
        path: require("../assets/dotdroid_logo.png"),
      },
      { 
        name: "Qdroid", index: 2, path: require("../assets/qdroid_logo.png") 
      },
      { 
        name: "WonderPod", index: 3, path: require("../assets/wonderpod.png") 
      },
    ],
    boards: [],
  }),
  components: {
    // BoardComponent,
  },
  methods: {
    touch: function (n) {
      // alert("DD = " + n);
      this.idx = n;
    },
    getBoardList: function (idx, page) {
      var boardurl = "";

      switch (idx) {
        case 0:
          boardurl = "/l2pboard/alldroid_faq/?page=" + page;
          break;
        case 1:
          boardurl = "/l2pboard/dotdroid_faq/?page=" + page;
          break;
        case 2:
          boardurl = "/l2pboard/qdroid_faq/?page=" + page;
          break;
        case 3:
          boardurl = "/l2pboard/wonderpod_faq/?page=" + page;
          break;
      }

      axios({
        method: "GET",
        url: boardurl,
      })
        .then((response) => {
          this.pageInfo.totalCount = response.data.count;
          this.pageInfo.pageCount = Math.ceil(
            this.pageInfo.totalCount / Number(this.itemsPerPage)
          );
          this.pageInfo.curPage = page;
          // if (this.boards.length > 0) {
            this.boards = [];
          // }
          this.boards = response.data.results;
          return 0;
        })
        .catch((response) => {
          console.log("axios get error: ", response);
          return 0;
        });
    },
  },
  created() {
    console.log('create')
  },
  mounted() {
    console.log('mounted')

    if (Object.keys(this.$route.params).length == 0) {
      this.active_tab = 0;
      this.getBoardList(0, 1);
    } else {
      // alert(JSON.stringify(this.$route.params.category));
      this.active_tab = this.$route.params.category - 1;
      this.getBoardList(this.active_tab, 1);
    }
  },
  updated() {
  }
};
</script>
