
<template>
    <div style="overflow:scroll;height:500px">
        <h3> 고정할 숫자 선택하기 </h3>
        <v-divider></v-divider>
        <p> 내가 꿈에서 본 숫자가 뭐였지..?</p>
        <v-row class="max-auto ma-5"> 
            <v-col cols="1"
            v-for="num in num_list"
            v-bind:key="num.key"
            >
                <v-btn :id="num" depressed fab large color="#ffd400" @click="fixed_function(num)" class="ball"> {{num}} </v-btn>
            </v-col> 

        </v-row>
        <v-btn class="mx-auto ma-5 pa-5" width="80%"  v-on:click="fixedEvent"> 고정하기 </v-btn>
        <v-divider></v-divider>

        <!-- 선택된 숫자는 event를 통해서
            random page로 가며, 이것들을 표현해 주어야 함 -->

        <!-- 미리 구한 확률 정보로 가이드 -->
        <!-- <button v-on:click="viewHistory">이력보기</button> -->
        <br />
        <br />
        <v-container fluid>
            <v-row>
            <v-col  cols="10">
            <p> 최근 확률 정보 <strong>(최근 {{select}}회 기준))</strong> </p>
            </v-col>
        
            <v-col cols="10">
                <v-combobox
                v-on:change="select_click"
                v-model="select"
                :items="items"
                label="범위 설정"
                outlined
                dense
                ></v-combobox>
            </v-col>
            </v-row>
        </v-container>
        <span>

        <v-row class="max-auto ma-5"> 
            <v-col cols="1"
            v-for="item in recent_percentage_data"
            v-bind:key="item.key"
            >
                
                <div v-bind:style="{ backgroundColor: computedBgColor(item.num)}" class="ball_div"> {{item.num}} </div>
                <p  class="pa+2 ma+2"><strong> {{item.percentage}}</strong> 번 </p>
            </v-col> 

        </v-row>

        </span>
        <br />
        <br />
        <p> 전체 확률 정보 <strong>(전체 기준))</strong> </p>
        <span>


        <v-row class="max-auto ma-5"> 
            <v-col cols="1"
            v-for="item in total_percentage_data"
            v-bind:key="item.key"
            >
                
                <div v-bind:style="{ backgroundColor: computedBgColor(item.num)}" class="ball_div"> {{item.num}} </div>
                <p  class="pa+2 ma+2"><strong> {{item.percentage}}</strong> 번 </p>
            </v-col> 

        </v-row>
        
            <!-- <p>
                {{percentage_data[0]}}
            </p> -->

            <!-- <li class="ball">34</li>  
            <li> <strong>85%</strong> </li>
            <p></p>
            <li class="ball">40</li>  
            <li> <strong>50%</strong> </li>
            <p></p>
            <li class="ball">12</li>  
            <li> <strong>22%</strong> </li> -->
        </span>
    

    </div>
</template>

<!-- <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-5746542177729335"
     crossorigin="anonymous"></script> -->
<script>
// import vSelect from 'vue-select'
import axios from 'axios'

export default {
    components : { 
        // vSelect,
    },
    methods : {
        select_click:function(){
            // alert(this.select)
            axios.defaults.headers.common['Content-Type'] = 'application/x-www-form-urlencoded'
            axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
            var recent_num = this.select; 
            var query = "/lotto/get_lotto_percentage/?recent_num=" + recent_num 
            this.recent_percentage_data = [];
            axios.get(query).then(
                res=>{
                    for(var i = 1 ; i < 46; i++){ 
                        // console.log(res.data[i].percentage);
                        var num_data = {"num" : i, "percentage" : res.data[i]} ; 
                        console.log("push" + num_data["num"] + "/" +  num_data["percentage"]); 
                        // tmp.push(num_data);
                        this.recent_percentage_data.push(num_data);
                        // console.log(this.total_percentage_data[i]);
                    }
                    this.recent_percentage_data.sort(function(a,b){
                        if(a["percentage"] > b["percentage"]) return -1;
                        else if(a["percentage"] == b["percentage"]) return 0;
                        else return 1;
                    })
                }
            );
        },
        computedBgColor: function(num) { 
            var color = "#ffffff";
            console.log("num = " + num);
                if(num < 10){
                    // this.balls_num.
                    color = "#ffd400";
                }else if(num < 20){ 
                    color = "#00d4ff";
                }else if(num < 30){ 
                    color = "#FF5a5a";
                }else if(num < 40){ 
                    color = "#808080";
                }else{ 
                    color = "#92DF45";
                }
            return color;
        },
        fixed_function: function(number) { 
            // alert(number);

            var t = document.getElementById(number);
            // alert(t.style.background);
            if(this.num_list_toggle[number] == 0){ 

                var cnt = 0; 
                for(var i = 0 ; i < this.num_list_toggle.length; i++){
                    if(this.num_list_toggle[i] == 1) cnt++; 
                }
                if(cnt == 6){
                    alert("6개까지만 설정가능합니다");
                    return;
                }
                
                t.style.background="#fc0303";
                this.num_list_toggle[number]=1;
                // this.fixed_num_list.push(number);

            }else{ 
                t.style.background="#ffd400";
                this.num_list_toggle[number]=0;
                // this.fixed_num_list.delete(number);

            }
        },
        fixedEvent : function() { 
            for(var i = 0 ; i < this.num_list_toggle.length; i++){
                if(this.num_list_toggle[i] == 1) { 
                    this.fixed_num_list.push(i);
                }
            }
            // alert(this.fixed_num_list);
            this.$emit('fixedEvent', this.fixed_num_list);
            alert('숫자 고정 완료! 뽑기로 번호를 생성해보세요!')
            // return;
        },
        viewHistory: function(){ 
            console.log("viewhistory" + this.idx_history);
            for(var i = 0 ; i < this.idx_history; i++) {
                for(var j = 0 ; j < this.num_history[i].length; j++){
                    console.log(this.num_history[i][j]);
                }
            }
        }
    },
    data() { 
        return { 
            select: '100',
            items: [
            '10',
            '50',
            '100',
            '200',
            ],
            fixed_num_list : [],
            num_history: [],
            idx_history: 0,
            num_list_toggle : [
                0,0,0,0,0,
                0,0,0,0,0,
                0,0,0,0,0,
                0,0,0,0,0,
                0,0,0,0,0,
                0,0,0,0,0,
                0,0,0,0,0,
                0,0,0,0,0,
                0,0,0,0,0,
            ],
            num_list : ["1","2","3","4","5",
                        "6","7","8","9","10",
                        
                        "11","12","13","14","15",
                        "16","17","18","19","20",

                        "21","22","23","24","25",
                        "26","27","28","29","30",
                        
                        "31","32","33","34","35",
                        "36","37","38","39","40",
                        "41","42","43","44","45",
                        ],
            // percentage related data
            total_percentage_data: [],
            recent_percentage_data: [],
        }
    },
    created : function() {
        axios.defaults.headers.common['Content-Type'] = 'application/x-www-form-urlencoded'
        axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
        // axios.get('http://127.0.0.1:8000/totalpercentage').then(
        //     res=>{
        //         alert("DD!?");
        //         console.log(res);
        //     }
        // );
        axios.get("/lotto/get_lotto_percentage/").then(
            res=>{
                // console.log(res); 
                // console.log(res.data[0].num);
                // console.log(res.data[0].percentage);
                // console.log("DAAD" + res); 
                // var tmp = []; 
                for(var i = 1 ; i < 46; i++){ 
                    // console.log(res.data[i].percentage);
                    var num_data = {"num" : i, "percentage" : res.data[i]} ; 
                    console.log("push" + num_data["num"] + "/" +  num_data["percentage"]); 
                    // tmp.push(num_data);
                    this.total_percentage_data.push(num_data);
                    // console.log(this.total_percentage_data[i]);
                }
                this.total_percentage_data.sort(function(a,b){
                    if(a["percentage"] > b["percentage"]) return -1;
                    else if(a["percentage"] == b["percentage"]) return 0;
                    else return 1;
                })
            }
        );
        var recent_num = 100; 
        var query = "/lotto/get_lotto_percentage/?recent_num=" + recent_num 
        axios.get(query).then(
            res=>{
                for(var i = 1 ; i < 46; i++){ 
                    // console.log(res.data[i].percentage);
                    var num_data = {"num" : i, "percentage" : res.data[i]} ; 
                    console.log("push" + num_data["num"] + "/" +  num_data["percentage"]); 
                    // tmp.push(num_data);
                    this.recent_percentage_data.push(num_data);
                    // console.log(this.total_percentage_data[i]);
                }
                this.recent_percentage_data.sort(function(a,b){
                    if(a["percentage"] > b["percentage"]) return -1;
                    else if(a["percentage"] == b["percentage"]) return 0;
                    else return 1;
                })
            }
        );
    //     // for(var i = 900 ; i < 902; i++){    
    //         var temp = []; 
    //         // var query_result = "/api/common.do?method=getLottoNumber&drwNo=" + i;
    //         // console.log("query == " + query_result);
    //         axios.get('/api/common.do?method=getLottoNumber&drwNo=900')
    //         // axios.get(query_result)
    //             .then(function(response){
    //                 console.log("response = " + response);
    //                 console.log("1 = " + response.data.drwtNo1);
    //                 console.log("2 = " + response.data.drwtNo2);
    //                 console.log("3 = " + response.data.drwtNo3);
    //                 console.log("4 = " + response.data.drwtNo4);
    //                 console.log("5 = " + response.data.drwtNo5);   
                    
    //                 temp.push(response.data.drwtNo1);
    //                 temp.push(response.data.drwtNo2);
    //                 temp.push(response.data.drwtNo3);
    //                 temp.push(response.data.drwtNo4);
    //                 temp.push(response.data.drwtNo5);
    //             })
    //             .catch(function(error){
    //                 console.log(error);
    //             });
    //             this.num_history.push(temp);
    //             this.idx_history++;
    //     // }
    }
}
</script>
<style scoped>
/* vSelect {
    width: 20px;
} */
.ball {
    color: #ffd400;
    width: 20px;
    height: 20px;
    margin-left: 5px;
    font-weight: bold;
    line-height: 28px;
    border-radius: 50%;
    color : black;
}
.ball_div {
    background-color: #ffd400;
    width: 30px;
    height: 30px;
    margin-left: 5px;
    font-weight: bold;
    text-align: center;
    line-height:30px;
    border-radius: 50%;
    color : white;
}
</style>